var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-5 tarjeta",staticStyle:{"cursor":"pointer"},attrs:{"height":_vm.$vuetify.breakpoint.name === 'xl' ? '620px' : _vm.$vuetify.breakpoint.name === 'lg' ? '600px' : '',"elevation":hover ? 5 : 2}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"parent",style:('height:' + _vm.height + '; width:100%')},[_c('div',{staticClass:"child",style:('background-image:url(' +
                _vm.image +
                '); width: 100%; height:100%; background-position: center; background-size: cover;')})])]),_c('v-col',{attrs:{"cols":"12","align":"start"}},[_c('v-card-title',{staticStyle:{"word-break":"normal","font-size":"18px"}},[_vm._v(_vm._s(_vm.$t(_vm.title)))]),_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.date))]),(
            _vm.$vuetify.breakpoint.name === 'md' ||
              _vm.$vuetify.breakpoint.name === 'sm' ||
              _vm.$vuetify.breakpoint.name === 'xl'
          )?_c('v-card-text',{staticClass:"pb-0"},[_vm._v(_vm._s(_vm.$t(_vm.text)))]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }