<template>
  <pages-layout>
    <template v-slot:content>
      <Header :title="'titulosSecciones.about'" :image="require('@/assets/about/about_header.jpg')" />
      <v-col cols="12">
        <v-row justify="center" style="">
          <!--md="" lg="" xl=""-->
          <v-col cols="12" md="10" lg="10" xl="10" justify="center">
            <p
              data-aos="fade-up"
              class="mr-8 ml-8 mt-10 pb-2"
              style="font-size:50px; font-weight:500; color:#25becb; text-align: center;"
            >
              {{ $t('about.texts.whoweare.upText') }}
            </p>
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mr-8 ml-8 mt-10 pb-16 text-body1 text-md-h6"
              style="font-weight:400; color:black; text-align: center;"
            >
              {{ $t('about.texts.whoweare.text') }}
              <a href="https://www.mvgm.es/" style="text-decoration:none;">MVGM</a>
              {{ $t('about.texts.whoweare.text2') }}
            </p>
          </v-col>
        </v-row></v-col
      >

      <v-col
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-duration="1200"
        cols="12"
        class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0"
      >
        <v-parallax
          id="my-lax"
          :height="
            $vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl'
              ? 700
              : 1200
          "
          :src="require('@/assets/about/niksen3.jpg')"
        >
          <v-col class="mb-0 pb-0 pt-0 mt-0 pr-8 pl-8 " cols="12">
            <p
              class="mr-8 ml-8 mt-10 pb-2 text-md-h3 text-h5"
              style="font-weight:500; color:#25becb; text-align: center;"
            >
              {{ $t('about.whyEniksen') }}
            </p>

            <p
              data-aos="fade-in"
              data-aos-duration="1000"
              class="mr-8 ml-8 mt-15 pb-2 text-md-h4 text-h6"
              style="font-weight-regular; color:black; text-align: center;"
            >
              {{ $t('about.texts.whyEniksen.upText') }}
            </p>

            <p
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="300"
              class="mx-md-8 mx-0 mt-10 pb-2 text-body1 text-md-h6"
              style="font-weight: 400; color:black; text-align: center;"
            >
              {{ $t('about.texts.whyEniksen.text') }}
            </p>

            <p
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="300"
              class="mx-md-8 mx-0 mt-10 pb-2 "
              style="font-size:40px; font-weight-light; color:black; text-align: center;"
              :style="
                $vuetify.breakpoint.name === 'md' ||
                $vuetify.breakpoint.name === 'lg' ||
                $vuetify.breakpoint.name === 'xl'
                  ? 'font-size:40px'
                  : 'font-size:22px'
              "
            >
              {{ $t('about.texts.whyEniksen.downText') }}
            </p>
          </v-col>
        </v-parallax>
      </v-col>
      <v-col cols="12">
        <v-row justify="center" style="">
          <v-col cols="10" justify="center">
            <p
              data-aos="fade-up"
              class="mr-8 ml-8 mt-10 pb-2"
              style="font-size:50px; font-weight:500; color:#25becb; text-align: center;"
            >
              {{ $t('about.services') }}
            </p>
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mr-8 ml-8 mt-10 pb-4"
              style="font-size:20px; font-weight-light; color:black; text-align: center;"
            >
              {{ $t('about.texts.services.text') }}
            </p>
          </v-col>
        </v-row></v-col
      >

      <!-- TARJETAS -->

      <v-row
        justify="center"
        class="mx-xl-16 pl-x-8 mx-auto "
        v-if="$vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl'"
      >
        <v-col
          cols="12"
          lg="4"
          xl="4"
          align="center"
          class="mx-auto "
          v-for="(servicio, index) in servicios"
          :key="index"
        >
          <card class=" mb-15" color="grey lighten-4" max-width="450" :data-image="servicio.foto">
            <h1 slot="header">{{ servicio.titulo }}</h1>
            <p slot="content">{{ servicio.descripcion }}</p>
          </card>
        </v-col>
      </v-row>

      <v-col cols="12" class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0" v-else>
        <v-parallax
          data-aos="fade-in"
          data-aos-duration="1000"
          v-for="(servicio, index) in servicios"
          :key="index"
          style=""
          class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0"
          :height="servicio.altura"
          :src="servicio.foto"
        >
          <h1 style="background-color:rgba(0, 0, 0, 0.6)" class=" text-center">{{ servicio.titulo }}</h1>
          <span style="background-color:rgba(0, 0, 0, 0.6); font-weight: 500" class=" text-center pb-2">{{
            servicio.descripcion
          }}</span>
        </v-parallax></v-col
      >

      <!-- CALL TO ACTION -->

      <v-col cols="12" style="background-color:#25becb;">
        <CallAction
          :titulo="'callAction.titleContact'"
          :subtitulo="'callAction.subtitleContact'"
          :texto="'callAction.textoContact'"
          :boton="'callAction.botonContacto'"
      /></v-col>

      <!-- VALORES -->

      <v-col cols="12" style="background-color:#E4EAE7;" class="mb-0 pb-0 pt-0 mt-0 mx-0 px-0"
        ><v-row class="px-0 mx-0">
          <v-col
            v-if="
              $vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'md'
            "
            cols="12"
            lg="6"
            xl="6"
            class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0"
            ><v-parallax style="height: 750px;" :src="require('@/assets/about/valores.jpg')"> </v-parallax
          ></v-col>
          <v-col class="mb-0 pb-0 pt-0 mt-0 pr-8 pl-8 " cols="12" lg="6" xl="6">
            <p
              class="mr-8 ml-8 mt-10 pb-2 text-h4 text-md-h2"
              style="font-size:50px; font-weight:500; color:#25becb; text-align: center;"
            >
              {{ $t('about.valores') }}
            </p>
            <p
              class="mr-8 ml-8 mt-1 pb-0 pb-md-8 text-body-2 text-md-h6"
              style="font-size:20px; font-weight: 400; color:black; text-align: center;"
            >
              {{ $t('about.texts.valores.text') }}
            </p>
            <!-- <v-col cols="12" v-if="$vuetify.breakpoint.name != 'xs'">
              <v-col cols="12">
                <ListValores
                  class="ml-16 mt-n4"
                  :texto="item"
                  v-for="(item, i) in $t('about.texts.valores.valorList')"
                  :key="i"/></v-col
            ></v-col> -->
            <!-- <servicio :servicio="valor" v-for="(valor, i) in valores" :key="i"></servicio> -->
            <!-- <v-row>
              <v-col cols="12" align="center" class="mx-0 px-0">
                <v-carousel hide-delimiters height="150" class=" pt-0" style="">
                  <v-carousel-item v-for="(valor, i) in valores" :key="i">
                    <v-col cols="6" align="center">
                      <v-icon size="100" color="primary">{{ valor.icono }}</v-icon>
                      <span class="text-h6 text--secondary">{{ valor.titulo }}</span></v-col
                    ></v-carousel-item
                  >
                </v-carousel>
              </v-col></v-row
            > -->
            <v-row v-if="$vuetify.breakpoint.name != 'xs'" justify="center" align="center">
              <v-col cols="12" align="center" justify="center" class="mx-0 px-0">
                <v-row justify="center" align="center">
                  <v-col
                    cols="2"
                    align="center"
                    class="mx-3"
                    justify="center"
                    v-for="(valor, i) in valores"
                    :key="i"
                    :class="valor.titulo === 'Orientación al cliente' ? 'pt-8' : ''"
                  >
                    <v-row align="center" justify="center">
                      <v-col align="center" justify="center" cols="12">
                        <v-icon
                          :size="$vuetify.breakpoint.name === 'sm' ? 55 : 75"
                          color="primary"
                          justify="center"
                          align="center"
                          >{{ valor.icono }}</v-icon
                        ></v-col
                      ><v-col cols="12" align="center" justify="center">
                        <span
                          class="text--secondary"
                          :style="
                            $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'md'
                              ? 'font-size:14px'
                              : $vuetify.breakpoint.name === 'lg'
                              ? 'font-size:16px'
                              : 'font-size: 21px'
                          "
                          >{{ valor.titulo }}</span
                        ></v-col
                      >
                    </v-row></v-col
                  >
                </v-row>
              </v-col></v-row
            >
            <v-row v-else class="pb-8">
              <v-col
                cols="12"
                align="center"
                justify="center"
                class="mx-0 px-0 py-0"
                v-for="(valor, i) in valores"
                :key="i"
              >
                <v-col align="center" justify="center" cols="12">
                  <v-row align="center" justify="center">
                    <v-icon size="20" class="mr-4" color="primary">{{ valor.icono }}</v-icon>
                    <span class="" style="font-size: 14px">{{ valor.titulo }}</span></v-row
                  ></v-col
                >
                <!-- <v-carousel hide-delimiters height="150" class=" pt-0" style="">
                  <v-carousel-item v-for="(valor, i) in valores" :key="i">
                    <v-col cols="6" align="center">
                      <v-icon size="100" color="primary">{{ valor.icono }}</v-icon>
                      <span class="text-h6 text--secondary">{{ valor.titulo }}</span></v-col
                    ></v-carousel-item
                  ></v-carousel
                > -->
              </v-col></v-row
            >
          </v-col>
        </v-row>
      </v-col>
    </template>
  </pages-layout>
</template>

<script>
// import Alerts from '@/components/Alerts'
/* import NavigationDrawer from '@/components/NavigationDrawer' */
import Header from '@/components/Header'

import CallAction from '@/components/CallAction'
//import ListValores from '@/components/ListValores'
import { mapActions, mapGetters } from 'vuex'
import card from '@/components/card.vue'
//import servicio from '@/pages/about/servicio.vue'
export default {
  name: 'about',
  components: {
    // Alerts,
    //NavigationDrawer
    Header,
    CallAction,
    //ListValores,
    card
    //servicio
  },
  data() {
    return {
      servicios: [
        {
          titulo: this.$t('about.serviciosSeccion.servicio1.tituloServicio'),
          foto: require('@/assets/about/servicio1.jpeg'),
          descripcion: this.$t('about.serviciosSeccion.servicio1.descripcionServicio'),
          altura: 400
        },
        {
          titulo: this.$t('about.serviciosSeccion.servicio2.tituloServicio'),
          foto: require('@/assets/about/servicio2.jpeg'),
          descripcion: this.$t('about.serviciosSeccion.servicio2.descripcionServicio'),
          altura: 550
        },
        {
          titulo: this.$t('about.serviciosSeccion.servicio3.tituloServicio'),
          foto: require('@/assets/about/servicio3.jpeg'),
          descripcion: this.$t('about.serviciosSeccion.servicio3.descripcionServicio'),
          altura: 400
        }
      ],
      valores: [
        {
          icono: 'mdi-handshake',
          titulo: this.$t('about.texts.valores.valorList')[0]
        },
        {
          icono: 'mdi-home-lightbulb-outline',
          titulo: this.$t('about.texts.valores.valorList')[1]
        },
        {
          icono: 'mdi-compass-outline',
          titulo: this.$t('about.texts.valores.valorList')[2]
        },
        {
          icono: 'mdi-medal-outline',
          titulo: this.$t('about.texts.valores.valorList')[3]
        }
      ]
    }
  },
  created() {
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  background: linear-gradient(0deg, #25becb, transparent);
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
