<template>
  <pages-layout>
    <template v-slot:content>
      <v-row no-gutters justify="center">
        <swiper class="swiper" :options="swiperOption" style="height: 620px">
          <swiper-slide v-for="(item, i) in images" :key="i" style="padding: 0">
            <v-img :lazy-src="item.src" :src="item.src" style="height: 100%" class="imgHomeCarousel" />
          </swiper-slide>
        </swiper>
        <v-col class="buscador" cols="9">
          <TituloSeccionH2 :titulo="'home.findyourhome'" style="text-transform: uppercase;" />
          <v-row justify="center">
            <v-col cols="10" md="3" lg="3" sm="3">
              <v-select
                v-model="ciudad"
                :items="ciudades"
                :loading="loading"
                :disabled="loading"
                :label="$t('filtersSelect.cuidad.title')"
                ><template slot="no-data">
                  <v-layout style="padding:10px" :value="true">{{ $t('general.nociudades') }}</v-layout>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                  </v-list-item> </template
              ></v-select>
            </v-col>
            <v-col cols="10" md="3" lg="3" sm="3">
              <v-select
                v-model="tipologia"
                :items="tipologiasTraducidas"
                :disabled="loading"
                :loading="loading"
                :label="$t('filtersSelect.tipologia.title')"
                ><template slot="no-data">
                  <v-layout style="padding:10px" :value="true">{{ $t('general.notipos') }}</v-layout>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
                  </v-list-item> </template
              ></v-select>
            </v-col>
            <v-col cols="9" md="2" lg="2" sm="4" class="mt-3">
              <span>{{ $t('home.priceRange') }}</span>
              <v-range-slider v-model="rangoPrecio" :max="max" :min="min" hide-details class="align-center" />
              <span style="font-size: 14px"
                >{{ $t('home.de') }} {{ rangoPrecio[0] | currency }} {{ $t('home.a') }}{{ textoMaximo }}</span
              >
            </v-col>
            <v-col cols="10" md="1" sm="1" lg="1">
              <v-row justify="center">
                <v-btn @click="buscar" icon class="mt-4 ml-0">
                  <v-icon color="primary" x-large style="ml-16">mdi-arrow-right-circle-outline</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" align="center" class="mt-10">
          <v-col cols="10">
            <TituloSeccionH2 :titulo="'home.claim'" style="text-transform: uppercase;" />
            <!-- <TextoCentrado :texto="'home.texthome'"/> -->
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mr-8 ml-8 mt-10 mb-10 text-body1 text-md-h6"
              style="font-weight:400; color:black; text-align: center;"
            >
              {{ $t('home.texthome') }}
            </p>
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mr-8 ml-8 pb-16 text-body1 text-md-h6"
              style="font-weight:400; color:black; text-align: center;"
            >
              {{ $t('home.texthome2') }}
            </p>
          </v-col>
        </v-col>
        <!-- <v-col cols="12" style="background-color:#25becb;">
          <CallAction
            :titulo="'callAction.titleContact'"
            :subtitulo="'callAction.subtitleContact'"
            :texto="'callAction.textoContact'"
            :boton="'callAction.botonContacto'"
        /></v-col> -->

        <v-col cols="12" justify="center" class="mb-12 mb-md-0 mb-xl-0 mb-lg-0" style="background-color:#25becb;">
          <FormStep :items="formulario" :formType="'newsletter'" :titulos="titulosForm" @stepReset="reset = $event"
        /></v-col>
        <v-col cols="12" align="center">
          <v-col cols="12" align="center" class="px-4 px-sm-12">
            <TituloSeccionH2 :titulo="'home.latestNews'" style="text-transform: uppercase;" />
            <!-- <p>{{ noticias }}</p> -->
            <v-row justify="center" class="">
              <v-col
                :data-aos="reset === false ? 'fade-up' : ''"
                data-aos-duration="1000"
                data-aos-delay="50"
                cols="12"
                sm="10"
                md="10"
                lg="4"
                v-for="noticia in noticiasNews"
                :key="noticia.id"
              >
                <CardNewsHome
                  style=""
                  :image="noticia.foto"
                  :title="'blog.noticia' + noticia.id + '.titulo'"
                  :text="'blog.noticia' + noticia.id + '.descripcionCorta'"
                  :date="noticia.fecha"
                  :height="'300px'"
                  @click.native.stop="goTo(noticia)"
                />
              </v-col>
            </v-row> </v-col
        ></v-col>
      </v-row>
    </template>
  </pages-layout>
</template>

<script>
import TituloSeccionH2 from '@/components/Textos/TituloSeccionH2'
import CardNewsHome from '@/components/CardNewsHome'
//import CallAction from '@/components/CallAction'
/* import CardImageText from '@/components/CardImageText' */
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import FormStep from '@/components/FormStep'
//import TextoCentrado from '@/components/Textos/TextoCentrado'
//import ListValores from '@/components/ListValores'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'home',
  components: {
    //NavigationDrawer,
    //CallAction,
    /* CardImageText, */
    CardNewsHome,
    //TextoCentrado,
    FormStep,
    //ListValores,
    TituloSeccionH2,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      formulario: [
        {
          step: 1,
          titulo: 'formulario.formNameContact.titulo',
          subtitulo: 'formulario.formNameContact.subtitulo',
          type: 'nombre',
          label: 'formulario.formNameContact.label',
          boton: 'formulario.formNameContact.boton',
          literalStep: 'formulario.formNameContact.literal'
        },
        {
          step: 2,
          titulo: 'formulario.formEmailNewsletter.titulo',
          subtitulo: 'formulario.formEmailNewsletter.subtitulo',
          label: 'formulario.formEmailNewsletter.label',
          boton: 'formulario.formEmailNewsletter.boton',
          type: 'email',
          literalStep: 'formulario.formEmailNewsletter.literal'
        },
        {
          step: 3,
          titulo: 'formulario.formTextNewsletter.titulo',
          subtitulo: 'formulario.formTextNewsletter.subtitulo',
          label: 'formulario.formTextNewsletter.label',
          boton: 'formulario.formTextNewsletter.boton',
          type: 'comentarios',
          maxStep: true,
          literalStep: 'formulario.formTextNewsletter.literal'
        }
      ],
      titulosForm: { titulo: 'contacto.titleForm', subtitulo: 'contacto.subTitleForm' },
      swiperOption: {
        enabled: false,
        speed: 2000,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      },
      images: [
        {
          src: require('@/assets/home/UBIDOMO_slide-4-1500x630.jpg')
        },
        {
          src: require('@/assets/home/UBIDOMO_slide-6-1500x630.jpg')
        },
        {
          src: require('@/assets/home/UBIDOMO_slide-7-1500x630.jpg')
        },
        {
          src: require('@/assets/home/UBIDOMO_slide-8-1500x630.jpg')
        }
      ],
      min: 0,
      max: 3000,
      rangoPrecio: [0, 3000],
      ciudad: null,
      promociones: [],
      tipologias: [],
      loading: true,
      properties: [],
      tipologia: null,
      reset: false
    }
  },
  async created() {
    window.scrollTo(0, 0)
    this.promociones = await this.loadPromociones()
    this.tipologias = await this.loadTipologias()
    if (this.tipologias.length > 0 && this.tipologias.some(x => x === 'Vivienda')) {
      this.tipologia = 'Vivienda'
    }
    if (this.tipologias.length > 0 && this.tipologias.some(x => x === 'Dwelling')) {
      this.tipologia = 'Dwelling'
    }
    this.loading = false
  },
  methods: {
    ...mapActions({
      loadPromociones: 'properties/loadPromociones',
      loadTipologias: 'properties/loadTipologias'
    }),
    goTo(accion) {
      this.$router.push({ name: 'articulo', params: { ruta: accion.enlace } })
    },
    buscar() {
      this.$router.push({
        name: 'properties',
        query: {
          ciudad: this.ciudad,
          tipologia: this.tipologia,
          rangoPrecio: this.rangoPrecio
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      noticias: 'blog/getNoticias'
    }),
    tipologiasTraducidas() {
      if (this.$root.$i18n.locale == 'es') {
        return this.tipologias
      } else {
        return this.tipologias.map(x => {
          switch (x) {
            case 'Vivienda':
              return 'Dwelling'
            case 'Garaje':
              return 'Parking'
            case 'Local':
              return 'Premises'
            case 'Trastero':
              return 'Storage'
          }
        })
      }
    },
    textoMaximo() {
      return this.rangoPrecio[1] >= 3000
        ? this.$t('home.sinlimite')
        : this.$options.filters['currency'](this.rangoPrecio[1])
    },
    ciudades() {
      if (this.promociones == null) {
        return []
      }
      return this.promociones.map(x => {
        return x.provincia
      })
    },
    noticiasNews() {
      let retorno = _.orderBy(
        this.noticias.filter(x => x.categorias.includes('new')),
        [
          function(noticia) {
            return moment(new Date(noticia.fecha)).format('YYYYMMDD')
          }
        ],
        ['desc']
      )
      retorno = retorno.map(x => {
        return { ...x, fecha: moment(new Date(x.fecha)).format('DD/MM/YYYY') }
      })
      return retorno
    },
    imageBigHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '150px'
        case 'sm':
          return '300px'
        case 'md':
          return '340px'
        case 'lg':
          return '440px'
        case 'xl':
          return '440px'
        default:
          return '440px'
      }
    }
  }
}
</script>

<style scoped>
.buscador {
  z-index: 2;
  background-color: rgb(228 234 231 / 75%);
  margin-top: -230px;
  height: 200px;
  position: relative;
  margin-bottom: 0;
  border-radius: 5px 5px 0 0;
}
@media only screen and (max-width: 600px) {
  .buscador {
    margin-top: -565px;
    height: 520px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 600px) {
  .buscador {
    margin-top: -260px;
    height: 225px;
  }
}

/* Chrome, Safari, Opera */
@-webkit-keyframes zoom {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
/* Standard syntax */
@keyframes zoom {
  0% {
    -webkit-transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
  }
}
.imgHomeCarousel {
  -webkit-animation: zoom 38s infinite; /* Chrome, Safari, Opera */
  animation: zoom 38s infinite;
}
</style>
