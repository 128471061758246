<template>
  <pages-layout :loading="loading">
    <!-- sm="" md="" lg="" xl=""-->
    <template v-slot:content>
      <v-col cols="12">
        <v-breadcrumbs :items="adminBreadcrumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.to" :disabled="item.disabled">
              {{ item.exact ? item.text : $t(item.text) }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-row justify="center">
          <v-col cols="11" md="9" lg="9" xl="9">
            <v-row justify="space-between">
              <v-col cols="12" md="6" lg="6" xl="6">
                <h2 class="secondary--text pt-0 mt-0" style="font-weight: bold">
                  {{ inmueble.descripcionLarga }}
                  {{ inmueble.promocion.provincia ? '(' + inmueble.promocion.provincia + ')' : '' }}
                </h2>
              </v-col>
              <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 mt-0">
                <v-row class="hidden-md-and-up" justify="start">
                  <v-col cols="6" class="pt-0 mt-0">
                    <h2
                      v-if="inmueble.tarifaAlquiler.importeAlquiler == null"
                      style="font-weight: bold; font-size: 30px"
                    >
                      {{ $t('properties.noImporte') }}
                    </h2>
                    <h2 v-else style="font-weight: bold; font-size: 30px">
                      {{ importeTotal | currency }}
                    </h2>
                  </v-col>
                </v-row>
                <v-row justify="end" class="hidden-sm-and-down">
                  <v-col align="end">
                    <h2
                      v-if="inmueble.tarifaAlquiler.importeAlquiler == null"
                      class="secondary--text"
                      style="font-weight: bold"
                    >
                      {{ $t('ejemploPiso.precioLabel') + ': ' }}

                      {{ $t('properties.noImporte') }}
                    </h2>
                    <h2 v-else class="secondary--text" style="font-weight: bold">
                      {{ $t('ejemploPiso.precioLabel') + ': ' }}

                      {{ importeTotal | currency }}
                    </h2></v-col
                  ></v-row
                >
              </v-col></v-row
            >
          </v-col>
        </v-row></v-col
      ><v-col v-if="inmueble.imagenes && inmueble.imagenes.length > 0" cols="12" class="mt-n12 mb-12">
        <v-row justify="center">
          <v-col cols="12" md="9" lg="7" xl="7" class="px-0">
            <div class="thumb-example">
              <!-- swiper1 -->
              <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                <swiper-slide
                  v-for="imagen in inmueble.imagenes"
                  :key="imagen.id"
                  :style="
                    $vuetify.breakpoint.name === 'xs' ||
                    $vuetify.breakpoint.name === 'sm' ||
                    $vuetify.breakpoint.name === 'md'
                      ? 'background-size: cover; background-position: center; background-image: url(' +
                        $apiDocument(imagen) +
                        ');'
                      : 'background-size: cover; background-position: center; background-image: url(' +
                        $apiDocument(imagen) +
                        ');'
                  "
                ></swiper-slide>

                <div
                  v-if="inmueble.imagenes && inmueble.imagenes.length > 1"
                  class="swiper-button-next"
                  :class="
                    $vuetify.breakpoint.name === 'xs' ||
                    $vuetify.breakpoint.name === 'sm' ||
                    $vuetify.breakpoint.name === 'md'
                      ? 'swiper-button-white'
                      : 'swiper-button-white'
                  "
                  slot="button-next"
                ></div>
                <div
                  v-if="inmueble.imagenes && inmueble.imagenes.length > 1"
                  class="swiper-button-prev"
                  :class="
                    $vuetify.breakpoint.name === 'xs' ||
                    $vuetify.breakpoint.name === 'sm' ||
                    $vuetify.breakpoint.name === 'md'
                      ? 'swiper-button-white'
                      : 'swiper-button-white'
                  "
                  slot="button-prev"
                ></div>
              </swiper>
              <!-- swiper2 Thumbs -->
              <swiper
                v-if="inmueble.imagenes && inmueble.imagenes.length > 1"
                class="swiper gallery-thumbs"
                :options="swiperOptionThumbs"
                ref="swiperThumbs"
              >
                <swiper-slide
                  v-for="imagen in inmueble.imagenes"
                  :key="imagen.id"
                  style="background-size: cover; background-position: center;"
                  :style="'background-image: url(' + $apiDocument(imagen) + ');'"
                ></swiper-slide>
              </swiper>
            </div>
          </v-col> </v-row
      ></v-col>
      <v-col v-else cols="12" justify="center" align="center" class="pb-0 mb-0">
        <v-col cols="12" sm="11" md="10" lg="5" xl="6" align="center">
          <v-img
            height="350px"
            data-aos="fade-up"
            data-aos-duration="1000"
            justify="center"
            :src="require('@/assets/fake_properties/11/building_placeholder_500x332.jpg')"
          ></v-img>
          <h3 data-aos="fade-up" style="font-weight: 500" data-aos-duration="2000" class="mt-8">
            {{ alertError == null ? $t('properties.property.titles.noPhoto') : alertError }}
          </h3>
        </v-col>
      </v-col>
      <v-col cols="12" class="pb-0 mt-8 mt-1" align="center">
        <v-col cols="12" sm="10" md="9" align="center" justify="center">
          <!-- PROMOCION -->
          <v-row
            :style="inmueble.imagenes != null && inmueble.imagenes.length > 0 ? 'margin-top:60px' : ''"
            v-if="mattProm != null || descPromSpa != null"
            :justify="
              $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? 'space-between' : 'center'
            "
          >
            <v-col cols="12">
              <h2 class=" primary--text text-sm-left text-md-left text-lg-left text-xl-left text-center ">
                {{ $t('properties.property.titles.description') }}
              </h2>
              <v-divider class="dividerTitle primary"> </v-divider>
            </v-col>
            <v-col :cols="mattProm != null ? 5 : 12">
              <TextoFormateado
                v-if="
                  $vuetify.breakpoint.name === 'lg' ||
                    $vuetify.breakpoint.name === 'xl' ||
                    $vuetify.breakpoint.name === 'sm' ||
                    $vuetify.breakpoint.name === 'md'
                "
                class="mx-md-0 mx-lg-0 mx-xl-0 mx-sm-0 mx-6"
                :texto="
                  descPromSpa != null
                    ? $root.$i18n.locale == 'es'
                      ? descPromSpa
                      : descPromEng
                    : $t('descriptionProperty')
                "
              />
              <TextoFormateado
                v-else
                class="pt-8"
                :texto="
                  descPromSpa != null
                    ? $root.$i18n.locale == 'es'
                      ? descPromSpa
                      : descPromEng
                    : $t('descriptionProperty')
                "
              />
            </v-col>

            <v-col cols="12" lg="7" v-if="mattProm != null">
              <v-row justify="start">
                <v-col cols="12">
                  <v-icon>mdi-rotate-3d</v-icon>
                  <span style="font-weight: 600;color: rgba(0,0,0,.54);">
                    {{ $t('properties.property.titles.matterportProm') }}
                  </span>
                </v-col>
              </v-row>
              <iframe
                id="showcase"
                title="test"
                :width="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 600
                    : '100%'
                "
                height="375"
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
                :src="mattProm != null ? mattProm : null"
              ></iframe></v-col
          ></v-row>
          <!-- INMUEBLE -->
          <v-row
            class="mt-1"
            :justify="
              $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? 'space-between' : 'center'
            "
          >
            <v-col cols="12">
              <h2 class=" primary--text text-sm-left text-md-left text-lg-left text-xl-left text-center ">
                {{ $t('properties.property.titles.detalle') }}
              </h2>
              <v-divider class="dividerTitle primary"> </v-divider>
            </v-col>
            <v-col :cols="mattInm != null ? 5 : 12">
              <TextoFormateado
                v-if="
                  descInmSpa != null &&
                    ($vuetify.breakpoint.name === 'lg' ||
                      $vuetify.breakpoint.name === 'xl' ||
                      $vuetify.breakpoint.name === 'sm' ||
                      $vuetify.breakpoint.name === 'md')
                "
                class="mx-md-0 mx-lg-0 mx-xl-0 mx-sm-0 mx-6"
                :texto="
                  descInmSpa != null
                    ? $root.$i18n.locale == 'es'
                      ? descInmSpa
                      : descInmEng
                    : $t('descriptionProperty')
                "
              />
              <TextoFormateado
                v-else-if="descInmSpa != null"
                class="pt-8"
                :texto="
                  descInmSpa != null
                    ? $root.$i18n.locale == 'es'
                      ? descInmSpa
                      : descInmEng
                    : $t('descriptionProperty')
                "
              />
              <v-row align="center">
                <v-col cols="auto" align="center" class="py-0">
                  <v-divider></v-divider>
                  <v-row align="center">
                    <v-col class="pr-0" cols="auto">
                      <v-icon>
                        mdi-home
                      </v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <span style="font-weight: 600;color: rgba(0,0,0,.54);">{{
                        inmueble.m2Construidos | noDecimals
                      }}</span
                      ><span style="margin-left:5px;font-weight: 600;color: rgba(0,0,0,.54);">{{ $t('m2') }}</span>
                    </v-col>
                  </v-row>
                  <v-divider v-if="inmueble.descripcionTipo === 'Vivienda'"></v-divider>
                  <v-row v-if="inmueble.descripcionTipo === 'Vivienda'" align="center">
                    <v-col class="pr-0" cols="auto">
                      <v-icon>
                        mdi-bed-king
                      </v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <span style="font-weight: 600;color: rgba(0,0,0,.54);">{{ inmueble.numDormitorios }}</span
                      ><span style="margin-left:5px;font-weight: 600;color: rgba(0,0,0,.54);">{{
                        inmueble.numDormitorios == 1
                          ? $t('properties.cardProperty.room')
                          : $t('properties.cardProperty.rooms')
                      }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row align="center">
                    <v-col cols="auto" class="pr-0">
                      <v-icon class="pr-0">
                        mdi-currency-eur
                      </v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <span style="font-weight: 600;color: rgba(0,0,0,.54);">{{ importeTotal | currency }}</span>
                    </v-col>
                  </v-row>
                  <v-divider v-if="inmueble.planos != null"></v-divider>
                  <v-row align="center" v-if="inmueble.planos != null">
                    <v-col cols="auto" class="pr-0">
                      <v-icon class="pr-0">
                        mdi-file-document
                      </v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <span style="font-weight: 600;color: rgba(0,0,0,.54);">{{
                        $t('properties.property.titles.planos')
                      }}</span>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        color="primary"
                        fab
                        small
                        @click.stop
                        :href="$apiDocument(inmueble.planos[0])"
                        target="_blank"
                      >
                        <v-icon>
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" lg="7" v-if="mattInm != null">
              <v-row justify="start">
                <v-col cols="12">
                  <v-icon>mdi-rotate-3d</v-icon>
                  <span style="font-weight: 600;color: rgba(0,0,0,.54);">
                    {{ $t('properties.property.titles.matterport') }}
                  </span>
                </v-col>
              </v-row>
              <iframe
                id="showcase"
                title="test"
                :width="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 600
                    : '100%'
                "
                height="375"
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
                :src="mattInm != null ? mattInm : null"
              ></iframe></v-col
          ></v-row>
          <v-row
            ><v-col cols="12" style="margin-top:20px">
              <h2
                v-if="codigosPropios && codigosPropios.length > 0"
                class="primary--text text-sm-left text-md-left text-lg-left text-xl-left text-center "
              >
                {{ $t('properties.property.titles.caracteristicas') }}
              </h2>
              <v-divider v-if="codigosPropios && codigosPropios.length > 0" class="dividerTitle primary"> </v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-row v-if="codigosPropios && codigosPropios.length > 0" class="pl-0" style="margin-left:10px">
                <v-col align="center" class="" v-for="item in codigosPropios" :key="item.id">
                  <v-avatar
                    :color="item.codigoCP === 'EW_CERTIFICADO' || item.valor === 'Sí' ? 'primary' : 'grey'"
                    class="mb-2 mt-6 carac"
                    ><v-icon large dark v-if="item.icon && item.icon != ''">
                      {{ item.icon }}
                    </v-icon>
                    <span v-else style="color: white; font-weight: bold; font-size:28px">{{ item.text }}</span>
                  </v-avatar>
                  <v-row justify="center">
                    <span class="text-center caracText" style="font-weight: 500; font-size:14px; color:secondary">{{
                      $root.$i18n.locale == 'es' ? item.titulo : item.tituloTrad
                    }}</span></v-row
                  ></v-col
                ></v-row
              ></v-col
            >
          </v-row>
        </v-col>
        <v-row justify="center" style=" background-color:lightgrey" class="mt-16">
          <v-col> <TituloSeccionH2 :titulo="'properties.property.titles.location'" /> </v-col
        ></v-row>
        <v-row style=""
          ><GoogleMap
            :latitude="inmueble.promocion ? Number(inmueble.promocion.latitud) : 40.44136892833852"
            :longitude="inmueble.promocion ? Number(inmueble.promocion.longitud) : -3.679683207263298"
            :title="'MVGM'"
            style=" height:400px"/></v-row
      ></v-col>
      <v-col cols="12">
        <v-row justify="center" class="mb-12 mb-md-0 mb-xl-0 mb-lg-0">
          <v-col cols="12">
            <FormStep
              :items="formulario"
              :formType="'property'"
              :inmuebleId="id"
              :inmueble="inmueble"
              :titulos="titulosForm"
              :mostrarBotonReservaInmueble="false"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-speed-dial
        v-model="fab"
        fixed
        bottom
        right
        direction="top"
        color="red"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" large color="primary" dark fab>
            <v-icon v-if="fab">
              mdi-close
            </v-icon>
            <v-icon v-else>
              mdi-share-variant-outline
            </v-icon>
          </v-btn>
        </template>

        <a
          ><v-row>
            <telegram
              :url="'¡Mira lo que acabo de encontrar en eniksen! \n ' + fullURL"
              scale="2.5"
              style="position: absolute; "
            ></telegram>
            <div style="height: 28px; width:20px;  margin-left: 16px; margin-top: 6px"></div></v-row
        ></a>

        <a class="mb-2">
          <v-row>
            <whats-app
              :url="'¡Mira lo que acabo de encontrar en eniksen! \n ' + fullURL"
              title="¡Mira lo que acabo de encontrar en eniksen!"
              scale="2.5"
              style="position: absolute; "
            ></whats-app>
            <div
              style="height: 30px; width:28px; background-color: white;  margin-left: 4px; margin-top: 7px"
            ></div></v-row
        ></a>
        <a class="mb-2">
          <v-row>
            <facebook :url="fullURL" scale="2.5" style="position: absolute;"></facebook>
            <div
              style="height: 30px; width:28px; background-color: white;  margin-left: 4px; margin-top: 6px"
            ></div></v-row
        ></a>
        <a class="mb-2">
          <v-row>
            <twitter
              :url="'¡Mira lo que acabo de encontrar en eniksen! \n ' + fullURL"
              scale="2.5"
              style="position: absolute;"
            ></twitter>
            <div
              style="height: 28px; width:28px; background-color: white;  margin-left: 4px; margin-top: 9px"
            ></div></v-row
        ></a>
        <a class="mb-2">
          <v-row>
            <email
              :url="fullURL"
              scale="2.5"
              style="position: absolute;"
              subject="¡Mira lo que acabo de encontrar en eniksen!"
              :body="'¡Mira lo que acabo de encontrar en eniksen! \n ' + fullURL"
            ></email>
            <div
              style="height: 28px; width:28px; background-color: white;  margin-left: 4px; margin-top: 9px"
            ></div></v-row
        ></a>
      </v-speed-dial>
    </template>
  </pages-layout>
</template>
<script>
// import translate from 'translate'
import { Telegram, WhatsApp, Facebook, Twitter, Email } from 'vue-socialmedia-share'
import GoogleMap from '@/components/GoogleMap'
//import CallAction from '@/components/CallAction'
import FormStep from '@/components/FormStep'
import TituloSeccionH2 from '@/components/Textos/TituloSeccionH2.vue'
import TextoFormateado from '../../components/Textos/TextoFormateado.vue'
import 'swiper/css/swiper.css'
import { mapActions } from 'vuex'
export default {
  props: ['id', 'importeTotal'],
  name: 'property',
  components: {
    //NavigationDrawer
    //CallAction,
    TituloSeccionH2,
    TextoFormateado,
    Facebook,
    Telegram,
    WhatsApp,
    Twitter,
    Email,
    GoogleMap,
    FormStep
  },
  async mounted() {
    this.inmueble = await this.loadInmueble(this.id)
    if (this.inmueble.imagenes != null) {
      this.inmueble.imagenes = this.inmueble.imagenes.concat(
        this.inmueble.promocion.imagenes ? this.inmueble.promocion.imagenes : []
      )
      if (this.inmueble.imagenPrincipal != null) {
        this.inmueble.imagenes = [this.inmueble.imagenPrincipal].concat(this.inmueble.imagenes)
      }
    } else {
      this.inmueble.imagenes = []
      this.inmueble.imagenes = this.inmueble.imagenes.concat(
        this.inmueble.promocion.imagenes ? this.inmueble.promocion.imagenes : []
      )
      if (this.inmueble.imagenPrincipal != null) {
        this.inmueble.imagenes = [this.inmueble.imagenPrincipal].concat(this.inmueble.imagenes)
      }
    }
    this.adminBreadcrumbs = [
      {
        text: 'returnToList',
        disabled: false,
        to: 'properties',
        exact: false
      },
      {
        text: this.inmueble.descripcionLarga,
        disabled: true,
        exact: true
      }
    ]
    let respuestaSheet = await this.loadLongContent({
      id: this.inmueble.descripcionCorta,
      promocionId: this.inmueble.promocion ? this.inmueble.promocion.codigo : '100'
    })
    this.descPromSpa =
      respuestaSheet.promocionSheet && respuestaSheet.promocionSheet[2] !== ''
        ? respuestaSheet.promocionSheet[2].replaceAll('\n', '<br>')
        : null
    this.descPromEng =
      respuestaSheet.promocionSheet && respuestaSheet.promocionSheet[3]
        ? respuestaSheet.promocionSheet[3].replaceAll('\n', '<br>')
        : null
    this.mattProm =
      respuestaSheet.promocionSheet && respuestaSheet.promocionSheet[4] ? respuestaSheet.promocionSheet[4] : null
    this.descInmSpa =
      respuestaSheet.inmuebleSheet && respuestaSheet.inmuebleSheet[2]
        ? respuestaSheet.inmuebleSheet[2].replaceAll('\n', '<br>')
        : null
    this.descInmEng =
      respuestaSheet.inmuebleSheet && respuestaSheet.inmuebleSheet[3]
        ? respuestaSheet.inmuebleSheet[3].replaceAll('\n', '<br>')
        : null
    this.mattInm =
      respuestaSheet.inmuebleSheet && respuestaSheet.inmuebleSheet[4] ? respuestaSheet.inmuebleSheet[4] : null
    // translate.engine = 'google' // Or "yandex", "libre", "deepl"
    // translate.key = 'AIzaSyDHWzqXcBSzNbsNZmYCVkwgap5NOt6Snvk'
    for (let i = 0; i < this.inmueble.codigosPropiosPromocion.length; i++) {
      let textoTrad = this.caracteristicas.find(
        x => x.codigoPropio === this.inmueble.codigosPropiosPromocion[i].codigoCP
      )
        ? this.caracteristicas.find(x => x.codigoPropio === this.inmueble.codigosPropiosPromocion[i].codigoCP)
            .tituloTrad
        : ''
      this.$set(this.inmueble.codigosPropiosPromocion[i], 'tituloTrad', textoTrad)
    }
    for (let i = 0; i < this.inmueble.codigosPropios.length; i++) {
      let textoTrad = this.caracteristicas.find(x => x.codigoPropio === this.inmueble.codigosPropios[i].codigoCP)
        ? this.caracteristicas.find(x => x.codigoPropio === this.inmueble.codigosPropios[i].codigoCP).tituloTrad
        : ''
      this.$set(this.inmueble.codigosPropios[i], 'tituloTrad', textoTrad)
    }
    window.scrollTo(0, 0)
    this.loading = false
    this.$nextTick(() => {
      if (this.inmueble.imagenes && this.inmueble.imagenes.length > 0) {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      }
    })
  },
  data() {
    return {
      loading: true,
      adminBreadcrumbs: [],
      inmueble: null,
      descPromEng: null,
      descPromSpa: null,
      descInmEng: null,
      descInmSpa: null,
      mattProm: null,
      mattInm: null,
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      },
      fab: false,
      caracteristicas: [
        {
          codigoPropio: 'EW_INFANTIL',
          titulo: 'Área infantil',
          tituloTrad: 'Children area',
          icon: 'mdi-baby-carriage'
        },
        {
          codigoPropio: 'EW_PISCINA',
          titulo: 'Piscina',
          tituloTrad: 'Pool',
          icon: 'mdi-pool'
        },
        {
          codigoPropio: 'EW_GIMNASIO',
          titulo: 'Gimnasio',
          tituloTrad: 'Gym',
          icon: 'mdi-dumbbell'
        },
        {
          codigoPropio: 'EW_COWORKING',
          titulo: 'Coworking',
          tituloTrad: 'Coworking',
          icon: 'mdi-access-point-network'
        },
        {
          codigoPropio: 'EW_TERRAZA',
          titulo: 'Terraza',
          tituloTrad: 'Terrace',
          icon: 'mdi-balcony'
        },
        {
          codigoPropio: 'EW_COCINA_AMUEB',
          titulo: 'Cocina amueblada',
          tituloTrad: 'Furnished kitchen',
          icon: 'mdi-fridge-outline'
        },
        {
          codigoPropio: 'EW_AMUEBLADO',
          titulo: 'Amueblado',
          tituloTrad: 'Furnished',
          icon: 'mdi-wardrobe'
        },
        {
          codigoPropio: 'EW_AREA_DEPORTI',
          titulo: 'Área deportiva',
          tituloTrad: 'Sport area',
          icon: 'mdi-basketball'
        },
        {
          codigoPropio: 'EW_COMUNITARIA',
          titulo: 'Sala comunitaria',
          tituloTrad: 'Community room',
          icon: 'mdi-account-group'
        },
        {
          codigoPropio: 'EW_GARAJE',
          titulo: 'Garaje',
          tituloTrad: 'Garage',
          icon: 'mdi-garage-variant'
        },
        {
          codigoPropio: 'EW_ASCENSOR',
          titulo: 'Ascensor',
          tituloTrad: 'Elevator',
          icon: 'mdi-inbox-arrow-up'
        },
        {
          codigoPropio: 'EW_JARDIN',
          titulo: 'Jardín',
          tituloTrad: 'Garden',
          icon: 'mdi-mower'
        },
        {
          codigoPropio: 'EW_CONSERJERIA',
          titulo: 'Conserjería',
          tituloTrad: 'Concierge',
          icon: 'mdi-account-key'
        },
        {
          codigoPropio: 'EW_CONSERJE',
          titulo: 'Conserje',
          tituloTrad: 'Concierge',
          icon: 'mdi-account-key'
        },
        {
          codigoPropio: 'EW_CERRADA',
          titulo: 'Urbanización cerrada',
          tituloTrad: 'Gated community',
          icon: 'mdi-door-closed-lock'
        },
        {
          codigoPropio: 'EW_VIGILANCIA',
          titulo: 'Vigilancia',
          tituloTrad: 'Surveillance',
          icon: 'mdi-cctv'
        },
        {
          codigoPropio: 'EW_CERTIFICADO',
          titulo: 'Certificado',
          tituloTrad: 'Energy certificate',
          icon: '',
          text: 'A++'
        }
      ],
      titulosForm: {
        titulo: 'contacto.titleForm2',
        subtituloAlquiler: 'contacto.subTitleFormRent',
        subtituloContacto: 'contacto.subTitleFormContact'
      },
      formulario: [
        {
          step: 1,
          titulo: 'formulario.formNameProperty.titulo', //nombre
          subtitulo: 'formulario.formNameProperty.subtitulo',
          label: 'formulario.formNameProperty.label',
          boton: 'formulario.formNameProperty.boton',
          type: 'nombre',
          literalStep: 'formulario.formNameProperty.literal'
        },
        {
          step: 2,
          titulo: 'formulario.formEmailProperty.titulo', //email
          subtitulo: 'formulario.formEmailProperty.subtitulo',
          label: 'formulario.formEmailProperty.label',
          boton: 'formulario.formEmailProperty.boton',
          type: 'email',
          literalStep: 'formulario.formEmailProperty.literal'
        },
        {
          step: 3,
          titulo: 'formulario.formPhoneProperty.titulo', //telefono
          subtitulo: 'formulario.formPhoneProperty.subtitulo',
          label: 'formulario.formPhoneProperty.label',
          boton: 'formulario.formPhoneProperty.boton',
          type: 'telefono',
          literalStep: 'formulario.formPhoneProperty.literal'
        },
        {
          step: 4,
          titulo: 'formulario.formPropertyProperty.titulo',
          subtitulo: 'formulario.formPropertyProperty.subtitulo',
          label: 'formulario.formPropertyProperty.label',
          boton: 'formulario.formPropertyProperty.boton',
          type: 'comentarios',
          maxStep: true,
          literalStep: 'formulario.formPropertyProperty.literal'
        }
      ]
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    codigosPropios() {
      if (this.loading) {
        return []
      }
      let retorno = this.inmueble.codigosPropiosPromocion
        .filter(x => x.codigoCP !== 'EW_PRO_DESC' && x.codigoCP !== 'EW_PRO_VISIBLE')
        .concat(this.inmueble.codigosPropios)
        .filter(
          x =>
            x.codigoCP !== 'EW_INM_DESC' &&
            x.codigoCP !== 'EW_INM_VISIBLE' &&
            (x.valor === 'Sí' || x.codigoCP == 'EW_CERTIFICADO')
        )
        .map(x => {
          return {
            ...x,
            icon: this.caracteristicas.find(y => y.codigoPropio === x.codigoCP)
              ? this.caracteristicas.find(y => y.codigoPropio === x.codigoCP).icon
              : false,
            text: x.valor,
            titulo: x.codigo.descripcionCP
          }
        })
      if (this.inmueble.descripcionTipo === 'Garaje') {
        retorno.push({
          icon: this.caracteristicas.find(y => y.codigoPropio === 'EW_GARAJE').icon,
          valor: 'Sí',
          titulo: 'Garaje'
        })
      }
      return retorno
    },
    fullURL() {
      return window.baseURL + this.$route.fullPath
    }
  },
  methods: {
    ...mapActions({
      loadInmueble: 'properties/loadInmueble',
      loadLongContent: 'properties/loadLongContent'
    })
  }
}
</script>

<style scoped>
.parent {
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.child {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.tarjeta:hover .child,
.tarjeta:focus .child {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.dividerTitle {
  height: 2px;
  max-height: 2px;
}
.tarjeta:hover .child:before,
.tarjeta:focus .child:before {
  display: block;
}

.child:before {
  content: '';
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.carac {
  height: 80px !important;
  width: 80px !important;
}
.thumb-example {
  height: 500px;
  background-color: 'black';
}
.swiper .swiper-slide {
  background-size: cover;
  background-position: center;
}
.swiper .swiper-slide.slide-1 {
  background-image: url('../../assets/fake_properties/14/001.png');
}
.swiper .swiper-slide.slide-2 {
  background-image: url('../../assets/fake_properties/14/002.png');
}
.swiper .swiper-slide.slide-3 {
  background-image: url('../../assets/fake_properties/14/003.png');
}
.swiper .swiper-slide.slide-4 {
  background-image: url('../../assets/fake_properties/14/004.png');
}
.swiper .swiper-slide.slide-5 {
  background-image: url('../../assets/fake_properties/14/005.png');
}
.swiper.gallery-top {
  height: 100%;
  width: 100%;
}
.swiper.gallery-thumbs {
  /* height: 100%; */
  box-sizing: border-box;
  padding-top: 10px;
}
.swiper.gallery-thumbs .swiper-slide {
  width: 15%;
  height: 100px;
  opacity: 0.5;
}
.swiper.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}

@media only screen and (max-width: 400px) {
  .carac {
    height: 50px !important;
    width: 50px !important;
  }
}
@media only screen and (max-width: 400px) {
  .caracText {
    font-size: 9px !important;
  }
}
</style>
