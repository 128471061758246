<template>
  <pages-layout>
    <template v-slot:content>
      <Header :title="'titulosSecciones.news'" :image="require('@/assets/news/header8.jpg')" />
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="11" md="12"
            ><v-row class="pl-8 hidden-md-and-up"><h4>TAGS:</h4></v-row>
            <v-row class="pl-8 mb-6 hidden-md-and-up">
              <v-btn
                class="mx-2 mt-2"
                v-for="categoria in categorias"
                :key="categoria.id"
                color="primary"
                :disabled="categoriasAdded.some(x => x.id === categoria.id)"
                @click="addCategoria(categoria)"
                >{{ $t(categoria.texto) }}</v-btn
              ></v-row
            ><v-row class="pl-8 mb-8 hidden-md-and-up">
              <v-chip
                class="mx-2 mt-2"
                v-for="added in categoriasAdded"
                :key="added.id"
                close
                close-icon="mdi-close-outline"
                color="secondary"
                @click:close="borrarCategoria(added)"
                >{{ $t(categorias.find(x => x.code === added).texto) }}</v-chip
              ></v-row
            >
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="7" lg="8" xl="8">
                <CardNews
                  v-for="noticia in noticiasFiltradas"
                  :key="noticia.id"
                  :image="noticia.foto"
                  :title="'blog.noticia' + noticia.id + '.titulo'"
                  :text="'blog.noticia' + noticia.id + '.descripcionCorta'"
                  :date="noticia.fecha"
                  :height="'300px'"
                  @click.native.stop="goTo(noticia)"
                />
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" md="4" lg="3" xl="3">
                <v-row class="pl-8 hidden-sm-and-down"><h4>TAGS:</h4></v-row>
                <v-row class="pl-8 mb-8 hidden-sm-and-down">
                  <v-btn
                    class="mx-2 mt-2"
                    v-for="categoria in categorias"
                    :key="categoria.id"
                    color="primary"
                    :disabled="categoriasAdded.some(x => x.id === categoria.id)"
                    @click="addCategoria(categoria)"
                    >{{ $t(categoria.texto) }}</v-btn
                  ></v-row
                ><v-row class="pl-8 mb-8 hidden-sm-and-down">
                  <v-chip
                    class="mx-2 mt-2"
                    v-for="added in categoriasAdded"
                    :key="added.id"
                    close
                    close-icon="mdi-close-outline"
                    color="secondary"
                    @click:close="borrarCategoria(added)"
                    >{{ $t(categorias.find(x => x.code === added).texto) }}</v-chip
                  ></v-row
                >
                <v-divider></v-divider>

                <CardNewsSmall
                  v-for="noticia in noticiasNews"
                  :key="noticia.id"
                  :image="noticia.foto"
                  :title="'blog.noticia' + noticia.id + '.titulo'"
                  :text="'blog.noticia' + noticia.id + '.descripcionCorta'"
                  :date="noticia.fecha"
                  :height="'100px'"
                  @click.native.stop="goTo(noticia)"
                />
              </v-col>
            </v-row> </v-col></v-row
      ></v-col>
    </template>
  </pages-layout>
</template>
<script>
import Header from '@/components/Header'
import CardNews from '@/components/CardNews'
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import CardNewsSmall from '@/components/CardNewsSmall'
export default {
  name: 'news',
  components: {
    Header,
    CardNews,
    CardNewsSmall
  },
  data() {
    return {
      categoriasAdded: []
    }
  },
  computed: {
    ...mapGetters({
      noticias: 'blog/getNoticias',
      categorias: 'blog/getCategorias'
    }),
    noticiasNews() {
      let retorno = _.orderBy(
        this.noticias.filter(x => x.categorias.includes('new')),
        [
          function(noticia) {
            return moment(new Date(noticia.fecha)).format('YYYYMMDD')
          }
        ],
        ['desc']
      )
      retorno = retorno.map(x => {
        return { ...x, fecha: moment(new Date(x.fecha)).format('DD/MM/YYYY') }
      })
      return retorno
    },
    noticiasFiltradas() {
      let retorno = _.orderBy(
        this.noticias,
        [
          function(noticia) {
            return moment(new Date(noticia.fecha)).format('YYYYMMDD')
          }
        ],
        ['desc']
      )
      if (this.categoriasAdded.length > 0) {
        retorno = retorno.filter(x => _.intersection(this.categoriasAdded, x.categorias).length > 0)
      }
      retorno = retorno.map(x => {
        return { ...x, fecha: moment(new Date(x.fecha)).format('DD/MM/YYYY') }
      })
      return retorno
    }
  },
  methods: {
    borrarCategoria(categoria) {
      this.categoriasAdded = this.categoriasAdded.filter(x => x !== categoria)
    },
    goTo(accion) {
      this.$router.push({ name: 'articulo', params: { ruta: accion.enlace } })
    },
    addCategoria(categoria) {
      if (this.categoriasAdded.some(x => x === categoria.code)) {
        return
      } else {
        this.categoriasAdded.push(categoria.code)
      }
    }
  }
}
</script>
