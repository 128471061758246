<template>
  <pages-layout :fillHeight="true">
    <template v-slot:content>
      <iframe id="comercializacion-digital" width="100%" height="100%" frameBorder="0" :src="urlIncrustado" />
    </template>
  </pages-layout>
</template>
<script>
export default {
  name: 'comercializacion-digital',
  created() {
    window.scrollTo(0, 0)
  },
  computed: {
    urlIncrustado() {
      let url = window.urlComercializacionDigital + '/incrustado/condiciones?jsondata='
      let queryParams = JSON.stringify(this.parametros)
      return url + queryParams
    },
    parametros() {
      return {
        parametros: {
          cod_subdominio_agrupacion: this.codSubdominioAgrupacion,
          codigoActivo: this.codigoActivo,
          idubapi: 3929300107,
          codigoPromocion: this.codigoPromocion
        }
      }
    },
    codigoActivo() {
      return this.$route.query.codigoActivo != null ? this.$route.query.codigoActivo : null
    },
    codigoPromocion() {
      return this.$route.query.codigoPromocion != null ? this.$route.query.codigoPromocion : null
    },
    codSubdominioAgrupacion() {
      return window.codSubdominioAgrupacion != null ? window.codSubdominioAgrupacion : null
    }
  }
}
</script>
