<template>
  <v-row justify="center">
    <v-col class="text-center" cols="8">
      <h2 class=" primary--text">
        {{ this.$t(titulo) }}
      </h2>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['titulo', 'color'],
  name: 'TituloSeccionH2',
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>
