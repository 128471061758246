<template>
  <pages-layout>
    <template v-slot:content>
      <v-expand-transition>
        <v-col
          v-show="backs"
          cols="12"
          style="position: fixed;  background-color: black; top: 0px; left:0px; right:0px; bottom: 0px; z-index: 2; opacity: 0.5"
          class="shrink"
        ></v-col
      ></v-expand-transition>
      <v-col cols="12" style="">
        <v-row justify="center" class="mb-12 mb-md-0 mb-xl-0 mb-lg-0">
          <v-col cols="11" md="3" lg="3" xl="3" align="start">
            <v-col
              cols="12"
              md="3"
              lg="3"
              xl="3"
              :class="
                $vuetify.breakpoint.name === 'md' ||
                $vuetify.breakpoint.name === 'lg' ||
                $vuetify.breakpoint.name === 'xl'
                  ? 'fijadosIzq'
                  : ''
              "
            >
              <FilterProperty
                :filtro="'promocion'"
                v-model="promocion"
                :promociones="promociones"
                :loading="loading || !iniciado"
              />
              <FilterProperty
                :filtro="'ciudad'"
                v-model="ciudad"
                :disabled="disableCiudad"
                :loading="loading || !iniciado"
                :promociones="promociones"
              />
              <FilterProperty
                :filtro="'tipologia'"
                v-model="tipologia"
                :tipologias="tipologiasTraducidas"
                :loading="loading || !iniciado"
              />
              <FilterProperty
                :filtro="'dormitorios'"
                v-model="dormitorios"
                :loading="loading || !iniciado"
                v-if="tipologia == null || tipologia == 'Vivienda' || tipologia == 'Dwelling'"
              />
              <FilterProperty
                :filtro="'muebles'"
                v-if="tipologia == null || tipologia == 'Vivienda' || tipologia == 'Dwelling'"
                v-model="amueblado"
                :loading="loading || !iniciado"
              />
              <FilterProperty
                :filtro="'precio'"
                v-model="rangoPrecio"
                @soltado="sueltoRango($event)"
                :loading="loading || !iniciado"
                @actMin="actMin($event)"
                @actMax="actMax($event)"
              />
              <OrderComponent :conTitulo="false" :order="orden" v-model="selectOrder" :loading="loading || !iniciado" />
              <v-text-field
                v-model="busqueda"
                placeholder="Busca una descripción"
                single-line
                @blur="buscar($event)"
                class="mr-4"
              ></v-text-field>
            </v-col>
          </v-col>
          <v-expansion-panels v-if="scrolled" focusable class="mt-0" style="position: fixed;  top: 55px; z-index: 3">
            <v-expansion-panel>
              <v-expansion-panel-header @click="backgroundExp()">
                <h4>{{ $t('properties.filters') }}:</h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12" style="overflow: scroll; height: 57vh">
                  <FilterProperty
                    :filtro="'promocion'"
                    v-model="promocion"
                    :promociones="promociones"
                    class="pt-12"
                    :loading="loading || !iniciado"
                  />
                  <FilterProperty
                    :filtro="'ciudad'"
                    v-model="ciudad"
                    :promociones="promociones"
                    :disabled="disableCiudad || loading"
                    :loading="loading || !iniciado"
                  />
                  <FilterProperty
                    :filtro="'tipologia'"
                    v-model="tipologia"
                    :tipologias="tipologiasTraducidas"
                    :disabled="loading || !iniciado"
                  />
                  <FilterProperty
                    :filtro="'dormitorios'"
                    v-model="dormitorios"
                    :loading="loading || !iniciado"
                    v-if="tipologia == null || tipologia == 'Vivienda'"
                  />
                  <FilterProperty
                    :filtro="'muebles'"
                    v-model="amueblado"
                    :loading="loading || !iniciado"
                    v-if="tipologia == null || tipologia == 'Vivienda'"
                  />
                  <FilterProperty
                    :filtro="'precio'"
                    v-model="rangoPrecio"
                    @soltado="sueltoRango($event)"
                    :loading="loading || !iniciado"
                    @actMin="actMin($event)"
                    @actMax="actMax($event)"
                  />

                  <OrderComponent
                    :conTitulo="false"
                    :order="orden"
                    v-model="selectOrder"
                    :loading="loading || !iniciado"
                  />
                  <v-text-field
                    v-model="busqueda"
                    :placeholder="$t('properties.busqueda')"
                    single-line
                    @blur="buscar($event)"
                    class="mr-4"
                  ></v-text-field>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider class="hidden-sm-and-down" vertical style="min-height:90vh" />
          <v-col v-if="!loading && iniciado" cols="11" md="8" lg="8" xl="8" class="pt-0 pt-md-10 pt-lg-10 pt-xl-10">
            <CardProperty
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="50"
              data-aos-anchor-placement="top-bottom"
              v-for="property in properties"
              :key="property.id"
              :item="property"
              :height="'190'"
            />
            <v-row
              v-if="properties == null || properties.length === 0"
              class="mb-14 mb-md-2 mb-lg-2 mb-xl-2 text-center"
              justify="center"
            >
              <v-col cols="12" align="center">
                <v-col cols="7" md="6" lg="4" justify="center">
                  <v-img
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    src="@/assets/properties/no_results_01.png"
                  ></v-img>
                </v-col>
                <h2 data-aos="fade-up" data-aos-duration="1200" class="mt-16">
                  {{ alertError == null ? $t('properties.noresults') : alertError }}
                </h2>
              </v-col>
              <v-col cols="12">
                <p data-aos="fade-up" data-aos-duration="1000">
                  {{ alertError == null ? $t('properties.noresultsSubtitle') : alertError }}
                </p>
              </v-col>
            </v-row>
            <v-row justify="end" v-else-if="properties != null">
              <v-pagination
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="50"
                data-aos-anchor-placement="top-bottom"
                v-model="page"
                :length="lengthPagination"
                :total-visible="10"
              />
            </v-row>
          </v-col>
          <v-col v-else cols="11" md="8" lg="8" xl="8" class="pt-0 pt-md-10 pt-lg-10 pt-xl-10">
            <v-row justify="center">
              <div style="margin-top:100px">
                <!-- <atom-spinner :animation-duration="1000" :size="60" :color="'#25BECB'" /> -->
                <breeding-rhombus-spinner :animation-duration="2000" :size="200" :color="'#25BECB'" />
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </pages-layout>
</template>

<script>
import CardProperty from '@/components/CardProperty'
import FilterProperty from './FilterProperty'
import OrderComponent from './OrderComponent'
import { BreedingRhombusSpinner } from 'epic-spinners'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'properties',
  components: {
    CardProperty,
    // AtomSpinner,
    BreedingRhombusSpinner,
    FilterProperty,
    OrderComponent
  },
  data() {
    return {
      page: 1,
      iniciado: false,
      lengthPagination: 1,
      tipologia: null,
      dormitorios: [],
      soltadoRango: true,
      rangoPrecio: [0, 3000],
      amueblado: false,
      ciudad: null,
      disableCiudad: false,
      promociones: [],
      tipologias: [],
      promocion: null,
      alertError: null,
      properties: null,
      superficie: null,
      scrolled: false,
      loading: false,
      selectOrder: 'Recientes',
      offsetTop: 0,
      expansion: false,
      busqueda: null,
      backgroundExpansion: false
    }
  },
  async created() {
    if (localStorage == 'en') {
      this.$root.$i18n.locale = 'en'
    }
    this.promociones = await this.loadPromociones()
    this.tipologias = await this.loadTipologias()
    // CARGO LOS FILTROS DE LOCAL STORAGE
    this.ciudad =
      localStorage.ciudad != null
        ? localStorage.ciudad === 'null' || localStorage.ciudad == 'undefined'
          ? null
          : localStorage.ciudad
        : this.ciudad
    this.tipologia = localStorage.tipologia != null ? localStorage.tipologia : this.tipologia
    this.dormitorios = localStorage.dormitorios != null ? JSON.parse(localStorage.dormitorios) : this.dormitorios
    this.amueblado =
      localStorage.amueblado != null ? (localStorage.amueblado === 'false' ? false : true) : this.amueblado
    this.promocion =
      localStorage.promocion != null
        ? localStorage.promocion === 'null' || localStorage.promocion == 'undefined'
          ? null
          : localStorage.promocion
        : this.promocion
    if (this.$route.params.codigo != null) {
      this.promocion = this.promociones.find(x => x.codigo == this.$route.params.codigo).codigo
      localStorage.promocion = this.promocion
    }
    this.rangoPrecio = localStorage.rangoPrecio != null ? JSON.parse(localStorage.rangoPrecio) : this.rangoPrecio
    this.selectOrder = localStorage.selectOrder != null ? localStorage.selectOrder : this.selectOrder

    if (this.tipologia == null && this.tipologias.length > 0 && this.tipologiasTraducidas.some(x => x === 'Vivienda')) {
      this.tipologia = 'Vivienda'
    }
    if (this.tipologia == null && this.tipologias.length > 0 && this.tipologiasTraducidas.some(x => x === 'Dwelling')) {
      this.tipologia = 'Dwelling'
    }
    if (this.$route.query.rangoPrecio != null) {
      this.rangoPrecio = this.$route.query.rangoPrecio
    }
    if (this.$route.query.ciudad != null) {
      this.ciudad = this.$route.query.ciudad
    }
    if (this.$route.query.tipologia != null) {
      this.tipologia = this.$route.query.tipologia
    }
    if (this.$root.$i18n.locale == 'en') {
      this.tipologia = this.cambiarTipo(this.tipologia)
    }
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll)
    this.updateFiltros()
    this.iniciado = true
    await this.cargarInmuebles()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    ...mapGetters({
      getLang: 'properties/getLang'
    }),
    tipologiasTraducidas() {
      if (this.$root.$i18n.locale == 'es') {
        return this.tipologias
      } else {
        return this.tipologias.map(x => {
          switch (x) {
            case 'Vivienda':
              return 'Dwelling'
            case 'Garaje':
              return 'Parking'
            case 'Local':
              return 'Premises'
            case 'Trastero':
              return 'Storage'
          }
        })
      }
    },
    orden() {
      return [
        { text: this.$t('properties.order.bajos'), value: 'Baratos' },
        { text: this.$t('properties.order.altos'), value: 'Caros' },
        { text: this.$t('properties.order.pequenios'), value: 'Pequeños' },
        { text: this.$t('properties.order.grandes'), value: 'Grandes' },
        { text: this.$t('properties.order.recientes'), value: 'Recientes' },
        { text: this.$t('properties.order.alfabeticamente'), value: 'Alfabeticamente' },
        { text: this.$t('properties.order.notalfabeticamente'), value: 'NotAlfabeticamente' }
      ]
    },
    mensajeError() {
      return this.$t('errors.serverError')
    },
    filtros() {
      return {
        precio: this.rangoPrecio,
        ciudad: this.ciudad,
        tipologia: this.noTraducirTipo(this.tipologia),
        dormitorios: this.dormitorios,
        amueblado: this.amueblado,
        promocion: this.promocion,
        orden: this.selectOrder,
        busqueda: this.busqueda
      }
    },
    backs() {
      if (this.expansion === true && this.scrolled === true) {
        return true
      }
      if (this.expansion === true && this.scrolled === false) {
        this.backgroundExp()
        return false
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions({
      addAlert: 'alerts/add',
      loadPromociones: 'properties/loadPromociones',
      loadTipologias: 'properties/loadTipologias',
      propertiesWithFilters: 'properties/getInmuebles'
    }),
    sueltoRango(valor) {
      this.soltadoRango = valor
      if (this.soltadoRango) {
        this.cargarInmuebles()
      }
    },
    actMin(valor) {
      this.$set(this.rangoPrecio, 0, valor)
      this.cargarInmuebles()
    },
    buscar() {
      this.cargarInmuebles()
    },
    actMax(valor) {
      this.$set(this.rangoPrecio, 1, valor)
      this.cargarInmuebles()
    },
    cambiarTipo(tipo) {
      switch (tipo) {
        case 'Vivienda':
          return 'Dwelling'
        case 'Garaje':
          return 'Parking'
        case 'Local':
          return 'Premises'
        case 'Trastero':
          return 'Storage'
        case 'Dwelling':
          return 'Vivienda'
        case 'Parking':
          return 'Garaje'
        case 'Premises':
          return 'Local'
        case 'Storage':
          return 'Trastero'
      }
    },
    noTraducirTipo(tipo) {
      if (this.$root.$i18n.locale == 'es') {
        return tipo
      } else {
        switch (tipo) {
          case 'Dwelling':
            return 'Vivienda'
          case 'Parking':
            return 'Garaje'
          case 'Premises':
            return 'Local'
          case 'Storage':
            return 'Trastero'
        }
      }
    },
    goTo(id) {
      this.$router.push({ name: 'property', params: { id: id } })
    },
    updateFiltros() {
      localStorage.ciudad = this.ciudad
      localStorage.tipologia = this.tipologia
      localStorage.dormitorios = JSON.stringify(this.dormitorios)
      localStorage.amueblado = this.amueblado
      localStorage.promocion = this.promocion
      localStorage.rangoPrecio = JSON.stringify(this.rangoPrecio)
      localStorage.selectOrder = this.selectOrder
    },
    /* backgroundExp() {
      if (this.expansion && this.scrolled) this.backgroundExpansion = true
      else {
        this.backgroundExpansion = false
      }
    }, */
    async cargarInmuebles() {
      if (!this.iniciado || this.loading) {
        return
      }
      this.loading = true
      this.properties = []
      this.updateFiltros()
      try {
        let response = await this.propertiesWithFilters({
          filtros: this.filtros,
          pagina: this.page,
          ordenacion: this.selectOrder
        })

        this.properties = response.items.filter(x => {
          return !this.amueblado || x.codigosPropios.some(e => e.codigoCP === 'EW_AMUEBLADO')
        })

        this.lengthPagination = Math.ceil(response.totalItems / 10)
        // this.properties = this.edificiosTest
        // this.lengthPagination = 1
        this.alertError = null
        this.loading = false
      } catch (error) {
        this.alertError = this.mensajeError
        this.addAlert({ texto: this.mensajeError, color: 'error' })
        this.loading = false
      }
    },
    backgroundExp() {
      this.expansion = !this.expansion
    },
    handleScroll() {
      if (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs') {
        this.scrolled = window.scrollY >= 600
      }
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop
    }
  },
  watch: {
    page() {
      window.scrollTo(0, 0)
      this.cargarInmuebles()
    },

    selectOrder() {
      window.scrollTo(0, 0)
      this.cargarInmuebles()
    },
    ciudad() {
      if (!this.disableCiudad) {
        window.scrollTo(0, 0)
        this.cargarInmuebles()
      }
      if (this.$route.query.ciudad != null) {
        let query = Object.assign({}, this.$route.query)
        delete query.ciudad
        this.$router.replace({ query })
      }
    },
    tipologia() {
      window.scrollTo(0, 0)
      if (this.$route.query.tipologia != null) {
        let query = Object.assign({}, this.$route.query)
        delete query.tipologia
        this.$router.replace({ query })
      }
      this.cargarInmuebles()
    },
    rangoPrecio() {
      window.scrollTo(0, 0)
      if (this.$route.query.rangoPrecio != null) {
        let query = Object.assign({}, this.$route.query)
        delete query.rangoPrecio
        this.$router.replace({ query })
      }
      if (this.soltadoRango) {
        this.cargarInmuebles()
      }
    },
    dormitorios() {
      window.scrollTo(0, 0)
      this.cargarInmuebles()
    },
    amueblado() {
      window.scrollTo(0, 0)
      this.cargarInmuebles()
    },
    getLang() {
      console.log('watch de get lang')
      this.tipologia = this.cambiarTipo(this.tipologia)
    },
    promocion(newValue) {
      if (newValue != null) {
        this.disableCiudad = true
        this.ciudad = this.promociones.find(x => x.codigo === newValue)
          ? this.promociones.find(x => x.codigo === newValue).provincia
          : null
      } else {
        this.ciudad = null
        this.disableCiudad = false
      }
      window.scrollTo(0, 0)
      this.cargarInmuebles()
    }
  }
}
</script>
<style scoped>
.fijadosIzq {
  position: fixed;
}
</style>
