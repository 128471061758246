<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card class="mb-5 tarjeta" style="cursor:pointer" :elevation="hover ? 5 : 2">
        <v-row>
          <v-col cols="12" lg="6" xl="6">
            <!--  <v-img
              :src="image"
              class="white--text align-end ml-4"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              :height="height"
            ></v-img> -->
            <div class="parent mx-2 mx-sm-4 " :style="'height:' + height + '; width:95%'">
              <div
                class="child"
                :style="
                  'background-image:url(' +
                    image +
                    '); width: 95%; height:100%; background-position: center; background-size: cover;'
                "
              ></div>
            </div>
          </v-col>
          <v-col cols="12" lg="6" xl="6" align="start">
            <v-card-title style="word-break: normal">{{ $t(title) }}</v-card-title>
            <v-card-subtitle class="pb-0">{{ date }}</v-card-subtitle>
            <v-card-text class="pb-0">{{ $t(text) }}</v-card-text>
          </v-col></v-row
        >
      </v-card></v-hover
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['title', 'image', 'date', 'text', 'height'],
  name: 'CardNews',
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
// width="500px" height="400px"
</script>
<style scoped>
.parent {
  overflow: hidden !important;
  position: relative !important;
  display: inline-block !important;
}
.child {
  height: 100% !important;
  width: 100% !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  -webkit-transition: all 0.5s !important;
  -moz-transition: all 0.5s !important;
  -o-transition: all 0.5s !important;
  transition: all 0.5s !important;
}
.tarjeta:hover .child,
.tarjeta:focus .child {
  -ms-transform: scale(1.2) !important;
  -moz-transform: scale(1.2) !important;
  -webkit-transform: scale(1.2) !important;
  -o-transform: scale(1.2) !important;
  transform: scale(1.2) !important;
}

.tarjeta:hover .child:before,
.tarjeta:focus .child:before {
  display: block;
}

.child:before {
  content: '' !important;
  display: none !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}
</style>
