<template>
  <pages-layout>
    <template v-slot:content>
      <Header :title="'titulosSecciones.howtorent'" :image="require('@/assets/howtorent/howtorent_header.jpg')" />

      <v-col cols="12" class="pt-0 mt-0 mb-0 pb-0">
        <v-row class="pb-8" justify="center">
          <v-col cols="12">
            <p
              data-aos="fade-up"
              class="mr-8 ml-8 mt-10 pb-2"
              style="font-size:50px; font-weight:500; color:#25becb; text-align: center;"
            >
              {{ $t('howtorent.claim') }}
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col data-aos="fade-up" data-aos-duration="1400" cols="12 " class="px-0 pb-0">
        <swiper class="swiper" :options="swiperOption">
          <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
          <!-- ENCUENTRA -->
          <swiper-slide style=" color: white;" class="px-2">
            <v-col cols="12" md="11" class="ml-0 ml-md-16">
              <p
                data-swiper-parallax="-100"
                class="mb-0 text-center text-md-left ml-0 ml-md-16 lgSizeTitle"
                :style="'font-size:' + textSizeCarrousel[0]"
                style="font-weight:400; color:white;"
              >
                {{ $t('howtorent.find.title') }}
              </p>
              <v-col cols="12" md="6" lg="6" xl="6" class="pt-4 ml-md-16 pr-md-16">
                <p
                  class="text-center text-md-left ml-0 pr-md-16 lgSizeText"
                  :style="'font-size:' + textSizeCarrousel[1]"
                  style="font-size:20px; font-weight-light;"
                >
                  {{ $t('howtorent.find.text1') }}
                  <router-link to="properties"> {{ $t('howtorent.find.text2') }}</router-link>
                  {{ $t('howtorent.find.text3') }} {{ $t('howtorent.find.text4') }}
                  <router-link to="contact"> {{ $t('howtorent.find.text2') }}</router-link>
                </p>
              </v-col>
            </v-col>
          </swiper-slide>
          <!-- VISITALA -->
          <swiper-slide style="color: white;" class="px-2">
            <v-col cols="10" class="ml-10 ml-md-12">
              <p
                data-swiper-parallax="-100"
                class="mb-0 ml-0 ml-md-12"
                :style="'font-size:' + textSizeCarrousel[0]"
                style="font-weight:400;"
              >
                {{ $t('howtorent.visit.title') }}
              </p>
              <p
                class="ml-0 ml-md-12 lgSizeText"
                :style="'font-size:' + textSizeCarrousel[1]"
                style="font-weight-light;"
              >
                {{ $t('howtorent.visit.text') }}
              </p>
            </v-col>
            <v-col cols="10" md="7" lg="7" xl="7" class="ml-10 ml-md-16 pt-0">
              <ListElement
                class="ml-md-16"
                :size="$vuetify.breakpoint.name !== 'xl' ? textSizeCarrousel[1] : widthInner >= 2066 ? '40px' : '25px'"
                :texto="$t('howtorent.visit.list1')"
              />
              <ListElement
                class="ml-md-16"
                :size="$vuetify.breakpoint.name !== 'xl' ? textSizeCarrousel[1] : widthInner >= 2066 ? '40px' : '25px'"
                :texto="$t('howtorent.visit.list2')"
                :link="true"
              />
            </v-col>
          </swiper-slide>
          <!-- REVISA LAS CONDICIONES -->
          <swiper-slide style="color: white;" class="px-2">
            <v-col cols="12" md="11" class="ml-0 ml-md-16">
              <p
                data-swiper-parallax="-100"
                class="mb-0 text-center text-md-left ml-0 ml-md-16"
                :style="'font-size:' + textSizeCarrousel[0]"
                style="font-weight:400;"
              >
                {{ $t('howtorent.conditions.title') }}
              </p>
              <v-col cols="12" md="5" lg="6" xl="6" class="pt-4 ml-md-16 px-10 pl-md-0 pr-md-14">
                <p
                  class="text-center text-md-left lgSizeText"
                  :style="'font-size:' + textSizeCarrousel[1]"
                  style=" font-weight-light; "
                >
                  {{ $t('howtorent.conditions.text') }}
                </p>
              </v-col></v-col
            >
          </swiper-slide>
          <!-- REGISTRATE -->
          <swiper-slide style="color: white;" class="px-2">
            <v-col cols="12" class="ml-0 ml-md-16">
              <p
                data-swiper-parallax="-100"
                class="mb-0 text-center text-md-left ml-0 ml-md-16"
                :style="'font-size:' + textSizeCarrousel[0]"
                style="font-weight:400;"
              >
                {{ $t('howtorent.register.title') }}
              </p>
              <v-col cols="12" md="6" lg="6" xl="6" class="pt-4 ml-md-16 pr-md-16">
                <p
                  class="text-center text-md-left lgSizeText"
                  :style="'font-size:' + textSizeCarrousel[1]"
                  style=" font-weight-light; text-align: left;"
                >
                  {{ $t('howtorent.register.text') }}
                </p>
              </v-col></v-col
            >
          </swiper-slide>
          <!-- RESERVA -->
          <swiper-slide style="color: white;" class="px-2">
            <v-col cols="12" class="ml-0 ml-md-16">
              <p
                data-swiper-parallax="-100"
                class="mb-0 text-center text-md-left ml-0 ml-md-16"
                :style="'font-size:' + textSizeCarrousel[0]"
                style="font-weight:400;"
              >
                {{ $t('howtorent.reserve.title') }}
              </p>
              <v-col cols="12" md="6" lg="6" xl="6" class="pt-4 ml-md-16 pr-md-16">
                <p
                  class="text-center text-md-left lgSizeText"
                  :style="'font-size:' + textSizeCarrousel[1]"
                  style=" font-weight-light; text-align: left;"
                >
                  {{ $t('howtorent.reserve.text1') }}
                </p>
              </v-col></v-col
            >
          </swiper-slide>
          <!-- DOCUMENTACIÓN -->
          <swiper-slide style="color: white;" class="px-2 mb-6">
            <v-col cols="12" class="ml-0 ml-md-16 ">
              <p class="mb-0" style="font-size:28px; font-weight:600;">
                {{ $t('howtorent.reserve.text2') }}
              </p>
              <v-col
                v-if="
                  $vuetify.breakpoint.name === 'md' ||
                    $vuetify.breakpoint.name === 'lg' ||
                    $vuetify.breakpoint.name === 'xl'
                "
                cols="12"
                md="9"
                lg="9"
                xl="9"
                style="line-height: 160%; "
                class="ml-6"
              >
                <ListElement
                  :size="
                    $vuetify.breakpoint.name !== 'xl' ? textSizeCarrousel[1] : widthInner >= 2066 ? '40px' : '25px'
                  "
                  v-for="(doc, i) in $t('howtorent.reserve.documentation')"
                  :key="i"
                  class="ml-0"
                  :style="$vuetify.breakpoint.name !== 'xl' ? 'line-height: 24px' : 'line-height: 40px'"
                  :texto="doc"
                /> </v-col
              ><v-col v-else cols="12" class="px-0 mx-0 my-0 py-0">
                <v-row justify="center" class="text-right my-0 py-0">
                  <v-col cols="6" class="my-0 py-0" v-for="(doc, i) in $t('howtorent.reserve.documentation2')" :key="i">
                    <ListElement :size="textSizeCarrousel[1]" class="my-0 py-0" :texto="doc" /> </v-col
                ></v-row>
                <v-col cols="12" class="px-0 mx-0 ">
                  <p class="my-0 py-0" style="font-size:18px; font-weight:600; color:white;">
                    {{ $t('howtorent.reserve.text4') }}
                  </p>
                  <v-row justify="center" class="text-right ">
                    <v-col
                      cols="12"
                      class="my-0 py-0"
                      style="line-height: 60%; "
                      v-for="(doc, i) in $t('howtorent.reserve.documentation3')"
                      :key="i"
                    >
                      <ListElement
                        :size="textSizeCarrousel[1]"
                        class="my-0 py-0"
                        :texto="doc"
                      /> </v-col></v-row></v-col></v-col
            ></v-col>
          </swiper-slide>
          <!-- ALQUILA -->
          <swiper-slide style="color: white;" class="px-2">
            <v-col cols="12" class="ml-0 ml-md-16">
              <p
                data-swiper-parallax="-100"
                class="ml-0 ml-sm-16"
                :style="'font-size:' + textSizeCarrousel[0]"
                style="font-weight:400; "
              >
                {{ $t('howtorent.rent.title') }}
              </p>
              <v-row>
                <v-col cols="12" md="6" lg="6" xl="6" class="">
                  <p
                    class="ml-0 ml-sm-16 lgSizeText"
                    :style="'font-size:' + textSizeCarrousel[1]"
                    style="font-size:20px; font-weight-light; text-align: left;"
                  >
                    {{ $t('howtorent.rent.text') }}
                  </p>
                </v-col>
              </v-row></v-col
            >
          </swiper-slide>
          <!-- VIVE -->
          <swiper-slide style="color: white;" class="px-2 pt-0">
            <v-col cols="12" class="ml-0 ml-md-16">
              <p class="ml-0 ml-md-16 py-0 my-0" style="font-size:80px; font-weight:400;">
                {{ $t('howtorent.live.title') }}
              </p>
              <v-row no-gutters>
                <v-col cols="12" md="9" lg="9" xl="9" class="ml-0 ml-md-16">
                  <p
                    class="lgSizeText"
                    :style="'font-size:' + textSizeCarrousel[1]"
                    style="font-weight-light; text-align: left;"
                  >
                    {{ $t('howtorent.live.text') }}
                    <router-link to="about"> {{ $t('howtorent.find.text2') }}</router-link>
                    .
                  </p>
                  <p
                    class="mt-n3 lgSizeText"
                    :style="'font-size:' + textSizeCarrousel[1]"
                    style="font-size:20px; font-weight-light; text-align: left;"
                  >
                    {{ $t('howtorent.live.text2') }}
                  </p>
                </v-col>
              </v-row></v-col
            >
          </swiper-slide>
          <div class="swiper-pagination swiper-pagination-bullets swiper-pagination-white" slot="pagination"></div>
          <div
            v-if="
              $vuetify.breakpoint.name === 'md' ||
                $vuetify.breakpoint.name === 'lg' ||
                $vuetify.breakpoint.name === 'xl'
            "
            class="swiper-button-prev swiper-button-white"
            slot="button-prev"
          ></div>
          <div
            v-if="
              $vuetify.breakpoint.name === 'md' ||
                $vuetify.breakpoint.name === 'lg' ||
                $vuetify.breakpoint.name === 'xl'
            "
            class="swiper-button-next swiper-button-white"
            slot="button-next"
          ></div>
        </swiper>
      </v-col>

      <!-- TODO INTEGRAR CALL TO ACTION -->
    </template>
  </pages-layout>
</template>
<script>
import ListElement from '@/components/ListElement'
import Header from '@/components/Header'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'howtorent',
  components: {
    Header,
    ListElement,
    Swiper,
    SwiperSlide

    //NavigationDrawer
  },
  data() {
    return {
      swiperOption: {
        speed: 600,
        parallax: true,
        keyboard: {
          enabled: true
        },
        pagination: {
          el: '.swiper-pagination',
          // type: 'progressbar',
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom">${index + 1}</span>`
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      items: [
        {
          color: 'primary',
          icon: 'mdi-flag-outline',
          days: this.$t('howtorent.rent.items.day') + '1',
          text: this.$t('howtorent.rent.items.text1')
        },
        {
          color: 'secondary',
          icon: 'mdi-eye',
          days: this.$t('howtorent.rent.items.day') + '2-5',
          text: this.$t('howtorent.rent.items.text2')
        },
        {
          color: 'primary',
          icon: 'mdi-fountain-pen',
          days: this.$t('howtorent.rent.items.day') + '6',
          text: this.$t('howtorent.rent.items.text3')
        },
        {
          color: 'secondary',
          icon: 'mdi-key-variant',
          days: this.$t('howtorent.rent.items.day') + '7',
          text: this.$t('howtorent.rent.items.text4')
        }
      ]
    }
  },
  computed: {
    textSizeCarrousel() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return ['50px', '15px']
        case 'sm':
          return ['50px', '15px']
        case 'md':
          return ['70px', '20px']
        case 'lg':
          return ['90px', '20px']
        case 'xl':
          return ['120px', '40px']
        default:
          return ['90px']
      }
    },
    widthInner() {
      return window.innerWidth
    }
  }
}
</script>

<style>
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;

  background-size: cover;
  background-position: left;
  /* background-image: url('../assets/home/UBIDOMO_slide-1-1500x630.jpg'); */
  background-image: url('../assets/howtorent/parallax3.jpg');
}

.swiper {
  width: 100%;
}

@media only screen and (max-width: 2066px) and (min-width: 1904px) {
  .lgSizeTitle {
    font-size: 90px !important;
  }
  .lgSizeText {
    font-size: 25px !important;
  }
}

.swiper .swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  box-sizing: border-box;
  padding: 20px 80px;
  background-color: transparent;
}

.swiper .swiper-slide .text {
  max-width: 430px;
  line-height: 1.32;
}

.swiper-pagination-bullet {
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important;
  text-align: center;
  margin: 0 6px !important;
  margin-bottom: 2px !important;

  opacity: 0.5;
  background: white;
}
.swiper-pagination-bullet-active {
  opacity: 1 !important;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 26px;
  left: 0;
  width: 100%;
}
</style>
