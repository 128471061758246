import axios from 'axios'

// export const BASE_URL = 'http://localhost:8888/mvgm/api'
// export const BASE_URL = 'https://mvgm.overtek.es/api'
export const BASE_URL = window.apiPath ? window.apiPath : process.env.API_PATH
export const DOCUMENTOS_URL = BASE_URL + '/documentos/get'

if (process.env.NODE_ENV === 'development') {
  window.codSubdominioAgrupacion = window.codSubdominioAgrupacion != null ? window.codSubdominioAgrupacion : 'des'
  window.urlComercializacionDigital =
    window.urlComercializacionDigital != null
      ? window.urlComercializacionDigital
      : 'https://apps.prinex.com/comercializacion_digital/public'
}

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

export default api
