<template>
  <div>
    <v-col cols="12">
      <v-row justify="center">
        <v-col
          cols="10"
          :align="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'center' : '90%'"
          ><!-- v-for="(item, i) in items" :key="i" -->
          <h3 data-aos="fade-right" data-aos-duration="1000" class="mt-8">
            <span
              class="text-sm-h3 text-h4 text-md-right text-lg-right text-sm-right text-center"
              style="font-weight:500;"
              :class="formType != 'newsletter' ? (colorBg != null ? 'black--text' : 'primary--text') : 'white--text'"
              >{{ $t(titulos.titulo) }}</span
            >
          </h3>
          <v-btn
            v-if="mostrarBotonReservaInmueble"
            text
            :to="{
              name: 'comercializacion-digital',
              query: { codigoActivo: inmueble.codigoActivo, codigoPromocion: inmueble.promocion.codigo }
            }"
            class="mt-2 py-2 pl-0"
            style="text-transform: unset !important"
          >
            <v-row no-gutters align="center" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">
              <span
                class="text-sm-h4 text-h5 text-md-right text-center"
                :class="bgColor != null ? 'black--text' : 'secondary--text'"
                style="font-weight: 500"
              >
                {{ $t(titulos.subtituloAlquiler) }}
                <v-icon>
                  mdi-arrow-right-drop-circle
                </v-icon>
              </span>
            </v-row>
          </v-btn>
          <h3 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200" class="mt-1">
            <span
              :class="bgColor != null ? 'black--text' : 'secondary--text'"
              class=" text-sm-h4 text-h5 text-md-right text-center"
              style="font-weight: 500"
            >
              {{ $t(titulos.subtitulo) }}
            </span>
          </h3>
        </v-col>
      </v-row>
      <v-stepper
        v-model="e1"
        class="elevation-0 mx-md-10 mx-lg-16 px-md-5 px-lg-10 mb-10"
        :style="
          formType != 'newsletter'
            ? colorBg != null
              ? 'background-color:' + colorBg
              : ''
            : 'background-color:#25becb;'
        "
        v-if="enviado != true"
      >
        <v-col cols="12" class="pb-0"
          ><v-row justify="space-between">
            <v-stepper-step
              :color="formType != 'newsletter' ? (colorBg != null ? 'black' : 'primary') : 'secondary'"
              v-for="(item, i) in items"
              :key="i"
              :complete="objectStep[item.type] != '' && item.step < e1"
              :step="item.step"
            >
              <span :class="formType != 'newsletter' ? '' : 'white--text font-weight-medium'">{{
                $t(item.literalStep)
              }}</span>
            </v-stepper-step>
            <v-progress-linear
              width="100%"
              height="10px"
              :background-color="formType != 'newsletter' ? 'secondary' : 'white'"
              :color="formType != 'newsletter' ? 'primary' : 'secondary'"
              :value="stepperProgres"
            ></v-progress-linear></v-row
        ></v-col>
        <v-stepper-items>
          <v-stepper-content v-for="(item, i) in items" :key="i" :step="item.step" class="mx-0 px-0 py-0 py-md-8">
            <v-col cols="12" class="mx-0 px-0" :key="i">
              <!-- <h2 class="primary--text mb-3 mb-md-8" style="font-weight: 300">{{ item.titulo }}</h2> -->
              <p
                class="pb-2"
                :class="formType != 'newsletter' ? '' : 'white--text'"
                :style="
                  $vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'xs'
                    ? 'font-size:16px'
                    : 'font-size:20px'
                "
              >
                {{ $t(item.subtitulo) }}
              </p>
              <v-form
                ref="form"
                @submit.prevent="onSubmit"
                v-model="nextStep"
                style="position: relative; width:100%; z-index:2"
              >
                <v-text-field
                  v-if="formType != 'newsletter'"
                  style=""
                  filled
                  :rules="
                    item.type === 'email'
                      ? [rules.email]
                      : item.type === 'telefono'
                      ? [rules.phone]
                      : item.type != 'comentarios'
                      ? [rules.required, rules.counter]
                      : []
                  "
                  :label="$t(item.label)"
                  v-model="objectStep[item.type]"
                ></v-text-field>
                <v-col v-else cols="12" class="mx-0 px-0 mt-n16 mb-n6">
                  <div
                    class="elevation-4"
                    style="height:50px; background-color: white; z-index: 0; position:relative;top:52px; border-radius: 6px 6px 0px 0px;"
                  ></div>
                  <v-text-field
                    class="px-1"
                    :rules="
                      item.type === 'email'
                        ? [rules.email]
                        : item.type != 'comentarios'
                        ? [rules.required, rules.counter]
                        : []
                    "
                    :label="$t(item.label)"
                    style=""
                    v-model="objectStep[item.type]"
                  ></v-text-field>
                </v-col>
              </v-form>

              <v-col cols="12" class="pb-0">
                <v-row class="mx-0 mt-0 " v-if="!soyFooter && item.maxStep" align="center">
                  <v-checkbox
                    :color="formType != 'newsletter' ? (colorBg == null ? '' : 'black') : 'white'"
                    class="ml-2 pt-1 mt-0"
                    v-model="privacyAcepted"
                  ></v-checkbox>
                  <pop-up-politica
                    v-model="privacyDialog"
                    @aceptado="privacyAcepted = true"
                    :formType="formType"
                    :mostrarAceptar="true"
                  />
                  <v-spacer></v-spacer>
                  <v-checkbox
                    color="white"
                    v-if="formType === 'newsletter'"
                    class="ml-2 mt-n3"
                    v-model="newsletterAcepted"
                    :label="$t('formulario.newsletterCheck')"
                    ><template v-slot:label
                      ><span class="white--text">{{ $t('formulario.newsletterCheck') }}</span></template
                    ></v-checkbox
                  >
                </v-row></v-col
              ><v-row>
                <v-col cols="6" align="start">
                  <v-btn
                    v-if="e1 != 1"
                    :color="formType != 'newsletter' && colorBg == null ? 'primary' : 'white'"
                    :class="formType != 'newsletter' ? 'white' : 'primary--text'"
                    @click="retroceder(item.step, objectStep, item.type)"
                  >
                    {{ $t('formulario.volver') }}
                  </v-btn></v-col
                >
                <v-col cols="6" align="end">
                  <v-btn
                    :color="formType != 'newsletter' && colorBg == null ? 'primary' : 'white'"
                    :class="formType != 'newsletter' ? 'white' : 'primary--text'"
                    v-if="!item.maxStep && item.type != 'telefono'"
                    :disabled="
                      (item.type === 'email' && rules.email(objectStep[item.type]) !== true) ||
                      (item.type !== 'email' &&
                        item.type !== 'telefono' &&
                        rules.counter(objectStep[item.type]) !== true)
                        ? true
                        : false
                    "
                    @click="avanzar(item.step)"
                  >
                    {{ $t(item.boton) }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="white"
                    v-else-if="item.type === 'telefono'"
                    :disabled="item.type === 'telefono' && rules.phone(objectStep[item.type]) !== true"
                    @click="avanzar(item.step)"
                  >
                    {{ $t(item.boton) }}
                  </v-btn>
                  <v-btn
                    v-else
                    :color="formType != 'newsletter' && colorBg == null ? 'primary' : 'white'"
                    :class="formType != 'newsletter' ? 'white' : 'primary--text'"
                    :disabled="!nextStep || privacyAcepted === false"
                    @click="enviar()"
                  >
                    {{ $t(item.boton) }}
                  </v-btn>
                </v-col></v-row
              >
            </v-col>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-col cols="12" class="mx-0 px-0" align="center" v-else>
        <h2 class="mb-2" :class="formType != 'newsletter' ? 'primary--text' : 'white--text'" style="font-weight: 400">
          {{ $t('formulario.formSendTitle') }}
        </h2>
        <p class="secondary--text" style="font-weight: 500">
          {{ $t('formulario.formSendSubtitle') }}
        </p>
        <v-btn
          class="mt-4"
          :color="formType != 'newsletter' ? 'primary' : 'white'"
          :class="formType != 'newsletter' ? 'white' : 'primary--text'"
          @click="reset()"
          >{{ $t('formulario.resetButton') }}</v-btn
        >
      </v-col></v-col
    >
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import PopUpPolitica from './PopUpPolitica.vue'
export default {
  props: {
    mostrarBotonReservaInmueble: {
      type: Boolean,
      default: false
    },
    datosPromocion: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      required: true
    },
    formType: {
      type: String,
      required: true
    },
    titulos: {
      type: Object,
      required: true
    },
    soyFooter: {
      type: Boolean,
      default: false
    },
    inmuebleId: {
      type: String,
      default: null
    },
    inmueble: {
      type: Object,
      default: () => {}
    },
    colorBg: {
      type: String,
      default: null
    }
  },
  name: 'FormStep',
  components: {
    PopUpPolitica
  },
  data() {
    return {
      objectStep: {
        nombre: '',
        email: '',
        texto: '',
        telefono: '',
        tipoEmail: '',
        newsletterSubscripcion: false,
        emailPromocion: null,
        nombrePromocion: null
      },
      nextStep: false,
      newsletterAcepted: false,
      privacyAcepted: false,
      propertyAcepted: false,
      privacyDialog: false,
      pasoAnterior: false,
      e1: null,
      lengthReset: null,
      enviado: false,
      rules: {
        required: value => !!value || 'Este campo es requerido',
        counter: value => (value && value.length >= 3) || this.$t('rules.min3'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('rules.email')
        },
        phone: value => {
          const pattern = /[+]*[0-9]{9,15}/
          return (pattern.test(value) && value.length < 16) || this.$t('rules.telefono')
        }
      }
    }
  },
  created() {
    this.e1 = this.items[0].step
    this.lengthReset = this.items.length - 1
    this.enviado = false
  },
  computed: {
    stepperProgres() {
      if (this.e1 === this.items.length) {
        return 100
      } else if (this.e1 === 1) {
        return 0
      } else {
        return ((this.e1 - 0.5) * 100) / this.items.length
      }
    }
  },
  methods: {
    ...mapActions({ almacenarDatos: 'email/almacenarDatos' }),
    reset() {
      this.e1 = this.items[0].step
      this.lengthReset = this.items.length - 1
      this.objectStep = {
        nombre: '',
        email: '',
        texto: '',
        telefono: '',
        tipoEmail: '',
        newsletterSubscripcion: false
      }
      this.newsletterAcepted = false
      this.privacyAcepted = false
      this.enviado = false
    },
    avanzar(step) {
      this.e1 = step + 1
    },
    retroceder(step) {
      this.e1 = step - 1
    },
    async enviar() {
      this.objectStep.tipoEmail = this.formType
      this.objectStep.newsletterSubscripcion = this.newsletterAcepted
      let obj = JSON.parse(JSON.stringify(this.objectStep))
      await this.almacenarDatos({
        nombre: obj.nombre,
        email: obj.email,
        texto: obj.comentarios,
        telefono: obj.telefono,
        tipoEmail: obj.tipoEmail,
        newsletterSubscripcion: obj.newsletterSubscripcion,
        inmuebleId: this.inmuebleId,
        emailPromocion: this.datosPromocion.hasOwnProperty('email') ? this.datosPromocion.email : null,
        nombrePromocion: this.datosPromocion.hasOwnProperty('nombre') ? this.datosPromocion.nombre : null
      })
      if (
        this.datosPromocion.hasOwnProperty('email') &&
        this.datosPromocion.email == 'alquiler.residencial.sevilla@mvgm.com'
      ) {
        this.$gtagReportConversion('', 'AW-11199284535/A32ECN28uagYELeKntwp')
        this.$gtagReportConversion('', 'AW-11199284535/gDP2CLi8t6gYELeKntwp')
      }
      this.nextStep = false
      this.enviado = true
      this.$emit('stepReset', this.enviado)
      //e1 = item.step - lengthReset    esto estaba antes en el @click
    }
  }
}
</script>
