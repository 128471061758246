import api from '@/api/api'

export default {
  namespaced: true,
  getters: {},
  actions: {
    almacenarDatos({ dispatch }, obj) {
      return new Promise((resolve, reject) => {
        api
          .post('/contacto/enviar_email', {
            jsondata: JSON.stringify({
              parametros: obj
            })
          })
          .then(response => {
            //console.log(response)
            if (response.statusText === 'OK') {
              resolve()
            } else {
              dispatch()
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  }
}
