<template>
  <v-dialog
    v-model="privacyDialog"
    :width="
      $vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl'
        ? '60%'
        : '90%'
    "
  >
    <template v-if="mostrarAceptar" v-slot:activator="{ on, attrs }" class="">
      <v-col v-bind="attrs" v-on="on" cols="10" class="pt-0 mt-0">
        <v-row
          ><a
            :class="formType != 'newsletter' ? '' : 'white--text'"
            :style="formType != 'newsletter' ? '' : 'text-decoration: underline'"
            >{{ conversacional ? $t('formulario.privacyDialogTitle') : $t('formulario.privacyReadDialog') }}</a
          ></v-row
        >
      </v-col></template
    ><template v-slot:default="dialog">
      <v-card>
        <v-toolbar
          color="primary"
          class="privacyTitle text-h6 text-sm-h5 pl-15"
          style="font-size:25px;font-weight: bold;height:100px;padding:20px 0px"
          dark
          >{{ $t('formulario.privacyDialogTitle') }}</v-toolbar
        >
        <v-card-text>
          <p class="mt-12 px-12" style="text-align:justify;">
            <TextoFormateado class="" :texto="$t('formulario.privacyDialogText')" />
          </p>
        </v-card-text>
        <!-- <v-card-text>
          <p class="mt-12 px-20" style="text-align:justify;margin-left: 100px;">
            Click <a href="#" @click.prevent="disableTracking">{{ $t('formulario.privacyHere') }}</a
            >,
            {{ $t('formulario.privacyDisable') }}
          </p>
        </v-card-text> -->
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">{{ $t('formulario.closeDialog') }}</v-btn>
          <v-btn v-if="mostrarAceptar" text @click="aceptar()">{{ $t('formulario.acceptDialog') }}</v-btn>
        </v-card-actions>
      </v-card>
    </template></v-dialog
  >
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TextoFormateado from '@/components/Textos/TextoFormateado.vue'
export default {
  props: ['value', 'formType', 'mostrarAceptar', 'conversacional'],
  name: 'PopUpPolitica',
  components: {
    TextoFormateado
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({}),
    privacyDialog: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    ...mapActions({}),
    aceptar() {
      this.privacyDialog = false
      this.$emit('aceptado')
    },
    disableTracking: function() {
      this.$ga.disable()
      // alert('Tracking disabled')
    }
  }
}
</script>
