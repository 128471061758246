<template>
  <div>
    <v-app-bar app>
      <router-link
        style="text-decoration: none"
        :to="{
          name: 'home'
        }"
      >
        <v-img max-width="150" class="ml-10" src="@/assets/Eniksen_Logo_def.png" alt=""></v-img>
      </router-link>
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-menu
          open-on-hover
          transition="slide-y-transition"
          bottom
          offset-y
          :rounded="'0'"
          style="border-style: none !important; box-shadow: none !important;text-decoration: none"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="pl-8 pr-8"
              style="border-style: none !important; box-shadow: none !important;text-decoration: none"
            >
              {{ $t(navPromo.title) }}
            </v-btn>
          </template>
          <v-list class="pb-0 pt-0 mt-0 mb-0">
            <v-list-item
              class="pb-2 pt-2 mt-0 mb-0"
              link
              v-for="(promo, index) in navPromo.list"
              :to="promo.to"
              :key="index"
            >
              <v-list-item-title>{{ $t(promo.title) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-for="(item, i) in navItems" :key="i" :to="item.to" text>
          {{ $t(item.title) }}
        </v-btn>

        <access class="ml-16" />
      </v-toolbar-items>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        absolute
        right
        color="primary"
        @click="dialog = true"
        v-if="dialog === false"
      ></v-app-bar-nav-icon>

      <v-dialog class="hidden-md-and-up" style="" v-model="dialog" fullscreen transition="dialog-bottom-transition">
        <v-card style="position:fixed;top:55px;">
          <v-btn absolute right icon style="z-index: 9;" class="pr-8 pt-12 nohover" @click="dialog = false">
            <v-icon size="70" color="primary">mdi-close</v-icon>
          </v-btn>
          <v-list nav dense>
            <v-list-item-group active-class="primary--text text--accent-4">
              <v-row
                v-for="(item, i) in navMobile"
                :key="i"
                @click.native="dialog = false"
                style="text-decoration: none;"
              >
                <v-list-item :disabled="item.title === 'titulosSecciones.nombrepromocion'" color="black">
                  <!-- :disabled="item.title === 'titulosSecciones.nombrepromocion'" -->
                  <v-list-item-title class="pt-8 despliegue" @click="enviarRutaResponsive(item)">{{
                    $t(item.title)
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.title === 'titulosSecciones.nombrepromocion'">
                  <v-list-item-title
                    v-for="(promo, index) in item.list"
                    :key="index"
                    class="pl-8 text-h6 "
                    @click="enviarRutaResponsive(promo)"
                    >{{ $t(promo.title) }}</v-list-item-title
                  >
                </v-list-item>
              </v-row>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-dialog>
    </v-app-bar>
  </div>
</template>

<script>
import access from '@/pages/access.vue'
export default {
  components: {
    access
  },
  data() {
    return {
      prueba: false,
      drawer: false,
      dialog: false,
      group: null,
      navItems: [
        { title: 'titulosSecciones.properties', to: { name: 'properties' }, exact: true },
        { title: 'titulosSecciones.about', to: { name: 'about' } },
        { title: 'titulosSecciones.howtorent', to: { name: 'howtorent' } },
        { title: 'titulosSecciones.faqs', to: { name: 'faqs' } },
        { title: 'titulosSecciones.news', to: { name: 'news' } },
        { title: 'titulosSecciones.contact', to: { name: 'contact' } }
      ],
      navPromo: {
        title: 'titulosSecciones.nombrepromocion',
        list: [{ title: 'titulosPromociones.sevillaLasDelicias', to: { name: 'sevillalasdelicias' } }]
      },
      navMobile: [
        { title: 'titulosSecciones.home', to: { name: 'home' }, exact: true },
        {
          title: 'titulosSecciones.nombrepromocion',
          list: [{ title: 'titulosPromociones.sevillaLasDelicias', to: { name: 'sevillalasdelicias' } }]
        },
        { title: 'titulosSecciones.properties', to: { name: 'properties' } },
        { title: 'titulosSecciones.about', to: { name: 'about' } },
        { title: 'titulosSecciones.howtorent', to: { name: 'howtorent' } },
        { title: 'titulosSecciones.faqs', to: { name: 'faqs' } },
        { title: 'titulosSecciones.news', to: { name: 'news' } },
        { title: 'titulosSecciones.contact', to: { name: 'contact' } }
      ]
    }
  },
  methods: {
    enviarRutaResponsive(item) {
      if (item.title === 'titulosSecciones.nombrepromocion') {
        return
      } else {
        this.dialog = false
        this.$router.push({ name: item.to.name })
      }
    }
  },
  watch: {
    group() {
      this.drawer = false
    }
  }
}
</script>
<style>
.eo__dropdown__menu {
  left: -20px !important;
}
</style>
<style scoped>
.despliegue {
  height: 70px !important;
  font-size: 35px !important;
}
.nohover::before {
  background-color: transparent !important;
}

@media only screen and (max-height: 700px) and (min-height: 470px) {
  .despliegue {
    padding-top: 23px !important;
    height: 60px !important;
    font-size: 30px !important;
  }
}
@media only screen and (max-height: 470px) and (min-height: 320px) {
  .despliegue {
    padding-top: 10px !important;
    height: 30px !important;
    font-size: 15px !important;
  }
}
</style>
