<template>
  <!-- eslint-disable -->
  <v-container :fill-height="fillHeight" class="ma-0 pa-0" style="max-width: 100%">
    <v-row no-gutters :style="fillHeight ? 'height: 100%' : ''">
      <v-col cols="12" class="mx-0 my-0 px-0 py-0" :style="fillHeight ? 'height: 100%' : ''">
        <slot v-if="!loading" name="content"/>
        <v-row v-else justify="center">
          <div style="margin-top:100px">
            <breeding-rhombus-spinner :animation-duration="2000" :size="200" :color="'#25BECB'" />
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" class="mx-0 px-0 mb-0 pb-0">
        <v-footer
          absolute inset padless
          style="background-color: #363636;" ref="footer" :style="'bottom:' + footerBottom + '; height:' + footerHeight"
        >
          <v-col cols="11" class="pb-0">
            <v-row justify="center">
              <v-col cols="10" sm="8" md="10" lg="5" xl="4">
                <v-row align="center" class="pb-6">
                  <v-col cols="8" md="4" lg="5" xl="4">
                    <router-link
                      style="text-decoration: none"
                      :to="{name: 'home'}"
                    >
                      <v-img src="@/assets/Eniksen_Logo_def.png" alt=""/>
                    </router-link>
                  </v-col>
                  <v-col class="ml-sm-2 mt-lg-3" cols="12" md="6" lg="6" xl="6">
                    <span  style="font-size: 14px; color: white;" class="">
                      {{$t('footer.text')}}
                    </span> 
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer/>
              <v-col cols="1" >
                <v-row class="pt-2">
                  <v-btn icon  class="pt-4 pr-md-4" style="text-decoration:none;">
                  <a style="text-decoration: none" target="_blank" href="https://www.linkedin.com/company/mvgm-international/">
                    <v-icon color="white" class="mr-4" style=" border-radius: 2px; font-size:40px">
                      mdi-linkedin
                    </v-icon></a>
                  </v-btn>
                
                  <!-- <v-btn icon class="pt-4 pt-md-0" style="text-decoration:none;" to=''>
                    <a target="_blank" style="text-decoration: none" href="https://www.instagram.com/">
                    <v-icon color="" class="mr-4 mt-4" color="white" style="border-radius: 2px; font-size:40px">
                      mdi-instagram
                    </v-icon></a>
                  </v-btn> -->
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" class="" align="center">
              <span style="color:white;padding-right:5px" class="text-center">
                © 2022 –</span>
                <v-btn text class="px-0" @click.stop="politica()" style="text-decoration:none; color:white;text-transform:none;height:auto">{{$t('footer.privacy')}}</v-btn>
                <span style="color:white;padding-left:5px;padding-right:5px" class="text-center"> – </span>
                <v-btn text class="px-0" @click.stop="terminos()" style="height:auto;text-decoration:none; color:white;text-transform:none">{{$t('footer.terms')}} </v-btn>
                <span style="color:white;padding-left:5px;padding-right:5px" class="text-center"> – </span>
                <v-btn text class="px-0" @click.stop="avisoLegal()" style="height:auto;text-decoration:none; color:white;text-transform:none">{{$t('footer.avisoLegal')}} </v-btn>
              <span  style="color:white;position: absolute;right: 20px;" class="text-center notranslate">
                Powered by <a href="https://www.mvgm.es" >MVGM</a>
              </span>
            </v-row>
          </v-col>
        </v-footer>
        <!-- <p>{{footerBottom}}</p> -->
      </v-col>
    </v-row>
    <pop-up-politica v-if="privacyDialog" v-model="privacyDialog" @aceptado="privacyAcepted = true" :formType="formType" :soyFooter="true" />
    <pop-up-terminos v-if="terminosDialog" v-model="terminosDialog" />
    <pop-up-aviso-legal v-if="avisoLegalDialog" v-model="avisoLegalDialog" />
  </v-container>
</template>

<script>
import { BreedingRhombusSpinner } from 'epic-spinners'
import PopUpPolitica from '@/components/PopUpPolitica.vue'
import PopUpAvisoLegal from '@/components/PopUpAvisoLegal.vue'
import PopUpTerminos from '@/components/PopUpTerminos.vue'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    fillHeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      privacyDialog: false,
      terminosDialog: false,
      avisoLegalDialog: false
    }
  },
  components: {
    BreedingRhombusSpinner,
    PopUpTerminos,
    PopUpAvisoLegal,
    PopUpPolitica
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  computed: {
    footerBottom() {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
          return '-260px'
        case 'md':
          return '-230px'
        case 'lg':
          return '-200px'
        case 'xl':
          return '-190px'
        default:
          return '-280px'
      }
    },
    footerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
          return '260px'
        case 'md':
          return '230px'
        case 'lg':
          return '200px'
        case 'xl':
          return '190px'
        default:
          return '280px'
      }
    }
  },
  methods: {
    politica() {
      this.privacyDialog = true
    },
    terminos() {
      this.terminosDialog = true
    },
    avisoLegal() {
      this.avisoLegalDialog = true
    }
  }
}
</script>
