<template>
  <pages-layout
    ><template v-slot:content>
      <div v-if="!enConstruccion">
        <v-col cols="12" class="my-0 py-0">
          <v-row justify="center">
            <swiper
              class="swiper"
              :options="swiperOption"
              :style="
                $vuetify.breakpoint.name === 'md' ||
                $vuetify.breakpoint.name === 'lg' ||
                $vuetify.breakpoint.name === 'xl'
                  ? 'height: 550px'
                  : 'height: 550px'
              "
            >
              <swiper-slide v-for="(item, i) in cabecera" :key="i" style="padding: 0">
                <v-img
                  :lazy-src="require(`@/assets${item.src}`)"
                  :src="require(`@/assets${item.src}`)"
                  style="height: 100%"
                ></v-img>
              </swiper-slide>
              <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
              <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
              <div class="swiper-pagination bulletSmall" slot="pagination" style="padding-top:12px !important"></div>
              <div class="swiper-pagination bulletSmall" slot="pagination" style="padding-top:12px !important"></div>
            </swiper>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-img :src="require('@/assets/promociones/Logotipo/logotipoNegro.png')"></v-img>
            </v-col>
          </v-row>
          <!-- <v-row
            justify="center"
            align="center"
            :style="
              $vuetify.breakpoint.name === 'md' ||
              $vuetify.breakpoint.name === 'lg' ||
              $vuetify.breakpoint.name === 'xl'
                ? ''
                : 'height: 130px;'
            "
            class="mt-0 pt-0"
          >
            <v-col class="text-center mt-2 mx-0 px-0" cols="12" v-if="$vuetify.breakpoint.name != 'xs'">
              <div>
                <vue-typer
                  class="typer-promocion  vuetyper colorNegro"
                  :repeat="0"
                  :style="
                    $vuetify.breakpoint.name === 'md' ||
                    $vuetify.breakpoint.name === 'lg' ||
                    $vuetify.breakpoint.name === 'xl'
                      ? 'font-size: 120px;'
                      : 'font-size: 30px; '
                  "
                  style="font-family: 'Modern Deco' !important; text-align: center; opacity: 1; "
                  :text="$t('sevillaLasDelicias')"
                  :pre-type-delay="0"
                  :type-delay="40"
                  caret-animation="solid"
                ></vue-typer>
              </div>
            </v-col>
            <v-col class="text-center py-12 mb-12 mx-0 px-0" cols="12" v-else>
              <div>
                <vue-typer
                  class="typer-promocion  vuetyper"
                  :repeat="0"
                  style="font-family: 'Modern Deco' !important; text-align: center; opacity: 1; letter-spacing: -1px; font-size: 40px;"
                  :text="$t('sevillaLasDeliciasResponsive1')"
                  :pre-type-delay="0"
                  :type-delay="40"
                  caret-animation="solid"
                ></vue-typer>
              </div>
              <div>
                <vue-typer
                  class="typer-promocion  vuetyper"
                  :repeat="0"
                  style="font-family: 'Modern Deco' !important; color:white;text-align: center; opacity: 1; letter-spacing: -1px;font-size: 40px;"
                  :text="$t('sevillaLasDeliciasResponsive2')"
                  :pre-type-delay="40"
                  :type-delay="80"
                  caret-animation="solid"
                ></vue-typer>
              </div>
            </v-col>
          </v-row> -->
          <v-row justify="center" :class="$vuetify.breakpoint.name == 'xs' ? 'mt-12' : ''" style="">
            <v-col cols="12" md="10" lg="10" xl="10" justify="center">
              <p
                class="mx-8 mt-2 colorNegro"
                :style="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 'font-size:50px'
                    : 'font-size:25px; font-weight:600;'
                "
                style="font-weight:500; text-align: center;"
                v-html="$t(edificioTitulo.titulo)"
              ></p>
              <p
                v-html="$t(edificioTitulo.texto)"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="300"
                class="mx-md-8 mx-0 mt-10 pb-2 colorNegro"
                style="font-size:30px; font-weight-light; text-align: center;"
                :style="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 'font-size:30px'
                    : 'font-size:18px'
                "
              ></p>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-6 mb-16">
            <v-col cols="12" align="center">
              <button
                :class="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 'buttonView'
                    : 'buttonSmall bgNegro'
                "
                @click="viviendasPromocion()"
              >
                <span>{{ $t(edificioTitulo.boton) }}</span>
              </button>
              <!-- height="100px"
              width="400px" -->
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" align-self="center" class="bgMarronClaro pb-0 mb-0">
          <v-row align="center" justify="center" class="pb-0 mb-0">
            <v-col cols="12" md="10">
              <p
                class="mx-8 mt-8 colorNegro"
                :style="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 'font-size:50px'
                    : 'font-size:25px; font-weight:600;'
                "
                style="font-weight:500; text-align: center;"
                v-html="$t('promocionTour.titulo')"
              ></p>
              <p
                v-html="$t('promocionTour.texto')"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="300"
                class="mx-md-8 mx-0 mt-10 pb-2 colorNegro"
                style="font-size:30px; font-weight-light;  text-align: justify;
                  text-align-last: center;"
                :style="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 'font-size:30px'
                    : 'font-size:18px'
                "
              ></p>
              <a href="https://www.omniqolab.com/tours/lasdelicias/cbx/" target="_blank">
                <v-img class="mb-10 mt-10" :src="require('@/assets/promociones/tourvirtual.jpg')"></v-img>
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="my-0 py-0 bgNegro">
          <v-row class="my-0 py-8">
            <v-col cols="12" style="overflow-x: hidden">
              <v-row justify="center">
                <v-col sm="11" cols="12">
                  <v-row justify="space-between">
                    <v-col cols="12" sm="9" md="9" lg="9" class="text-md-left text-lg-left text-sm-left text-center">
                      <h3 data-aos="fade-right" data-aos-duration="1000" class="mt-2">
                        <span
                          class="text-md-h3 text-xs-body-2 text-md-right text-lg-right text-sm-right text-center colorMarronClaro"
                          style="font-weight:500;"
                        >
                          {{ $t('sevillaDeliciasMasInfo.titulo') }}</span
                        >
                      </h3>
                      <h3 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="400" class="mt-2">
                        <a
                          class="text-md-h4 text-xs-body-2 text-md-right text-lg-right text-sm-right text-center colorMarronClaro"
                          @click="$gtagReportConversion('tel:677602618', 'AW-11199284535/vPZcCNmsuagYELeKntwp')"
                          href="tel:677602618"
                          >{{ $t('sevillaDeliciasMasInfo.telefono') }}</a
                        >
                      </h3>
                      <h3 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="400" class="mt-2">
                        <span
                          v-if="
                            $vuetify.breakpoint.name === 'sm' ||
                              $vuetify.breakpoint.name === 'lg' ||
                              $vuetify.breakpoint.name === 'xl' ||
                              $vuetify.breakpoint.name === 'md'
                          "
                          class="text-md-h4 text-xs-body-2 text-md-right text-lg-right text-sm-right text-center  colorMarronClaro"
                          style="font-weight: 500;"
                          :style="
                            $vuetify.breakpoint.name === 'sm' ||
                            $vuetify.breakpoint.name === 'lg' ||
                            $vuetify.breakpoint.name === 'xl'
                              ? ''
                              : 'font-size: 16px'
                          "
                          >{{ $t('sevillaDeliciasMasInfo.email') }}</span
                        >
                        <span
                          v-else
                          class="text-md-h4 text-xs-body-2 text-md-right text-lg-right text-sm-right text-center colorMarronClaro"
                          style="font-weight: 500; font-size: 13px"
                          >{{ $t('sevillaDeliciasMasInfo.email') }}</span
                        >
                      </h3>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" align="center">
                      <v-row justify="center">
                        <v-col
                          cols="12"
                          justify="center"
                          align="center"
                          class="text-md-right text-lg-right text-sm-right text-center"
                        >
                          <button
                            :class="
                              $vuetify.breakpoint.name === 'md' ||
                              $vuetify.breakpoint.name === 'lg' ||
                              $vuetify.breakpoint.name === 'xl'
                                ? 'button'
                                : 'buttonSmall'
                            "
                            data-aos="fade-left"
                            data-aos-delay="500"
                            data-aos-duration="1000"
                            @click="aContacto()"
                          >
                            <span>{{ $t('sevillaDeliciasMasInfo.boton') }}</span>
                          </button>
                        </v-col></v-row
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row></v-col
            ></v-row
          ></v-col
        >
        <v-col cols="12" class="my-0 py-0">
          <v-row style="">
            <v-col cols="12" style="" class="my-0 py-0 mx-0 px-0 bgMarronClaro"
              ><v-row class="px-0 mx-0 my-0 py-0">
                <v-col class="mb-0 pb-0 pt-0 mt-0 pr-8 pl-8 " cols="12" lg="6" xl="6">
                  <v-row justify="center" class="mt-md-4 pt-md-4"
                    ><h3 data-aos="fade-right" data-aos-duration="1000" class="mt-8 mb-8 ">
                      <span
                        class="text-md-h4 text-xs-body2 text-md-right text-lg-right text-sm-right text-center colorNegro"
                        :style="
                          $vuetify.breakpoint.name === 'md' ||
                          $vuetify.breakpoint.name === 'lg' ||
                          $vuetify.breakpoint.name === 'xl'
                            ? ''
                            : 'font-size:30px'
                        "
                        style="font-weight:600;"
                        >{{ $t(caracteristicasEdificio.titulo) }}
                      </span>
                    </h3>
                  </v-row>
                  <v-row
                    align="center"
                    justify="center"
                    class=""
                    v-for="seccion in caracteristicasEdificio.secciones"
                    :key="seccion.id"
                  >
                    <v-col cols="10" class="py-0 my-0"
                      ><v-row
                        class="py-0 my-0"
                        :align="
                          $vuetify.breakpoint.name === 'md' ||
                          $vuetify.breakpoint.name === 'lg' ||
                          $vuetify.breakpoint.name === 'xl'
                            ? 'start'
                            : 'start'
                        "
                      >
                        <v-col cols="1">
                          <v-row align="end" justify="end" class="py-0 my-0">
                            <v-icon color="#1d1d1b" class="pl-md-10 pl-2 " large dark style="font-size:30px;"
                              >mdi-arrow-right-drop-circle</v-icon
                            ></v-row
                          >
                        </v-col>
                        <v-col cols="10" md="11" align="start" justify="start" class="py-0 my-0">
                          <v-row align="start" justify="start" class="pl-4 pt-md-4"
                            ><p class="colorNegro" style="font-size:18px; text-align: left">
                              {{ $t(seccion.texto) }}
                            </p></v-row
                          ></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6" class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0"
                  ><swiper class="swiper mr-0 pr-0 " :options="swiperOption" style=" width:100%; height: 600px; ">
                    <swiper-slide v-for="(item, i) in galeriaCaracteristicas" :key="i" style="padding: 0;">
                      <v-img
                        :lazy-src="require(`@/assets${item.src}`)"
                        :src="require(`@/assets${item.src}`)"
                        style=" z-index: -1"
                      ></v-img>
                    </swiper-slide>
                    <div class="swiper-button-next swiper-button-white pt-12 pr-8" slot="button-next"></div>
                    <div class="swiper-button-prev swiper-button-white pt-12" slot="button-prev"></div>
                    <div
                      class="swiper-pagination bulletSmall pb-12 mt-0 pt-0"
                      slot="pagination"
                      style="padding-top:0px !important"
                    ></div> </swiper
                ></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          class="my-0 py-0 mx-0 px-0 bgMarronClaro"
          v-if="
            $vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl'
          "
        >
          <v-row class="my-0 py-0 mx-0 px-0">
            <v-col cols="6" class="my-0 py-0 mx-0 px-0">
              <v-parallax
                class="my-0 py-0 mx-0 px-0"
                style="width:100%; object-fit: contain;"
                height="610"
                :src="require('@/assets/promociones/CEE3.jpg')"
              >
              </v-parallax>
            </v-col>
            <v-col cols="6">
              <p
                class="mr-md-8 ml-md-8 mt-1 mt-md-12 pb-0  text-md-h5 colorNegro "
                style=" font-weight: 400; text-align: center;"
                :style="
                  $vuetify.breakpoint.name != 'xs' &&
                  $vuetify.breakpoint.name != 'sm' &&
                  $vuetify.breakpoint.name != 'md'
                    ? 'font-size:20px;'
                    : 'font-size:22px;'
                "
                v-html="$t(certificadoEnergia.texto)"
              ></p>
              <v-row
                align="center"
                justify="center"
                class="py-0 my-0"
                v-for="seccion in certificadoEnergia.secciones"
                :key="seccion.id"
              >
                <v-col cols="8" align="center" class="py-0 my-0"
                  ><v-row align="center">
                    <v-col cols="1">
                      <v-row align="end" justify="end" class="">
                        <v-icon class="pl-10" color="#1d1d1b" large dark style="font-size:30px"
                          >mdi-arrow-right-drop-circle</v-icon
                        ></v-row
                      >
                    </v-col>
                    <v-col cols="11" align="start" justify="start" class="py-0 my-0">
                      <v-row align="start" justify="start" class="pl-4 pt-4"
                        ><p class="colorNegro" style="font-size:18px; text-align: left">
                          {{ $t(seccion.texto) }}
                        </p></v-row
                      ></v-col
                    ></v-row
                  ></v-col
                >
              </v-row>
              <v-row>
                <v-col cols="12" align="center">
                  <v-row justify="center" class="mt-4">
                    <v-col cols="2" v-for="medida in certificadoEnergia.medidas" :key="medida.id">
                      <v-icon color="#1d1d1b" size="60px">{{ medida.icon }}</v-icon>
                      <p style="font-size:12px;" class="colorNegro">{{ $t(medida.texto) }}</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else>
          <v-row>
            <v-col cols="12">
              <v-img :src="require('@/assets/promociones/CEE.jpg')"></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p
                class="mr-8 ml-8 mt-md-12 pt-md-12 pb-0 colorNegro"
                :style="
                  $vuetify.breakpoint.name != 'xs' &&
                  $vuetify.breakpoint.name != 'sm' &&
                  $vuetify.breakpoint.name != 'md'
                    ? 'font-size:50px'
                    : 'font-size:30px; font-weight: bold'
                "
                style=" font-weight:500; text-align: center;"
              >
                {{ $t(certificadoEnergia.titulo) }}
              </p>
              <p
                v-html="$t(certificadoEnergia.texto)"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="300"
                class="mx-md-8 mx-2 mt-md-10 pb-2 colorNegro"
                style="font-size:30px; font-weight-light; text-align: center;"
                :style="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 'font-size:30px'
                    : 'font-size:22px'
                "
              ></p>
            </v-col>
          </v-row>
          <v-row class="pl-12" v-for="seccion in certificadoEnergia.secciones" :key="seccion.id">
            <v-col cols="1">
              <v-row align="end" justify="end" class="">
                <v-icon
                  class="pl-10 pl-2"
                  color="#1d1d1b"
                  large
                  dark
                  :style="
                    $vuetify.breakpoint.name != 'xs' &&
                    $vuetify.breakpoint.name != 'sm' &&
                    $vuetify.breakpoint.name != 'md'
                      ? 'font-size:55px'
                      : 'font-size:30px;'
                  "
                  >mdi-arrow-right-drop-circle</v-icon
                ></v-row
              >
            </v-col>
            <v-col cols="10" md="11" align="start" justify="start">
              <v-row align="start" justify="start" class="pl-4 pt-md-4"
                ><p class="colorNegro" style="font-size:18px; text-align: left">
                  {{ $t(seccion.texto) }}
                </p></v-row
              ></v-col
            ></v-row
          >
          <v-row>
            <v-col cols="12" align="center">
              <v-row justify="center">
                <v-col cols="12" v-for="medida in certificadoEnergia.medidas" :key="medida.id">
                  <v-icon color="#1d1d1b" size="50px">{{ medida.icon }}</v-icon>
                  <p style="font-size:14px; color:#5c7c6c">{{ $t(medida.texto) }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col cols="12" class="my-0 py-0">
          <v-row>
            <v-col cols="12" style="background-color:#E4EAE7;" class="mb-0 pb-0 pt-0 mt-0 mx-0 px-0">
              <v-row class="px-0 mx-0" align="center">
                <v-col
                  v-if="
                    $vuetify.breakpoint.name != 'xs' &&
                      $vuetify.breakpoint.name != 'sm' &&
                      $vuetify.breakpoint.name != 'md'
                  "
                  cols="12"
                  lg="6"
                  xl="6"
                  class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0"
                  ><v-parallax style="top:0px" :src="require('@/assets/promociones/clubSocial1.jpg')"> </v-parallax
                ></v-col>
                <v-col class="mb-0 pb-0 pt-0 mt-0 pr-8 pl-md-8 " cols="12" lg="6" xl="6" justify="center">
                  <p
                    class="mr-8 ml-8 mt-10 pb-2 text-h4 text-md-h2 "
                    :style="
                      $vuetify.breakpoint.name != 'xs' &&
                      $vuetify.breakpoint.name != 'sm' &&
                      $vuetify.breakpoint.name != 'md'
                        ? 'font-size:50px'
                        : 'font-size:30px; font-weight: bold'
                    "
                    style="font-weight:500; color:#25becb; text-align: center;"
                  >
                    {{ $t(certificadoEnergia.titulo) }}
                  </p>
                  <p
                    class="mr-md-8 ml-md-8 mt-1 pb-0  text-md-h5 "
                    style=" font-weight: 400; color:black; text-align: center;"
                    :style="
                      $vuetify.breakpoint.name != 'xs' &&
                      $vuetify.breakpoint.name != 'sm' &&
                      $vuetify.breakpoint.name != 'md'
                        ? 'font-size:20px;'
                        : 'font-size:22px;'
                    "
                    v-html="$t(certificadoEnergia.texto)"
                  ></p>
                  <v-row
                    align="center"
                    justify="center"
                    class="py-0 my-0"
                    v-for="seccion in certificadoEnergia.secciones"
                    :key="seccion.id"
                  >
                    <v-col cols="8" align="center" class="py-0 my-0"
                      ><v-row align="center">
                        <v-col cols="1">
                          <v-row align="end" justify="end" class="">
                            <v-icon class="pl-10" color="secondary" large dark style="font-size:45px"
                              >mdi-arrow-right-drop-circle</v-icon
                            ></v-row
                          >
                        </v-col>
                        <v-col cols="11" align="start" justify="start">
                          <v-row align="start" justify="start" class="pl-4 pt-4"
                            ><p class="" style="color: black; font-size:18px; text-align: left">
                              {{ $t(seccion.texto) }}
                            </p></v-row
                          ></v-col
                        ></v-row
                      ></v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col cols="12" align="center">
                      <v-row justify="center">
                        <v-col cols="2" v-for="medida in certificadoEnergia.medidas" :key="medida.id">
                          <v-icon color="secondary" size="50px">{{ medida.icon }}</v-icon>
                          <p style="font-size:14px; font-weight: bold; color:#5c7c6c">{{ $t(medida.texto) }}</p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>-->
        <!-- <v-col cols="12">
                  <v-row justify="center">
                    <v-col class="ml-12" cols="2" v-for="medida in certificadoEnergia.medidas" :key="medida.id">
                      <v-icon color="secondary" size="70px">{{ medida.icon }}</v-icon>
                      <p style="font-size:20px; font-weight: bold; color:#5c7c6c">{{ $t(medida.texto) }}</p>
                    </v-col>
                  </v-row>
                </v-col> -->
        <!--<v-col cols="12" class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0"
                  ><v-img
                    v-if="
                      $vuetify.breakpoint.name != 'md' &&
                        $vuetify.breakpoint.name != 'lg' &&
                        $vuetify.breakpoint.name != 'xl'
                    "
                    style="height: 350px;"
                    :src="require('@/assets/promociones/clubSocialResponsive.jpg')"
                  >
                  </v-img
                ></v-col>
              </v-row>
            </v-col> </v-row
        ></v-col> -->
        <v-col cols="12" class="my-0 py-0">
          <v-row
            justify="center"
            align="center"
            class="mt-md-0 pt-md-0 mt-4 pt-4
            "
          >
            <v-col justify="center" align="center" cols="12" class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0">
              <v-parallax
                id="my-lax"
                :height="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 620
                    : 950
                "
                :src="require('@/assets/promociones/posibleFondo1.jpg')"
                class="mr-0 pr-0"
                style="padding: 0px !important; margin: 0px !important"
              >
                <v-row justify="center">
                  <v-col class="mb-0 pb-0 pt-0 mt-0 pr-0 mr-0 " cols="10" md="9" lg="9" xl="9">
                    <v-row justify="center" class="mt-4">
                      <p
                        class="mr-8 ml-8 mt-md-10 pt-md-12 pt-sm-0 pt-12 mt-0 pb-2 text-md-h3 text-h4 colorNegro"
                        style="font-weight:500; text-align: center;"
                        :style="
                          $vuetify.breakpoint.name === 'md' ||
                          $vuetify.breakpoint.name === 'lg' ||
                          $vuetify.breakpoint.name === 'xl'
                            ? 'font-weight:500;'
                            : 'font-weight:600; '
                        "
                      >
                        {{ $t(complejo.titulo) }}
                      </p></v-row
                    >

                    <v-row justify="center" class="mb-12 pb-12"
                      ><p
                        v-html="$t(complejo.texto)"
                        data-aos="fade-in"
                        data-aos-duration="1000"
                        data-aos-delay="300"
                        class="mx-md-8 mx-0 mt-10 pb-0 mb-4 colorNegro"
                        style="font-size:30px; font-weight-light; text-align: center;"
                        :style="
                          $vuetify.breakpoint.name === 'md' ||
                          $vuetify.breakpoint.name === 'lg' ||
                          $vuetify.breakpoint.name === 'xl'
                            ? 'font-size:30px'
                            : $vuetify.breakpoint.name === 'sm'
                            ? 'font-size: 22px'
                            : 'font-size:18px'
                        "
                      ></p
                    ></v-row> </v-col
                ></v-row>
              </v-parallax>
            </v-col> </v-row
        ></v-col>
        <v-col cols="12" class="my-0 py-0">
          <v-row justify="center">
            <v-col cols="12" style="" class="mb-0 pb-0 pt-0 mt-0 mx-0 px-0 bgMarronClaro" align="center"
              ><v-row class="px-0 mx-0" align="center">
                <v-col class="mb-0 pb-0 pt-0 mt-0 pr-8 pl-8 " cols="12" lg="6" xl="6" justify="center">
                  <v-row justify="center"
                    ><h3 data-aos="fade-right" data-aos-duration="1000" class="mt-4 ">
                      <span
                        class="text-md-h4 text-xs-body-2 text-md-right text-lg-right text-sm-right text-center colorNegro"
                        style="font-weight:600;"
                        >{{ $t(complejo.instalaciones.tituloPiscina) }} :</span
                      >
                    </h3>

                    <p
                      v-html="$t(complejo.instalaciones.introduccion)"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      class="mt-6 ml-2 pl-2 colorNegro"
                      style="font-size:20px; font-weight-light; "
                      :style="
                        $vuetify.breakpoint.name === 'md' ||
                        $vuetify.breakpoint.name === 'lg' ||
                        $vuetify.breakpoint.name === 'xl'
                          ? 'font-size:25px'
                          : 'font-size:22px'
                      "
                    ></p
                  ></v-row>
                  <v-row justify="center" class="mt-12">
                    <v-col
                      cols="6"
                      align="center"
                      v-for="(espacio, index) in complejo.instalaciones.espacios"
                      :key="index"
                      ><v-row
                        v-if="
                          $vuetify.breakpoint.name != 'xs' &&
                            $vuetify.breakpoint.name != 'sm' &&
                            $vuetify.breakpoint.name != 'md'
                        "
                      >
                        <p class="mr-md-2 ml-md-12 colorNegro" style=" font-size:18px ">
                          <v-icon class="" color="#1d1d1b" large dark style="font-size:45px">{{ espacio.icon }}</v-icon>
                          {{ $t(espacio.texto) }}
                        </p></v-row
                      >
                      <v-row v-else class="my-0 py-0">
                        <v-col cols="12" class="my-0 py-0"
                          ><v-icon class="" color="#1d1d1b" large dark style="font-size:45px">{{
                            espacio.icon
                          }}</v-icon></v-col
                        >
                        <v-col cols="12"
                          ><p style="font-size:18px " class="colorNegro">{{ $t(espacio.texto) }}</p></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6" class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0"
                  ><swiper class="swiper mr-0 pr-0 " :options="swiperOption" style=" width:100%; height: 85vh">
                    <swiper-slide v-for="(item, i) in galeriaExterior" :key="i" style="padding: 0;">
                      <v-img
                        :lazy-src="require(`@/assets${item.src}`)"
                        :src="require(`@/assets${item.src}`)"
                        style="height: 100%; z-index: -1"
                      ></v-img>
                    </swiper-slide>
                    <div class="swiper-button-next swiper-button-white pt-12 pr-8" slot="button-next"></div>
                    <div class="swiper-button-prev swiper-button-white pt-12" slot="button-prev"></div>
                    <div
                      class="swiper-pagination bulletSmall pb-12 mt-0 pt-0"
                      slot="pagination"
                      style="padding-top:0px !important"
                    ></div> </swiper
                ></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0 pb-0">
          <v-row>
            <v-col cols="12" style="" class="mb-0 pb-0 pt-0 mt-0 mx-0 px-0 bgMarronClaro"
              ><v-row class="px-0 mx-0" align="center">
                <v-col
                  v-if="
                    $vuetify.breakpoint.name != 'xs' &&
                      $vuetify.breakpoint.name != 'sm' &&
                      $vuetify.breakpoint.name != 'md'
                  "
                  cols="12"
                  lg="6"
                  xl="6"
                  class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0"
                >
                  <swiper class="swiper mr-0 pr-0 " :options="swiperOption" style=" width:100%; height: 60vh">
                    <swiper-slide v-for="(item, i) in galeriaSocialClub" :key="i" style="padding: 0;">
                      <v-img
                        :lazy-src="require(`@/assets${item.src}`)"
                        :src="require(`@/assets${item.src}`)"
                        style="height: 100%; z-index: -1"
                      ></v-img>
                    </swiper-slide>
                    <div class="swiper-button-next swiper-button-white pt-12 pr-8" slot="button-next"></div>
                    <div class="swiper-button-prev swiper-button-white pt-12" slot="button-prev"></div>
                    <div
                      class="swiper-pagination bulletSmall pb-12 mt-0 pt-0"
                      slot="pagination"
                      style="padding-top:0px !important"
                    ></div>
                  </swiper>

                  <!-- <v-parallax style="height: 450px;" :src="require('@/assets/promociones/clubSocial1.jpg')">
                  </v-parallax
                > -->
                  <!-- <swiper class="swiper mr-0 pr-0 " :options="swiperOption" style=" width:100%; height: 85vh">
                    <swiper-slide v-for="(item, i) in galeriaSocialClub" :key="i" style="padding: 0;">
                      <v-img
                        :lazy-src="require(`@/assets${item.src}`)"
                        :src="require(`@/assets${item.src}`)"
                        style="height: 100%; z-index: -1"
                      ></v-img>
                    </swiper-slide>
                    <div class="swiper-button-next swiper-button-white pt-12 pr-8" slot="button-next"></div>
                    <div class="swiper-button-prev swiper-button-white pt-12" slot="button-prev"></div>
                    <div
                      class="swiper-pagination bulletSmall pb-12 mt-0 pt-0"
                      slot="pagination"
                      style="padding-top:0px !important"
                    ></div>
                  </swiper> -->
                </v-col>
                <v-col class="mb-0 pb-0 pt-0 mt-0 pr-8 pl-md-8 " cols="12" lg="6" xl="6" justify="center">
                  <p
                    class="mr-8 ml-8 mt-10 pb-2 text-h4 text-md-h2 colorNegro"
                    :style="
                      $vuetify.breakpoint.name != 'xs' &&
                      $vuetify.breakpoint.name != 'sm' &&
                      $vuetify.breakpoint.name != 'md'
                        ? 'font-size:50px'
                        : 'font-size:30px; font-weight: bold'
                    "
                    style="font-weight:500; text-align: center;"
                  >
                    {{ $t(clubSocial.titulo) }}
                  </p>
                  <p
                    class="mr-md-8 ml-md-8 mt-1 pb-0 pb-md-8 text-md-h5 colorNegro"
                    style=" font-weight: 400; text-align: center;"
                    :style="
                      $vuetify.breakpoint.name != 'xs' &&
                      $vuetify.breakpoint.name != 'sm' &&
                      $vuetify.breakpoint.name != 'md'
                        ? 'font-size:20px;'
                        : 'font-size:22px;'
                    "
                    v-html="$t(clubSocial.texto)"
                  ></p>
                </v-col>
                <v-col cols="12" class="mb-0 pb-0 pt-0 mt-0 ml-0 mr-0 pr-0 pl-0"
                  ><!-- <v-img
                    v-if="
                      $vuetify.breakpoint.name != 'md' &&
                        $vuetify.breakpoint.name != 'lg' &&
                        $vuetify.breakpoint.name != 'xl'
                    "
                    style="height: 350px;"
                    :src="require('@/assets/promociones/clubSocialResponsive.jpg')"
                  >
                  </v-img
                > -->
                  <swiper
                    class="swiper mr-0 pr-0 "
                    v-if="
                      $vuetify.breakpoint.name != 'md' &&
                        $vuetify.breakpoint.name != 'lg' &&
                        $vuetify.breakpoint.name != 'xl'
                    "
                    :options="swiperOption"
                    style=" width:100%; height: 85vh"
                  >
                    <swiper-slide v-for="(item, i) in galeriaSocialClub" :key="i" style="padding: 0;">
                      <v-img
                        :lazy-src="require(`@/assets${item.src}`)"
                        :src="require(`@/assets${item.src}`)"
                        style="height: 100%; z-index: -1"
                      ></v-img>
                    </swiper-slide>
                    <div class="swiper-button-next swiper-button-white pt-12 pr-8" slot="button-next"></div>
                    <div class="swiper-button-prev swiper-button-white pt-12" slot="button-prev"></div>
                    <div
                      class="swiper-pagination bulletSmall pb-12 mt-0 pt-0"
                      slot="pagination"
                      style="padding-top:0px !important"
                    ></div> </swiper
                ></v-col>
              </v-row>
            </v-col> </v-row
        ></v-col>
        <v-col cols="12" class="pt-0 mt-0">
          <v-row justify="center">
            <p
              class="mr-8 ml-8 mt-md-12 pt-md-12 pb-0 colorNegro"
              :style="
                $vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'md'
                  ? 'font-size:50px'
                  : 'font-size:30px; font-weight: bold'
              "
              style=" font-weight:500; text-align: center;"
            >
              {{ $t(viviendas.titulo) }}
            </p>
          </v-row></v-col
        >

        <v-row justify="center" no-gutters class="mt-0 ">
          <v-col cols="12" md="10" lg="10" xl="10" justify="center">
            <p
              v-html="$t(viviendas.texto)"
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="300"
              class="mx-md-8 mx-2 mt-md-10 pb-2 "
              style="font-size:30px; font-weight-light; text-align: center;"
              :style="
                $vuetify.breakpoint.name === 'md' ||
                $vuetify.breakpoint.name === 'lg' ||
                $vuetify.breakpoint.name === 'xl'
                  ? 'font-size:30px'
                  : 'font-size:22px'
              "
            ></p>
          </v-col>
        </v-row>

        <v-col cols="12" class="my-0 py-0 ">
          <v-row justify="center">
            <v-col
              cols="11"
              v-if="
                $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
              "
            >
              <v-row justify="center">
                <v-col align="center" v-for="item in viviendas.secciones.slice(0, 5)" :key="item.id">
                  <v-row justify="center">
                    <v-avatar style="cursor:pointer; " :color="'#f5f1ec'" class="mb-2 mt-2" size="90px">
                      <v-icon large dark style="font-size: 60px" :color="'#1d1d1b'">
                        {{ item.icono }}
                      </v-icon>
                    </v-avatar>

                    <v-col cols="12" align="center">
                      <span
                        v-html="$t(item.texto)"
                        class="colorNegro"
                        style="font-weight: 500; font-size:18px;"
                      ></span></v-col
                  ></v-row>
                </v-col>
                <v-row justify="center">
                  <v-col :cols="2" align="center" v-for="item in viviendas.secciones.slice(5, 9)" :key="item.id">
                    <v-row justify="center">
                      <v-avatar style="cursor:pointer; " :color="'#f5f1ec'" class="mb-2 mt-2 " size="90px">
                        <v-icon large dark style="font-size: 60px" :color="'#1d1d1b'">
                          {{ item.icono }}
                        </v-icon>
                      </v-avatar>

                      <v-col cols="12" justify="center" align="center">
                        <span
                          v-html="$t(item.texto)"
                          class=""
                          style="font-weight: 500; font-size:18px; color:secondary"
                        ></span></v-col
                    ></v-row> </v-col
                ></v-row>
              </v-row>
            </v-col>
            <v-col cols="12" v-else>
              <v-row align="center" justify="center">
                <v-col cols="12" align="start" class="mx-0 px-0 my-0 py-0 ">
                  <swiper class="swiper" :options="swiperOptionResponsive">
                    <swiper-slide v-for="(item, i) in viviendas.secciones" :key="i" style="padding: 0">
                      <v-row class="px-2">
                        <v-col cols="12" align="center" class="py-0 my-0">
                          <v-icon size="150" color="primary">{{ item.icono }}</v-icon></v-col
                        >
                        <v-col cols="12" align="center"
                          ><span class="text-body1 text--secondary">{{ $t(item.texto) }}</span></v-col
                        ></v-row
                      >
                    </swiper-slide>
                    <div
                      class="swiper-button-next"
                      style="color: #25becb;top: 90px !important;"
                      slot="button-next"
                    ></div>
                    <div
                      class="swiper-button-prev"
                      slot="button-prev"
                      style="color: #25becb; top: 90px !important;"
                    ></div>
                    <div
                      class="swiper-pagination bulletSmall"
                      slot="pagination"
                      style="padding-top:12px !important"
                    ></div>
                    <div
                      class="swiper-pagination bulletSmall"
                      slot="pagination"
                      style="padding-top:12px !important"
                    ></div>
                  </swiper> </v-col
              ></v-row>
            </v-col>
            <v-col v-if="viviendas.galeriaInterior && viviendas.galeriaInterior.length > 0" cols="12" class="mb-0">
              <v-row
                justify="center"
                :style="
                  $vuetify.breakpoint.name === 'xs' ||
                  $vuetify.breakpoint.name === 'sm' ||
                  $vuetify.breakpoint.name === 'md'
                    ? 'height:550px;'
                    : 'height: 810px'
                "
              >
                <v-col cols="12" md="10" class="px-0">
                  <div class="thumb-example">
                    <swiper
                      class="swiper gallery-top"
                      :options="swiperOptionTop"
                      ref="swiperTop"
                      :style="
                        $vuetify.breakpoint.name === 'xs' ||
                        $vuetify.breakpoint.name === 'sm' ||
                        $vuetify.breakpoint.name === 'md'
                          ? 'height:400px;'
                          : 'height:680px;'
                      "
                      style="display:inline-block"
                    >
                      <swiper-slide
                        v-for="(imagen, i) in viviendas.galeriaInterior"
                        :key="i"
                        :style="
                          $vuetify.breakpoint.name === 'xs' ||
                          $vuetify.breakpoint.name === 'sm' ||
                          $vuetify.breakpoint.name === 'md'
                            ? 'background-size: cover; background-position: center; background-image: url(' +
                              require(`@/assets${imagen.imagen}`) +
                              ');'
                            : 'background-size: cover; background-position: center; background-image: url(' +
                              require(`@/assets${imagen.imagen}`) +
                              ');'
                        "
                      ></swiper-slide>

                      <div
                        v-if="viviendas.galeriaInterior && viviendas.galeriaInterior.length > 1"
                        class="swiper-button-next"
                        :class="
                          $vuetify.breakpoint.name === 'xs' ||
                          $vuetify.breakpoint.name === 'sm' ||
                          $vuetify.breakpoint.name === 'md'
                            ? 'swiper-button-white'
                            : 'swiper-button-white'
                        "
                        slot="button-next"
                      ></div>
                      <div
                        v-if="viviendas.galeriaInterior && viviendas.galeriaInterior.length > 1"
                        class="swiper-button-prev"
                        :class="
                          $vuetify.breakpoint.name === 'xs' ||
                          $vuetify.breakpoint.name === 'sm' ||
                          $vuetify.breakpoint.name === 'md'
                            ? 'swiper-button-white'
                            : 'swiper-button-white'
                        "
                        slot="button-prev"
                      ></div>
                    </swiper>

                    <swiper
                      v-if="viviendas.galeriaInterior && viviendas.galeriaInterior.length > 1"
                      class="swiper gallery-thumbs"
                      :options="swiperOptionThumbs"
                      ref="swiperThumbs"
                    >
                      <swiper-slide
                        v-for="(imagen, i) in viviendas.galeriaInterior"
                        :key="i"
                        style="background-size: cover; background-position: center;"
                        :style="'background-image: url(' + require(`@/assets${imagen.imagen}`) + ');'"
                      ></swiper-slide>
                    </swiper>
                  </div>
                </v-col> </v-row
            ></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="my-0 py-0">
          <v-row class="mt-md-12 pt-md-12">
            <v-col cols="12" class="bgNegro">
              <CallAction
                :colorPers="'#f5f1ec'"
                @aContacto="aContacto()"
                :titulo="$t('amuebla.titulo')"
                :subtitulo="$t('amuebla.subtitulo')"
                :texto="$t('amuebla.subtitulo')"
                :boton="$t('amuebla.boton')"/></v-col></v-row
        ></v-col>

        <v-col cols="12" class="pb-0 mb-0 ">
          <v-row justify="center" class="">
            <v-col cols="12" class="">
              <p
                class="mr-md-8 ml-md-8 mt-4 pb-0 colorNegro"
                style="font-weight:500; text-align: center;"
                :style="
                  $vuetify.breakpoint.name != 'xs' &&
                  $vuetify.breakpoint.name != 'sm' &&
                  $vuetify.breakpoint.name != 'md'
                    ? 'font-size:50px'
                    : 'font-size:30px; font-weight: bold;'
                "
              >
                {{ $t('ubicacion.titulo') }}
              </p></v-col
            >
            <v-col cols="10" class="">
              <p
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="300"
                class="mx-md-8 mx-0 mt-0 pb-2 "
                style="font-size:30px; font-weight-light; color:black; text-align: center;"
                :style="
                  $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
                    ? 'font-size:30px'
                    : 'font-size:22px'
                "
              >
                {{ $t('ubicacion.texto') }}
              </p>
            </v-col>
            <v-col
              cols="12"
              align="center"
              v-if="
                $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
              "
              class=""
            >
              <v-stepper class="elevation-0  mb-4 pb-10 " v-model="stepUbicacion">
                <v-col cols="10" class="pb-0 pt-0 mb-0 mt-0 "
                  ><v-row class="">
                    <template v-for="item in pasosUbicacion">
                      <v-stepper-step
                        :key="item.step"
                        justify="space-between"
                        complete
                        class="hola "
                        :complete-icon="item.icono"
                        :edit-icon="item.icono"
                        :step="item.step"
                        editable
                      >
                        <v-row align="center" class="mr-2">
                          <v-col cols="5" class="ml-0 pl-0"
                            ><v-img width="42px" height="42px" :src="item.icono"></v-img></v-col
                          ><v-col cols="7" class="mx-0 px-0"
                            ><span
                              style="font-weight: bold"
                              :class="stepUbicacion === item.step ? 'colorNegro' : 'colorGris'"
                              >{{ $t(item.titulo) }}</span
                            ></v-col
                          ></v-row
                        >
                      </v-stepper-step>
                      <v-divider class="mt-14" v-if="item.step != 5"></v-divider> </template></v-row
                ></v-col>
                <v-stepper-items no-gutters class="mx-0 px-0 pt-8" style="margin: 0px; padding: 0px">
                  <v-stepper-content
                    style="margin: 0px; padding: 0px"
                    class="mx-0 px-0"
                    v-for="item in pasosUbicacion"
                    :key="item.step"
                    :step="item.step"
                  >
                    <v-col align="start" cols="10">
                      <p class=" pb-2 colorNegro" style="font-size:30px; font-weight:500; ">{{ $t(item.titulo) }}:</p>

                      <p
                        class="mt-2 text-h2 text-md-h5"
                        style="font-size:20px; font-weight: 400; color:black;"
                        v-html="$t(item.contenido)"
                      ></p>
                    </v-col>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
            <v-col v-else cols="12">
              <v-row v-for="(item, i) in pasosUbicacion" :key="i" class="mb-6">
                <v-col cols="12" class="mb-0 pb-0 pl-4">
                  <p style="font-size:22px; font-weight:500;" class="colorNegro">{{ $t(item.titulo) }}:</p>
                </v-col>
                <v-col class="mt-0 pt-0" cols="3"><v-img width="60px" height="60px" :src="item.icono"></v-img></v-col>
                <v-col class="mt-0 pt-0" cols="9"> <p v-html="$t(item.contenido)"></p></v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-row justify="center" class="bgMarronClaro">
                <v-col cols="12" class="pb-0 ">
                  <FormStep
                    ref="formulario"
                    :items="formulario"
                    :formType="'contact'"
                    :colorBg="'#f5f1ec'"
                    :titulos="titulosForm"
                    @stepReset="reset = $event"
                    :datosPromocion="datosPromocion"
                  />
                </v-col> </v-row
            ></v-col>
            <v-col cols="12" class="mx-0 px-0 py-0 my-0 bgNegro">
              <v-row style="" class="bgNegro" align="center" justify="center" no-gutters>
                <v-col cols="12" align="center">
                  <v-row align="center" no-gutters>
                    <v-col cols="12" md="6" justify="center" align="center" class=""
                      ><GoogleMap
                        :latitude="37.37770572459868"
                        :longitude="-5.911841414080792"
                        :title="'MVGM'"
                        style="height:500px;  width: 100%"
                    /></v-col>
                    <v-col
                      v-if="
                        $vuetify.breakpoint.name === 'md' ||
                          $vuetify.breakpoint.name === 'lg' ||
                          $vuetify.breakpoint.name === 'xl'
                      "
                      cols="6"
                      align="start"
                    >
                      <p
                        v-for="(info, i) in informacionPasos"
                        :key="i"
                        :class="informacionPasos[0] === info ? ' pb-12' : ''"
                        :style="informacionPasos[0] === info ? 'font-size: 35px' : 'font-size: 20px'"
                        class="mr-8 ml-8 mt-8 "
                        style="font-weight:500; color: white"
                      >
                        <v-icon
                          :class="informacionPasos[0] === info ? '' : 'mr-4'"
                          color="white"
                          large
                          dark
                          style="font-size:50px"
                          >{{ info.icono }}</v-icon
                        >
                        <span v-if="i != 2">{{ $t(info.texto) }}</span>
                        <a
                          v-else
                          @click="$gtagReportConversion('tel:677602618', 'AW-11199284535/vPZcCNmsuagYELeKntwp')"
                          href="tel:677602618"
                          >{{ $t(info.texto) }}</a
                        >
                      </p>
                    </v-col>
                    <v-col v-else cols="12" class="py-12">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <p style="font-weight:600; font-size: 24px; color: white" class="px-2 mb-4">
                            {{ $t(informacionPasos[0].texto) }}
                          </p></v-col
                        ></v-row
                      >
                      <v-row no-gutters v-for="(info, i) in informacionPasos.slice(1)" :key="i">
                        <v-col cols="12">
                          <v-icon color="white" size="40">{{ info.icono }}</v-icon></v-col
                        >
                        <v-col cols="12">
                          <p
                            style="font-weight:600; color: white"
                            :style="$vuetify.breakpoint.name === 'sm' ? 'font-size: 16px;' : 'font-size: 12px;'"
                            class="px-2"
                          >
                            {{ $t(info.texto) }}
                          </p></v-col
                        ></v-row
                      >
                    </v-col>
                  </v-row></v-col
                ></v-row
              ></v-col
            >
          </v-row></v-col
        >
        <v-col cols="12" class="mx-0 my-0 py-0 px-0">
          <v-row class="mx-0 my-0 py-0 px-0">
            <v-parallax
              height="800"
              style="width:100%"
              :src="require('@/assets/promociones/footerPisc.jpg')"
            ></v-parallax>
          </v-row>
        </v-col>
      </div>
      <div v-else>
        <!-- <v-row justify="center" style="">
          <v-col cols="12" md="10" lg="10" xl="10" justify="center">
            <p
              data-aos="fade-up"
              class="mr-8 ml-8 mt-10 pb-2"
              style="font-size:70px; font-weight:600; color:#25becb; text-align: center; text-transform: uppercase;"
            >
              {{ $t('titulosPromociones.sevillaLasDelicias') }}
            </p>
            <p
              data-aos="fade-up"
              class="mr-8 ml-8 mt-10 pb-2"
              style="font-size:40px; font-weight:500; color:#25becb; text-align: center; text-transform: uppercase"
            >
              {{ $t('enConstruccion.title') }}
            </p>
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mr-8 ml-8 mt-6 pb-8 text-body1 text-md-h6"
              style="font-weight:400; color:black; text-align: center;"
            >
              {{ $t('enConstruccion.promociones.sevillaLasDelicias') }}
            </p>
          </v-col>
          <v-col cols="12" class="my-0 py-0">
        <v-row justify="center" class="pb-n8 mb-n8">
          <v-col cols="12" class="pb-0 mb-10">
            <FormStep
              ref="formulario"
              :items="formulario"
              :formType="'contact'"
              :titulos="titulosForm"
              @stepReset="reset = $event"
              :datosPromocion="datosPromocion"
            />
          </v-col> </v-row
      ></v-col>
        </v-row> -->
      </div>
    </template>
  </pages-layout>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import GoogleMap from '@/components/GoogleMap'
import FormStep from '@/components/FormStep'
import CallAction from '@/components/CallAction'

export default {
  name: 'sevilla-las-delicias',
  components: {
    FormStep,
    Swiper,
    SwiperSlide,
    GoogleMap,
    CallAction
  },
  mounted() {
    this.importAll(require.context('../../assets/promociones/galeriaInterior/', true, /\.jpg$/))
    this.importAllExt(require.context('../../assets/promociones/galeriaExterior/', true, /\.jpg$/))
    this.importAllSocialClub(require.context('../../assets/promociones/galeriaSocialClub/', true, /\.jpg$/))
    this.importAllCaract(require.context('../../assets/promociones/galeriaCaracteristicas/', true, /\.jpg$/))
    this.$gtag('config', 'AW-11199284535/KuStCML3ragYELeKntwp', { phone_conversion_number: '677 60 26 18' })
    this.$nextTick(() => {
      if (this.viviendas.galeriaInterior && this.viviendas.galeriaInterior.length > 0) {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      }
    })
  },
  created() {
    window.scrollTo(0, 0)
  },
  data() {
    return {
      datosPromocion: {
        email: 'alquiler.residencial.sevilla@mvgm.com',
        nombre: 'SEVILLA LAS DELICIAS'
      },
      enConstruccion: false,
      titulosForm: {
        titulo: 'contacto.titleForm',
        subtitulo: 'contacto.subTitleForm'
      },
      complejo: {
        titulo: 'complejo.titulo',
        texto: 'complejo.texto',
        instalaciones: {
          introduccion: 'complejo.instalaciones.introduccion',
          tituloPiscina: 'complejo.instalaciones.tituloPiscina',
          espacios: [
            {
              texto: 'complejo.instalaciones.espacios.texto1',
              icon: 'mdi-pool'
            },
            {
              texto: 'complejo.instalaciones.espacios.texto2',
              icon: 'mdi-tennis-ball'
            },
            {
              texto: 'complejo.instalaciones.espacios.texto3',
              icon: 'mdi-slide'
            },
            {
              texto: 'complejo.instalaciones.espacios.texto4',
              icon: 'mdi-stadium-variant'
            },
            {
              texto: 'complejo.instalaciones.espacios.texto5',
              icon: 'mdi-basketball'
            },
            {
              texto: 'complejo.instalaciones.espacios.texto6',
              icon: 'mdi-soccer'
            },
            {
              texto: 'complejo.instalaciones.espacios.texto7',
              icon: 'mdi-run-fast'
            },
            {
              texto: 'complejo.instalaciones.espacios.texto8',
              icon: 'mdi-dog'
            }
          ]
        }
      },
      clubSocial: {
        titulo: 'clubSocial.titulo',
        texto: 'clubSocial.texto'
      },

      pasosUbicacion: [
        {
          titulo: 'pasosUbicacion.titulo1',
          contenido: 'pasosUbicacion.contenido1',
          icono: require('@/assets/promociones/iconos/icon_accesos.svg'),
          img: require('@/assets/home/UBIDOMO_slide-6-1500x630.jpg'),
          step: 1
        },
        {
          titulo: 'pasosUbicacion.titulo2',
          contenido: 'pasosUbicacion.contenido2',
          icono: require('@/assets/promociones/iconos/icon_bus.svg'),
          img: require('@/assets/home/UBIDOMO_slide-6-1500x630.jpg'),
          step: 2
        },
        {
          titulo: 'pasosUbicacion.titulo3',
          contenido: 'pasosUbicacion.contenido3',
          icono: require('@/assets/promociones/iconos/icon_cercanias.svg'),
          img: require('@/assets/home/UBIDOMO_slide-7-1500x630.jpg'),
          step: 3
        },
        {
          titulo: 'pasosUbicacion.titulo4',
          contenido: 'pasosUbicacion.contenido4',
          icono: require('@/assets/promociones/iconos/icon_interurbano.svg'),
          img: require('@/assets/home/UBIDOMO_slide-4-1500x630.jpg'),
          step: 4
        },
        {
          titulo: 'pasosUbicacion.titulo5',
          contenido: 'pasosUbicacion.contenido5',
          icono: require('@/assets/promociones/iconos/icon_servicios.svg'),
          img: require('@/assets/promociones/servicios.jpg'),
          step: 5
        }
      ],
      informacionPasos: [
        { texto: 'informacionPasos.texto1', icono: '' },
        { texto: 'informacionPasos.texto2', icono: 'mdi-map-marker' },
        { texto: 'informacionPasos.texto3', icono: 'mdi-phone' },
        { texto: 'informacionPasos.texto4', icono: 'mdi-email-outline' }
      ],
      stepUbicacion: 1,
      edificioTitulo: {
        titulo: 'edificioTitulo.titulo',
        texto: 'edificioTitulo.texto',
        boton: 'edificioTitulo.boton'
      },
      caracteristicasEdificio: {
        titulo: 'edificio.seccion1.introduccion',

        secciones: [
          {
            texto: 'edificio.seccion1.texto1'
          },
          { texto: 'edificio.seccion1.texto2' },
          {
            texto: 'edificio.seccion1.texto3'
          },
          { texto: 'edificio.seccion1.texto4' }
        ],
        imagenes: [
          { imagen1: require('@/assets/promociones/fachada_parking2.jpg') },
          { imagen2: require('@/assets/promociones/terrazas.jpg') }
        ]
      },
      certificadoEnergia: {
        texto: 'edificio.seccion2.introduccion',
        titulo: 'edificio.seccion2.titulo',
        secciones: [
          { texto: 'edificio.seccion2.texto1' },
          { texto: 'edificio.seccion2.texto2' },
          {
            texto: 'edificio.seccion2.texto3'
          }
        ],
        medidas: [
          { texto: 'edificio.seccion2.medida1', icon: 'mdi-weather-sunny' },
          { texto: 'edificio.seccion2.medida2', icon: 'mdi-water-boiler-auto' },
          { texto: 'edificio.seccion2.medida3', icon: 'mdi-water-thermometer' },
          {
            texto: 'edificio.seccion2.medida4',
            icon: 'mdi-water-sync'
          },
          { texto: 'edificio.seccion2.medida5', icon: 'mdi-string-lights' }
        ],

        imagenes: [{ imagen: '/promociones/CEE_A.jpg' }, { imagen: '/promociones/Breem.png' }]
      },

      viviendas: {
        titulo: 'viviendas.titulo',
        texto: 'viviendas.texto',
        secciones: [
          {
            texto: 'viviendas.punto1',
            icono: 'mdi-countertop'
          },
          {
            texto: 'viviendas.punto2',
            icono: 'mdi-home-thermometer'
          },
          {
            texto: 'viviendas.punto3',
            icono: 'mdi-faucet'
          },
          {
            texto: 'viviendas.punto4',
            icono: 'mdi-wardrobe'
          },
          {
            texto: 'viviendas.punto5',
            icono: 'mdi-floor-plan'
          },
          {
            texto: 'viviendas.punto6',
            icono: 'mdi-bathtub'
          },
          {
            texto: 'viviendas.punto7',
            icono: 'mdi-pine-tree'
          },
          {
            texto: 'viviendas.punto8',
            icono: 'mdi-shield-key'
          },
          {
            texto: 'viviendas.punto9',
            icono: 'mdi-hand-saw'
          }
        ],
        galeriaInterior: []
      },
      cabecera: [
        { src: '/promociones/cabecera/areaDeportiva.jpg' },
        /* { src: '/promociones/cabecera/cabecera3.jpg' },

        { src: '/promociones/cabecera/cabecera5.jpg' },
        { src: '/promociones/cabecera/cabecera8.jpg' }, */
        { src: '/promociones/footerPisc.jpg' },
        {
          src: '/promociones/cabecera/cabecera2.jpg'
        },
        { src: '/promociones/cabecera/piscinaClubSocial.jpg' }

        /* { src: '/promociones/cabecera/cabecera7.jpg' } */
      ],
      formulario: [
        {
          step: 1,
          titulo: 'formulario.formNameContact.titulo',
          subtitulo: 'formulario.formNameContact.subtitulo',
          type: 'nombre',
          label: 'formulario.formNameContact.label',
          boton: 'formulario.formNameContact.boton',
          literalStep: 'formulario.formNameContact.literal'
        },
        {
          step: 2,
          titulo: 'formulario.formEmailNewsletter.titulo',
          subtitulo: 'formulario.formEmailNewsletter.subtitulo',
          label: 'formulario.formEmailNewsletter.label',
          boton: 'formulario.formEmailNewsletter.boton',
          type: 'email',
          literalStep: 'formulario.formEmailNewsletter.literal'
        },
        {
          step: 3,
          titulo: 'formulario.formTextNewsletter.titulo',
          subtitulo: 'formulario.formTextNewsletter.subtitulo',
          label: 'formulario.formTextNewsletter.label',
          boton: 'formulario.formTextNewsletter.boton',
          type: 'comentarios',
          maxStep: true,
          literalStep: 'formulario.formTextNewsletter.literal'
        }
      ],
      email: '',
      swiperOption: {
        enabled: false,
        speed: 2000,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionResponsive: {
        enabled: false,
        speed: 2000,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        loop: true,
        autoplay: {
          delay: 10000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true
      },
      galeriaExterior: [],
      galeriaSocialClub: [],
      galeriaCaracteristicas: [],
      reset: false
    }
  },
  methods: {
    viviendasPromocion() {
      this.$router.push({ name: 'properties', params: { codigo: 140 } })
    },
    importAll(r) {
      r.keys().forEach(key =>
        this.viviendas.galeriaInterior.push({ imagen: '/promociones/galeriaInterior' + key.slice(1) })
      )
    },
    aContacto() {
      window.scrollTo(0, this.$refs.formulario.$el.offsetTop)
    },
    importAllExt(r) {
      r.keys().forEach(key =>
        this.galeriaExterior.push({
          src: '/promociones/galeriaExterior' + key.slice(1)
        })
      )
    },
    importAllSocialClub(r) {
      r.keys().forEach(key =>
        this.galeriaSocialClub.push({
          src: '/promociones/galeriaSocialClub' + key.slice(1)
        })
      )
    },
    importAllCaract(r) {
      r.keys().forEach(key =>
        this.galeriaCaracteristicas.push({
          src: '/promociones/galeriaCaracteristicas' + key.slice(1)
        })
      )
    }
  }
}
</script>
<style scoped>
a:hover {
  color: #cacaca;
}
a {
  color: #fff;
}
</style>
<style>
.bulletSmall .swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  text-align: center;
  margin: 0px 6px -20px 6px !important;
}
.thumb-example {
  height: 500px;
  background-color: 'black';
}
.swiper .swiper-slide {
  background-size: cover;
  background-position: center;
}
.swiper.gallery-top {
  height: 100%;
  width: 100%;
}
.swiper.gallery-thumbs {
  /* height: 100%; */
  box-sizing: border-box;
  padding-top: 10px;
}
.swiper.gallery-thumbs .swiper-slide {
  width: 15%;
  height: 100px;
  opacity: 0.5;
}
.swiper.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
.typer-promocion.vue-typer .custom.char.typed {
  color: #5c7c6c !important;
}
.hola .v-stepper__step__step {
  display: none !important;
}
.parent {
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.child {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.child:before {
  content: '';
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.tarjeta:hover .child,
.tarjeta:focus .child {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.tarjeta:hover .child:before,
.tarjeta:focus .child:before {
  display: block;
}
.button {
  border-style: solid;
  border-width: 5px;
  border-color: white;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding: 20px;
  width: 400px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}
.buttonSmall {
  border-style: solid;
  border-width: 5px;
  border-color: white;
  border-radius: 4px;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
  color: #1d1d1b;
}
.button:hover {
  background-color: #f5f1ec;
  border-radius: 4px;
  border-style: solid;
  border-width: 5px;
  border-color: #f5f1ec;
}
.button:hover span {
  padding-right: 25px;
  color: #1d1d1b;
}

.button:hover span:after {
  opacity: 1 !important;
  right: 10;
}
.btnCallAction {
  font-weight: 600;
  font-size: 30px;
  color: #1d1d1b;
}
@media only screen and (max-width: 420px) {
  .btnCallAction {
    font-weight: 600;
    font-size: 20px;
    color: #1d1d1b;
  }
}
@media only screen and (max-width: 960px) and (min-width: 600px) {
  .btnCallAction {
    font-weight: 600;
    font-size: 15px;
    color: #1d1d1b;
  }
}
.fuenteLato {
}
.fuenteBarlow {
}
.colorNegro {
  color: #1d1d1b;
}
.colorMarronClaro {
  color: #f5f1ec;
}
.colorGris {
  color: #d8d8d8;
}
.colorBlaco {
  color: #ffffff;
}

.bgNegro {
  background-color: #1d1d1b;
}
.bgMarronClaro {
  background-color: #f5f1ec;
}
.bgGris {
  background-color: #d8d8d8;
}
.bgBlanco {
  background-color: #ffffff;
}
.buttonView {
  border-style: solid;
  border-width: 5px;
  border-color: #1d1d1b;
  border-radius: 4px;
  color: #1d1d1b;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding: 20px;
  width: 400px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}
.buttonSmallView {
  border-style: solid;
  border-width: 5px;
  border-color: #1d1d1b;
  border-radius: 4px;
  color: #1d1d1b;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.buttonView span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.buttonView span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
  color: #f5f1ec;
}
.buttonView:hover {
  background-color: #1d1d1b;
  border-radius: 4px;
  border-style: solid;
  border-width: 5px;
  border-color: #1d1d1b;
}
.buttonView:hover span {
  padding-right: 25px;
  color: #f5f1ec;
}

.buttonView:hover span:after {
  opacity: 1 !important;
  right: 10;
}
</style>
