<template>
<!-- eslint-disable -->
  <v-app class="pr-0 mr-0" >
    <Alerts />
    <HeaderCard v-if="!loadingData"/>
    <v-main>
      <v-slide-x-reverse-transition mode="out-in">
        <router-view v-if="!loadingData"/>
        <v-container  v-else fill-height>
          <v-row  justify="center" align="center">
            <v-progress-circular
              size="100"
              indeterminate
              color="viralYellow"
            />
          </v-row>
        </v-container>
      </v-slide-x-reverse-transition>
      <v-dialog
        v-model="dialog"      
        max-width="600px"
        @click="dialog = true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-col cols="12" class="pb-0 mb-0" style="padding: 0px">
            <v-row justify="end" >
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  fixed
                  color="primary"
                  style="border-radius:10px 0px 0px 10px; top:140px; z-index: 2"
                  height="50"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="" class="mr-2" large>mdi-card-account-phone</v-icon>
                  <v-expand-x-transition>
                    <span v-if="hover" class="ml-2">{{$t('teLlamamos.title')}}</span>
                  </v-expand-x-transition>
                </v-btn>
              </v-hover>
            </v-row>
          </v-col>
        </template>
        <v-card>
          <v-container fluid >
            <v-row no-gutters justify="center">              
              <v-row justify="center" align="center" class="mt-3 ml-0 ml-sm-12 mb-3">
                <v-col cols="12" sm="2" md="3" lg="3" xl="3" class="py-0" align="center">
                  <v-img
                    src="@/assets/tellamamos.jpg"
                    lazy-src="@/assets/tellamamos.jpg"
                    max-height="100"
                    max-width="100"
                  />
                </v-col>
                <v-col class="py-0 mt-2 text--center text--sm-start" :align="$vuetify.breakpoint.name != 'xs' ? 'start' : 'center' ">
                  <span style="font-weight:500" class="primary--text text-h4">{{$t('teLlamamos.title')}}</span>
                </v-col>
              </v-row>
              <ConversationalForm ref="conversationalForm" @submit="submit"  class="mb-4 sizeChat">
                <fieldset>
                  <label for="nombre"/>
                  <input required :data-question="dameConver('comotellamas')" type="text"  ref="nombre" name="nombre" id="nombre" />
                </fieldset>
                <fieldset>
                  <label for="ciudad"/>
                  <select :data-question="dameConver('ciudadInteres')" name="ciudad" ref="ciudad" id="ciudad">
                    <option v-for="ciudad in ciudades">{{ciudad}}</option>
                  </select>
                </fieldset>
                <fieldset>
                  <label for="telefono"/>
                  <input pattern="[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]+[0-9]" :data-invalid="dameConver('invalidPhone')" required :data-question="dameConver('telefono')" type="text" name="telefono" ref="telefono" id="telefono" />
                </fieldset>
                <fieldset>
                  <label for="email"/>
                  <input pattern=".+\@.+\..+" :data-invalid="dameConver('invalidEmail')" :data-question="dameConver('email')" type="email" name="email" id="email">
                </fieldset>
                <fieldset>
                  <label for="politica"/>
                  <select :data-question="dameConver('politica')" name="politica" ref="politica" id="politica">
                    <option>{{dameConver('yes')}}</option>
                  </select>
                </fieldset>
                <fieldset>
                  <label for="listo"/>
                  <button
                    :data-question="dameConver('estasListo')"
                    name="submit"
                    type="submit"
                    data-cancel="No"
                    :data-success="dameConver('thanks')"
                  >
                    {{dameConver('yes')}}
                  </button>
                </fieldset>
                <fieldset>
                  <label for="restart"/>
                  <button :data-question="dameConver('again')" name="reset" type="reset" data-cancel="No">{{dameConver('yesAgain')}}</button>
                </fieldset>
                <fieldset>
                  <label>{{dameConver('onlyThanks')}}</label>
                </fieldset>
              </ConversationalForm>
            </v-row>
            <v-row justify="center" align="center">
               <pop-up-politica
                  v-model="privacyDialog"
                  @aceptado="privacyAcepted = true"
                  :formType="formType"
                  :conversacional="true"
                  :mostrarAceptar="true"
                />
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import Alerts from '@/components/Alerts'
import PopUpPolitica from '@/components/PopUpPolitica.vue'
/* import NavigationDrawer from '@/components/NavigationDrawer' */
import HeaderCard from '@/components/HeaderCard'
// import TextoCentrado from '@/components/Textos/TextoCentrado'
import ConversationalForm from 'vue-conversational-form'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    Alerts,
    PopUpPolitica,
    //NavigationDrawer
    HeaderCard,
    // TextoCentrado,
    ConversationalForm
  },
  data() {
    return {
      userOptions: [
        { icon: 'mdi-account', title: 'Editar perfil', to: { name: 'perfil' } },
        { icon: 'mdi-login-variant', title: 'Cerrar sesión', to: { name: 'login' } }
      ],
      privacyDialog: false,
      privacyAcepted: false,
      showDrawer: false,
      formType: 'contact',
      loadingData: false,
      dialog: false,
      politicaOptions: ['Sí', 'No'],
      promociones: []
    }
  },
  async created() {
    window.onbeforeunload = function() {
      window.scrollTo(0, 0)
    }
    this.promociones = await this.loadPromociones()
  },
  computed: {
    ...mapGetters({}),
    ciudades() {
      if (this.promociones == null) {
        return []
      }
      return ['Madrid', 'Sevilla']
      // return this.promociones.map(x => {
      //   return x.provincia
      // })
    },
    language() {
      return this.$root.$i18n.locale
    }
  },
  methods: {
    ...mapActions({ almacenarDatos: 'email/almacenarDatos', loadPromociones: 'properties/loadPromociones' }),
    clickLogo() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      }
    },
    dameConver(id) {
      if (this.$root.$i18n.locale == 'es') {
        switch (id) {
          case 'comotellamas':
            return '¡Hola! ¿Cómo te llamas?'
          case 'ciudadInteres':
            return '¿En qué ciudad estás interesad@?'
          case 'telefono':
            return 'Estupendo, ¿nos indicas tu número de teléfono?'
          case 'invalidPhone':
            return 'El teléfono no es correcto por favor introducelo de nuevo'
          case 'invalidEmail':
            return 'El email no es correcto por favor introducelo de nuevo'
          case 'email':
            return 'Por último facilitanos tu email para que podamos ponernos en contacto contigo'
          case 'estasListo':
            return 'Bien {nombre}, ¿estás list@ para enviar la petición de llamada?'
          case 'thanks':
            return '¡Solicitud enviada! Nos pondremos en contacto contigo lo antes posible. Muchas gracias.'
          case 'again':
            return '¿Quieres comenzar de nuevo?'
          case 'yesAgain':
            return 'Sí, responder de nuevo'
          case 'onlyThanks':
            return '¡Muchas gracias!'
          case 'politica':
            return '¿Aceptas nuestra politica de privacidad?'
          case 'yes':
            return 'Sí'
        }
      } else {
        switch (id) {
          case 'comotellamas':
            return 'Hello! What is your name?'
          case 'ciudadInteres':
            return 'What city are you interested in?'
          case 'telefono':
            return 'Great, can you tell us your phone number?'
          case 'invalidPhone':
            return 'The phone is not correct please enter it again'
          case 'invalidEmail':
            return 'The email is not correct, please enter it again'
          case 'email':
            return 'Finally, provide us with your email so we can get in touch with you'
          case 'estasListo':
            return 'Well {nombre}, are you ready to send the call request?'
          case 'thanks':
            return 'Request sent! We will get in touch with you as soon as possible. Thank you.'
          case 'again':
            return 'Do you want to start over?'
          case 'yesAgain':
            return 'Yes, answer again'
          case 'onlyThanks':
            return 'Thank you!'
          case 'politica':
            return 'Do you accept our privacy policy?'
          case 'yes':
            return 'Yes'
        }
      }
    },
    async submit(data) {
      await this.almacenarDatos({
        nombre: data.nombre,
        email: data.email,
        ciudad: data.ciudad,
        telefono: data.telefono,
        tipoEmail: 'conversational',
        inmuebleId: null,
        emailPromocion: data.ciudad != 'Sevilla' ? null : 'alquiler.residencial.sevilla@mvgm.com'
      })
    }
  },
  watch: {
    dialog() {
      this.$refs.conversationalForm.init()
    }
  }
}
</script>

<style>
.vcf-container {
  width: 80vw !important;
}
.vcf-question span {
  background: linear-gradient(to right, #5c7c6c, #25becb) !important;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 500;
}
.vcf-answer span {
  font-family: 'Montserrat';
  color: #5c7c6c !important;
  font-size: 15px;
  font-weight: 500;
}
.vcf-send-button {
  background: linear-gradient(to right, #5c7c6c, #25becb) !important;
}
.vcf-option {
  font-family: 'Montserrat';
  color: #5c7c6c !important;
  font-size: 12px;
  font-weight: bold;
}
.vcf-input {
  font-family: 'Montserrat';
  color: #5c7c6c !important;
  font-size: 20px !important;
  font-weight: 500;
}
</style>
