<template>
  <pages-layout>
    <template v-slot:content>
      <Header :title="'titulosSecciones.contact'" :image="require('@/assets/contact/header3.jpg')" />
      <v-col cols="12" class="mt-8 ">
        <v-row justify="center" class="pb-0 mb-0">
          <v-col cols="12" md="10" lg="7" justify="center" class="pb-0 mb-0">
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              class="mr-8 ml-8 mt-8 mb-0 text-body1 text-md-h6"
              style="font-weight:400; color:black; text-align: center;"
            >
              <!-- mt-10 mb-2 mb-md-10 -->
              {{ $t('contactPage.description') }}
            </p></v-col
          ></v-row
        >
        <!-- <v-row
          ><GoogleMap
            :latitude="40.44136892833852"
            :longitude="-3.679683207263298"
            :title="'MVGM'"
            style=" height:600px"
        /></v-row> -->
        <v-col cols="12" justify="center" class="px-0 mt-0">
          <FormStep :items="formulario" :formType="'contact'" :titulos="titulosForm" @stepReset="reset = $event"
        /></v-col>
      </v-col>
    </template>
  </pages-layout>
</template>
<script>
import Header from '@/components/Header'

//import GoogleMap from '@/components/GoogleMap'
import FormStep from '@/components/FormStep'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'contact',
  components: {
    //GoogleMap,
    Header,
    FormStep

    //TextDividerSmall
  },
  data() {
    return {
      titulosForm: { titulo: 'contacto.titleForm', subtitulo: 'contacto.subTitleForm' },
      formulario: [
        {
          step: 1,
          titulo: 'formulario.formNameContact.titulo',
          subtitulo: 'formulario.formNameContact.subtitulo',
          type: 'nombre',
          label: 'formulario.formNameContact.label',
          boton: 'formulario.formNameContact.boton',
          literalStep: 'formulario.formNameContact.literal'
        },
        {
          step: 2,
          titulo: 'formulario.formEmailContact.titulo',
          subtitulo: 'formulario.formEmailContact.subtitulo',
          label: 'formulario.formEmailContact.label',
          boton: 'formulario.formEmailContact.boton',
          type: 'email',
          literalStep: 'formulario.formEmailContact.literal'
        },
        {
          step: 3,
          titulo: 'formulario.formTextContact.titulo',
          maxStep: true,
          subtitulo: 'formulario.formTextContact.subtitulo',
          label: 'formulario.formTextContact.label',
          boton: 'formulario.formTextContact.boton',
          type: 'comentarios',
          literalStep: 'formulario.formTextContact.literal'
        }
      ]
    }
  },
  created() {
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>
<style></style>
