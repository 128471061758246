<template>
  <div>
    <v-col cols="12" class="py-0 px-0 mx-0 my-0">
      <v-parallax :height="height" :src="image">
        <v-row justify="center" align="center">
          <v-col class="text-center mt-2" cols="12">
            <!--text-h2 text-sm-h1 text-md-h1 text-lg-h1 text-xl-h1-->
            <vue-typer
              class="mt-4 vuetyper"
              :repeat="0"
              :style="
                $vuetify.breakpoint.name === 'md' ||
                $vuetify.breakpoint.name === 'lg' ||
                $vuetify.breakpoint.name === 'xl'
                  ? 'font-size: 148px'
                  : 'font-size: 55px'
              "
              style="font-weight: bold;font-family: 'Style Script', cursive !important; color:white;text-align: center; font-size:150px; opacity: 0.5;"
              :text="$t(title)"
              :pre-type-delay="0"
              :type-delay="40"
              caret-animation="solid"
            ></vue-typer>
            <div
              class="mt-4 titleNoTyper"
              :repeat="0"
              :style="
                $vuetify.breakpoint.name === 'md' ||
                $vuetify.breakpoint.name === 'lg' ||
                $vuetify.breakpoint.name === 'xl'
                  ? 'font-size: 148px'
                  : 'font-size: 55px'
              "
              style="display:none;font-weight: bold;font-family: 'Style Script', cursive !important; color:white;text-align: center; font-size:150px; opacity: 0.5;"
            >
              {{ $t(title) }}
            </div>
          </v-col>
        </v-row>
      </v-parallax>
    </v-col>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    title: {
      type: String
    },
    image: {
      type: String
    },
    height: {
      type: String,
      default: '400'
    }
  },
  name: 'App',
  data() {
    return {}
  },
  created() {},
  computed: {
    languageNavegador() {
      return navigator.language
    },
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>

<style scoped>
.translated-ltr .vuetyper {
  display: none;
}
.translated-ltr .titleNoTyper {
  display: inherit !important;
}
</style>
.translated-ltr .nav, .translated-rtl .nav {}
