import api from '@/api/api'
export default {
  namespaced: true,
  state: {
    rangoPrecio: [0, 3000],
    ciudad: null,
    tipologia: null,
    language: 'es',
    dormitorios: [],
    amueblado: false,
    promocion: null,
    orden: 'Recientes',
    promociones: ['Los rosales', 'Castamar', 'Los montes', 'Pinar de la sierra'],
    properties: [
      {
        id: 1,
        tipovia: 'Avenida',
        titulo: 'de la Primavera 4',
        amueblado: false,
        ciudad: 'Sevilla',
        fecha: '07/02/2011',
        tipologia: 'Piso',
        area: '120',
        precio: '500',
        dormitorios: '2',
        disponibilidad: 'Bajo opción',
        imagen: require('@/assets/fake_properties/1/001.png')
      },
      {
        id: 2,
        tipovia: 'Vía',
        titulo: 'Carlota O´Neill 5',
        ciudad: 'Valencia',
        amueblado: false,
        tipologia: 'Piso',
        fecha: '07/02/2011',
        area: '300',
        precio: '2750',
        dormitorios: '1',
        disponibilidad: 'Disponible',
        imagen: require('@/assets/fake_properties/2/001.png')
      },
      {
        id: 3,
        tipovia: 'Glorieta',
        titulo: 'Juan Ortensio Ramírez 16',
        ciudad: 'Barcelona',
        amueblado: false,
        tipologia: 'Piso',
        fecha: '07/02/2011',
        area: '50',
        precio: '2100',
        dormitorios: '4',
        disponibilidad: 'Alquilado con reserva',
        imagen: require('@/assets/fake_properties/3/001.png')
      },
      {
        id: 4,
        tipovia: 'Calle',
        titulo: 'del Océano 9',
        ciudad: 'Madrid',
        tipologia: 'Piso',
        amueblado: false,
        fecha: '07/02/2011',
        area: '89',
        precio: '989',
        dormitorios: '3',
        disponibilidad: 'Alquilado',
        imagen: require('@/assets/fake_properties/4/001.png')
      },
      {
        id: 5,
        tipovia: 'Calle',
        titulo: 'Gutierre de Cetina 8',
        ciudad: 'Madrid',
        amueblado: false,
        fecha: '07/02/2011',
        tipologia: 'Piso',
        area: '20',
        precio: '1502',
        dormitorios: '6',
        disponibilidad: 'Alquilado con reserva',
        imagen: require('@/assets/fake_properties/5/001.png')
      },
      {
        id: 6,
        tipovia: 'Calle',
        titulo: 'Castillo 20',
        amueblado: false,
        ciudad: 'Sevilla',
        fecha: '28/02/2021',
        tipologia: 'Piso',
        area: '115',
        precio: '855',
        dormitorios: '3',
        disponibilidad: 'Bajo opción',
        imagen: require('@/assets/fake_properties/6/001.png')
      },
      {
        id: 7,
        tipovia: 'Avenida',
        titulo: 'Complutense 4',
        amueblado: false,
        ciudad: 'Sevilla',
        fecha: '07/02/2020',
        tipologia: 'Piso',
        area: '120',
        precio: '630',
        dormitorios: '2',
        disponibilidad: 'Bajo opción',
        imagen: require('@/assets/fake_properties/7/001.png')
      },
      {
        id: 8,
        tipovia: 'Vía',
        titulo: 'Lusitania 5',
        ciudad: 'Valencia',
        amueblado: false,
        tipologia: 'Piso',
        fecha: '07/02/2011',
        area: '300',
        precio: '2520',
        dormitorios: '1',
        disponibilidad: 'Disponible',
        imagen: require('@/assets/fake_properties/8/001.png')
      },
      {
        id: 9,
        tipovia: 'Glorieta',
        titulo: 'Martínez Sánchez 16',
        ciudad: 'Barcelona',
        amueblado: false,
        tipologia: 'Piso',
        fecha: '07/02/2011',
        area: '50',
        precio: '1900',
        dormitorios: '4',
        disponibilidad: 'Alquilado con reserva',
        imagen: require('@/assets/fake_properties/9/001.png')
      },
      {
        id: 10,
        tipovia: 'Paseo',
        titulo: 'de la chopera 9',
        ciudad: 'Madrid',
        tipologia: 'Piso',
        amueblado: false,
        fecha: '07/02/2011',
        area: '89',
        precio: '960',
        dormitorios: '3',
        disponibilidad: 'Alquilado',
        imagen: require('@/assets/fake_properties/10/001.png')
      },
      {
        id: 11,
        tipovia: 'Calle',
        titulo: 'Islas Medas 8',
        ciudad: 'Madrid',
        amueblado: false,
        fecha: '07/02/2011',
        tipologia: 'Piso',
        area: '20',
        precio: '1400',
        dormitorios: '6',
        disponibilidad: 'Alquilado con reserva',
        imagen: require('@/assets/fake_properties/11/001.png')
      },
      {
        id: 12,
        tipovia: 'Avenida',
        titulo: 'de Brasil 25',
        amueblado: true,
        ciudad: 'Madrid',
        fecha: '28/02/2011',
        tipologia: 'Piso',
        area: '185',
        precio: '2620',
        dormitorios: '4',
        disponibilidad: 'Bajo opción',
        imagen: require('@/assets/fake_properties/12/001.png')
      },
      {
        id: 13,
        tipovia: 'Calle',
        titulo: 'Islas Medas 8',
        ciudad: 'Madrid',
        amueblado: true,
        fecha: '07/02/2011',
        tipologia: 'Piso',
        area: '20',
        precio: '1180',
        dormitorios: '6',
        disponibilidad: 'Alquilado con reserva',
        imagen: require('@/assets/fake_properties/13/001.png')
      },
      {
        id: 14,
        tipovia: 'Calle',
        titulo: 'San Germán 67',
        amueblado: false,
        ciudad: 'Madrid',
        fecha: '28/02/2011',
        tipologia: 'Piso',
        area: '185',
        precio: '2990',
        dormitorios: '4',
        disponibilidad: 'Bajo opción',
        imagen: require('@/assets/fake_properties/14/001.png')
      }
    ]
  },
  mutations: {
    setAll(state, payload) {
      state.ciudad = payload.ciudad
      state.tipologia = payload.tipologia
      state.dormitorios = payload.dormitorios
      state.amueblado = payload.amueblado
      state.promocion = payload.promocion
      state.rangoPrecio = payload.precio
      state.orden = payload.orden
    },
    setLang(state, payload) {
      state.language = payload
    }
  },
  getters: {
    getFiltros(state) {
      return {
        ciudad: state.ciudad,
        tipologia: state.tipologia,
        dormitorios: state.dormitorios,
        amueblado: state.amueblado,
        promocion: state.promocion,
        rangoPrecio: state.rangoPrecio,
        orden: state.orden
      }
    },
    getLang(state) {
      return state.language
    }
  },
  actions: {
    updateFiltros({ commit }, filtros) {
      commit('setAll', filtros)
    },
    updateLanguage({ commit }, lang) {
      localStorage.language = lang
      commit('setLang', lang)
    },
    loadPromociones({ dispatch }) {
      return new Promise((resolve, reject) => {
        api
          .get('/inmuebles/get_promociones_lista')
          .then(response => {
            if (response.data.result.ok) {
              resolve(response.data.result.data.data.promociones)
            } else {
              dispatch()
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
      // return getters.getPromociones
    },
    loadTipologias({ dispatch }) {
      return new Promise((resolve, reject) => {
        api
          .get('/inmuebles/get_tipologias_lista')
          .then(response => {
            if (response.data.result.ok) {
              resolve(response.data.result.data.data.tipologias)
            } else {
              dispatch()
              reject(response.data)
            }
          })
          .catch(e => {
            reject(e)
          })
      })
      // return getters.getPromociones
    },
    getInmuebles({ dispatch }, parametros) {
      return new Promise((resolve, reject) => {
        api
          .get('/inmuebles/get_lista', {
            params: {
              jsondata: JSON.stringify({
                parametros
              })
            }
          })
          .then(response => {
            if (response.data.result.ok) {
              resolve(response.data.result.data.data.inmuebles)
            } else {
              dispatch()
              reject()
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    loadInmueble({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        api
          .get('/inmuebles/get', {
            params: {
              jsondata: JSON.stringify({
                parametros: {
                  id
                }
              })
            }
          })
          .then(response => {
            if (response.data.result.ok) {
              resolve(response.data.result.data.data.inmueble)
            } else {
              dispatch()
              reject()
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    loadLongContent({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api
          .get('/inmuebles/get_desc_matterport', {
            params: {
              jsondata: JSON.stringify({
                parametros: {
                  id: payload.id,
                  promocionId: payload.promocionId
                }
              })
            }
          })
          .then(response => {
            if (response.data.result.ok) {
              resolve(response.data.result.data.data)
            } else {
              dispatch()
              reject()
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  }
}
