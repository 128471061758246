<template>
  <div>
    <v-col cols="12" style="overflow-x: hidden">
      <v-row justify="center">
        <v-col cols="10">
          <v-row justify="space-between">
            <v-col cols="12" sm="8" md="8" lg="8" class="text-md-left text-lg-left text-sm-left text-center">
              <h3 data-aos="fade-right" data-aos-duration="1000" class="mt-2">
                <span
                  class="text-md-h3 text-xs-body-2 text-md-right text-lg-right text-sm-right text-center"
                  style="font-weight:500;"
                  :style="colorPers != null ? 'color:' + colorPers : 'color:white'"
                  >{{ $t(titulo) }}</span
                >
              </h3>
              <h3 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="400" class="mt-2">
                <span
                  :style="colorPers != null ? 'color:' + colorPers : ''"
                  :class="colorPers != null ? '' : 'secondary--text'"
                  class=" text-md-h4 text-xs-body-2 text-md-right text-lg-right text-sm-right text-center"
                  style="font-weight: 500"
                  >{{ $t(subtitulo) }}</span
                >
              </h3>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4" align="center">
              <v-row justify="center">
                <v-col
                  cols="12"
                  justify="center"
                  align="center"
                  class="text-md-right text-lg-right text-sm-right text-center"
                >
                  <v-btn
                    class="btnCallAction"
                    data-aos="fade-left"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    @click="selectRoute()"
                    color="white"
                    :style="colorPers != null ? 'color: black' : 'color: #25becb;'"
                    :height="boton === 'Más información' ? '90' : '90'"
                    ><p
                      class="mx-2 mt-4 mx-sm-2 mx-md-2 mx-lg-10 mx-xl-10"
                      :style="
                        (boton === 'Más información' && $vuetify.breakpoint.name === 'xs') ||
                        $vuetify.breakpoint.name === 'sm' ||
                        $vuetify.breakpoint.name === 'md'
                          ? 'font-size: 14px'
                          : ''
                      "
                    >
                      {{ $t(boton) }}
                    </p></v-btn
                  ></v-col
                ></v-row
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row></v-col
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  /* props: ['texto', 'boton', 'titulo', 'subtitulo', 'bgColor', 'colorPers'], */
  props: {
    texto: { type: String, default: '' },
    boton: { type: String, default: '' },
    titulo: { type: String, default: '' },
    subtitulo: { type: String, default: '' },
    bgColor: { type: String, default: null },
    colorPers: { type: String, default: null }
  },
  name: 'CallAction',
  data() {
    return {
      route: ''
    }
  },
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({}),
    selectRoute() {
      if (this.boton === 'callAction.botonContacto') {
        this.$router.push({ name: 'contact' })
      } else if (this.boton === 'Más información') {
        this.$emit('aContacto')
      } else {
        this.$router.push({ name: 'properties' })
      }
    }
  }
}
</script>
<style scoped>
.btnCallAction {
  font-weight: 600;
  font-size: 30px;
  /*  color: #25becb; */
}
@media only screen and (max-width: 420px) {
  .btnCallAction {
    font-weight: 600;
    font-size: 20px;
    /*  color: #25becb; */
  }
}
@media only screen and (max-width: 960px) and (min-width: 600px) {
  .btnCallAction {
    font-weight: 600;
    font-size: 15px;
    /*  color: #25becb; */
  }
}
</style>
