import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from '@/plugins/vue-i18n'
import VueAnalytics from 'vue-analytics'
import vuetify from '@/plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'
import { DOCUMENTOS_URL } from '@/api/api'
import '@/plugins/errorHandler' // importante que esté despues de vuetify
import '@/plugins/styles' // importante que esté despues de vuetify
import '@/plugins/components'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueTyperPlugin from 'vue-typer'
import OGTAG from '@/plugins/overtek-gtag'

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style
import 'swiper/css/swiper.css'
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
Vue.use(VueTyperPlugin)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCznqnLyOtVs4ysINT8fNAPLYXCR-Pe7MY'
  }
})
// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-219255928-1',
  router
})
Vue.use(OGTAG)

const formatterCurrency = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
  // the default value for minimumFractionDigits depends on the currency
  // and is usually already 2
})
Vue.filter('noDecimals', function(value) {
  if (value === null || value === undefined) {
    return 0
  }
  return Math.round(value)
})

Vue.filter('currency', function(value) {
  if (value === null || value === undefined) {
    return formatterCurrency.format(0)
  }
  return formatterCurrency.format(value)
})
Vue.mixin({
  methods: {
    // $apiDocument(document) {
    //   if (document) {
    //     return DOCUMENTOS_URL + '' + encodeURI(`?jsondata={"parametros":{"id":${document.documentoId}}}`)
    //   } else {
    //     return ''
    //   }
    // },
    $apiDocument(document) {
      if (document) {
        return DOCUMENTOS_URL + '' + encodeURI(`?jsondata={"parametros":{"id":${document.documentoId}}}`)
      } else {
        return ''
      }
    }
  }
})
new Vue({
  created() {
    AOS.init()
  },
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
