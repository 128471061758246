<template>
  <pages-layout>
    <template v-slot:content>
      <HeaderArticulo :title="''" :image="noticia.foto" />

      <v-row no-gutters justify="center" class="mb-16 pb-16" style="margin-top: -900px">
        <v-col cols="11" md="8" lg="8" xl="8" :align-self="'center'">
          <v-card class="mt-n12 mb-6 mx-auto pa-8" elevation="12">
            <h1
              class=" primary--text "
              v-if="
                $vuetify.breakpoint.name === 'md' ||
                  $vuetify.breakpoint.name === 'lg' ||
                  $vuetify.breakpoint.name === 'xl'
              "
            >
              {{ $t('blog.noticia' + noticia.id + '.titulo') }}
            </h1>
            <h2 class=" primary--text " v-else>
              {{ $t('blog.noticia' + noticia.id + '.titulo') }}
            </h2>
            <h3 v-if="noticia.subtitulo" class=" secondary--text ">
              {{ $t('blog.noticia' + noticia.id + '.subtitulo') }}
            </h3>
            <v-row class="mt-2" no-gutters>
              <h4 v-if="noticia.fecha">{{ fecha }}</h4>
            </v-row>
            <v-row>
              <v-img class="fotoMostrar" :height="400" :src="noticia.foto" />
            </v-row>
            <v-row no-gutters>
              <h4 v-if="noticia.autor">{{ noticia.autor }}</h4>
            </v-row>
            <TextoFormateado class="" :texto="$t('blog.noticia' + noticia.id + '.resumen')" />
            <div v-for="(p, index) in $t('blog.noticia' + noticia.id + '.parrafos')" :key="index">
              <v-row no-gutters>
                <h4 class=" primary--text ">{{ p.titulo }}</h4>
                <TextoFormateado class="" :texto="p.texto" />
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row></v-row>
      <!-- TODO INTEGRAR CALL TO ACTION -->
    </template>
  </pages-layout>
</template>
<script>
import HeaderArticulo from '@/components/HeaderArticulo'
import TextoFormateado from '@/components/Textos/TextoFormateado.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: {
    HeaderArticulo,
    TextoFormateado
    //NavigationDrawer
  },
  props: {
    ruta: {
      type: String,
      default: null
    }
  },
  created() {
    this.fecha = moment(new Date(this.noticia.fecha)).format('DD/MM/YYYY')
  },
  data() {
    return { fecha: null }
  },
  computed: {
    ...mapGetters({
      getNoticia: 'blog/getArticulo'
    }),
    noticia() {
      return this.getNoticia(this.ruta)
    }
  }
}
</script>

<style>
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  background-size: cover;
  background-position: left;
  /* background-image: url('../assets/home/UBIDOMO_slide-1-1500x630.jpg'); */
}

@media only screen and (max-width: 420px) {
  .parallax-bg {
    background-image: url('../../../assets/howtorent/parallax2.jpg');
  }
}
</style>
