var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{staticClass:"py-0 px-0 mx-0 my-0",attrs:{"cols":"12"}},[_c('v-parallax',{attrs:{"height":_vm.height,"src":_vm.image}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center mt-2",attrs:{"cols":"12"}},[_c('vue-typer',{staticClass:"mt-4 vuetyper",staticStyle:{"font-weight":"bold","font-family":"'Style Script', cursive !important","color":"white","text-align":"center","font-size":"150px","opacity":"0.5"},style:(_vm.$vuetify.breakpoint.name === 'md' ||
              _vm.$vuetify.breakpoint.name === 'lg' ||
              _vm.$vuetify.breakpoint.name === 'xl'
                ? 'font-size: 148px'
                : 'font-size: 55px'),attrs:{"repeat":0,"text":_vm.$t(_vm.title),"pre-type-delay":0,"type-delay":40,"caret-animation":"solid"}}),_c('div',{staticClass:"mt-4 titleNoTyper",staticStyle:{"display":"none","font-weight":"bold","font-family":"'Style Script', cursive !important","color":"white","text-align":"center","font-size":"150px","opacity":"0.5"},style:(_vm.$vuetify.breakpoint.name === 'md' ||
              _vm.$vuetify.breakpoint.name === 'lg' ||
              _vm.$vuetify.breakpoint.name === 'xl'
                ? 'font-size: 148px'
                : 'font-size: 55px'),attrs:{"repeat":0}},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }