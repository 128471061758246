<template>
  <v-main class="py-0">
    <FilterTitle :titulo="'properties.kindsfilters.' + filtro" :icon="filterIcon" :color="'black'" :hovered="hovered" />
    <v-select
      class="mr-2"
      v-if="filtro == 'promocion'"
      v-model="promocion"
      :items="promociones"
      item-value="codigo"
      item-text="descripcion"
      :disabled="disabled || loading"
      dense
      clearable
      @focus="hovered = true"
      @blur="hovered = false"
    >
      <template slot="no-data">
        <v-layout style="padding:10px" :value="true">{{ $t('general.nopromos') }}</v-layout>
      </template>
    </v-select>
    <v-select
      class="mr-2"
      v-if="filtro == 'ciudad'"
      v-model="ciudad"
      :items="ciudades"
      dense
      clearable
      :disabled="disabled || loading"
      @focus="hovered = true"
      @blur="hovered = false"
    >
      <template slot="no-data">
        <v-layout style="padding:10px" :value="true">{{ $t('general.nociudades') }}</v-layout>
      </template>
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
        </v-list-item>
      </template>
    </v-select>
    <v-select
      class="mr-2"
      dense
      @focus="hovered = true"
      @blur="hovered = false"
      v-if="filtro == 'tipologia'"
      :disabled="disabled || loading"
      v-model="tipologia"
      :items="tipologias"
    >
      <template slot="no-data">
        <v-layout style="padding:10px" :value="true">{{ $t('general.notipos') }}</v-layout>
      </template>
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item v-bind="attrs" v-on="on">
          <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"></v-list-item-title>
        </v-list-item>
      </template>
    </v-select>
    <v-row v-if="filtro == 'dormitorios'">
      <v-checkbox
        dense
        class=""
        :class="
          $vuetify.breakpoint.name != 'xl' && $vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'lg'
            ? 'checkboxTamanho mr-2'
            : 'mr-4'
        "
        v-model="dormitorios"
        :label="$t('properties.estudios')"
        value="0"
        :disabled="disabled || loading"
      ></v-checkbox>
      <v-checkbox
        dense
        class=""
        :class="
          $vuetify.breakpoint.name != 'xl' && $vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'lg'
            ? 'checkboxTamanho mr-2'
            : 'mr-4'
        "
        v-model="dormitorios"
        label="1"
        value="1"
        :disabled="disabled || loading"
      ></v-checkbox>
      <v-checkbox
        dense
        class=""
        :class="
          $vuetify.breakpoint.name != 'xl' && $vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'lg'
            ? 'checkboxTamanho mr-2'
            : 'mr-4'
        "
        v-model="dormitorios"
        label="2"
        value="2"
        :disabled="disabled || loading"
      ></v-checkbox>
      <v-checkbox
        dense
        class=""
        v-model="dormitorios"
        :class="
          $vuetify.breakpoint.name != 'xl' && $vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'lg'
            ? 'checkboxTamanho mr-2'
            : 'mr-4'
        "
        label="3"
        value="3"
        :disabled="disabled || loading"
      ></v-checkbox>
      <v-checkbox
        dense
        v-model="dormitorios"
        :class="
          $vuetify.breakpoint.name != 'xl' && $vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'lg'
            ? 'checkboxTamanho'
            : ''
        "
        label="4+"
        value="4"
        :disabled="disabled || loading"
      ></v-checkbox>
    </v-row>
    <v-row class="mb-2 mr-2" v-if="filtro == 'precio'">
      <v-range-slider
        v-model="rangoPrecio"
        :disabled="disabled || loading"
        :max="max"
        :min="min"
        hide-details
        @mousedown="rangoDown()"
        @mouseup="rangoUp()"
        class="align-center"
        width="50px"
      >
        <template v-slot:prepend>
          <v-text-field
            :value="rangoPrecio[0]"
            class="mt-0 pt-0"
            suffix=" €"
            hide-details
            ref="minimo"
            single-line
            style="width: 60px"
            @blur="setMinimo($event)"
          ></v-text-field>
        </template>
        <template v-slot:append>
          <v-text-field
            :value="rangoPrecio[1] >= 3000 ? $t('home.sinlimite') : rangoPrecio[1]"
            :suffix="rangoPrecio[1] >= 3000 ? '' : ' €'"
            class="mt-0 pt-0"
            hide-details
            single-line
            style="width: 80px"
            @blur="setMaximo($event)"
          ></v-text-field>
        </template> </v-range-slider
    ></v-row>
    <v-row v-if="filtro == 'muebles'"
      ><v-switch dense v-model="amueblado" :disabled="disabled || loading" inset class="pl-1"></v-switch>
    </v-row>
    <v-row v-if="filtro == 'home'">
      <span>{{ $t('properties.rangoPrecios') }}</span>
      <v-range-slider
        v-model="rangoPrecio"
        @mouseup="setPrecio($event)"
        :max="max"
        :min="min"
        hide-details
        class="align-center"
        :disabled="disabled || loading"
      >
        <template v-slot:prepend>
          <v-text-field
            :value="rangoPrecio[0]"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            style="width: 60px"
            @blur="setMinimo($event)"
          ></v-text-field>
        </template>
        <template v-slot:append>
          <v-text-field
            :value="rangoPrecio[1]"
            class="mt-0 pt-0"
            single-line
            type="number"
            style="width: 60px"
            @blur="setMaximo($event)"
          ></v-text-field>
        </template> </v-range-slider
    ></v-row>
  </v-main>
</template>
<script>
import FilterTitle from '@/components/Textos/FilterTitle'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'FilterProperty',
  props: ['filtro', 'value', 'tipologias', 'promociones', 'disabled', 'loading'],
  components: {
    FilterTitle
  },
  data() {
    return {
      hovered: false,
      soltado: true,
      min: 0,
      max: 3000
    }
  },
  computed: {
    ...mapGetters({}),
    promocion: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    ciudades() {
      if (this.promociones == null) {
        return []
      }
      return this.promociones.map(x => {
        return x.provincia
      })
    },
    rangoPrecio: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    ciudad: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    tipologia: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    amueblado: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    dormitorios: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
    filterIcon() {
      let retorno = ''
      switch (this.filtro) {
        case 'promocion':
          retorno = 'mdi-home-city'
          break
        case 'ciudad':
          retorno = 'mdi-map-marker'
          break
        case 'tipologia':
          retorno = 'mdi-map-legend'
          break
        case 'dormitorios':
          retorno = 'mdi-bed-empty'
          break
        case 'precio':
          retorno = 'mdi-currency-eur'
          break
        case 'muebles':
          retorno = 'mdi-wardrobe'
          break
      }
      return retorno
    }
  },
  methods: {
    ...mapActions({}),
    setMinimo(event) {
      let valor = event.target.value.replace(/[^\d]/g, '')

      this.$emit('actMin', Number(valor))
    },
    setMaximo(event) {
      if (event.target.value !== 'sin límite') {
        let valor = event.target.value.replace(/[^\d]/g, '')
        this.$emit('actMax', Number(valor))
      } else {
        this.$emit('actMax', this.max)
      }
    },
    rangoDown() {
      this.$emit('soltado', false)
    },
    rangoUp() {
      this.$emit('soltado', true)
      this.$emit('input', this.value)
    }
  }
}
</script>
<style>
.checkboxTamanho .v-label {
  font-size: 13px !important;
}
</style>
