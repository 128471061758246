<template>
  <div>
    <v-row>
      <v-col class="text-left" cols="12">
        <div :style="'color:' + color" v-html="texto"></div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['texto', 'color', 'textSize'],
  name: 'TextoFormateado',
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>
