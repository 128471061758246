import Vue from 'vue'
import Vuex from 'vuex'
import alerts from './alerts'
import properties from './properties/store'
import email from './email/store'
import blog from './blog/store'
//import registro from './registro'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alerts,
    properties,
    blog,
    email
    //registro
  }
})
