<template>
  <v-main class="py-0">
    <FilterTitle
      v-if="!conTitulo"
      :titulo="'filtersSelect.orderBy'"
      :icon="'mdi-sort'"
      :color="'black'"
      :hovered="hovered"
    />
    <v-select class="ml-n3 mt-4" :items="order" solo v-model="orden" :disabled="loading" />
  </v-main>
</template>
<script>
import FilterTitle from '@/components/Textos/FilterTitle'
import { mapGetters } from 'vuex'
export default {
  name: 'OrderComponent',
  props: ['conTitulo', 'order', 'value', 'loading'],
  components: {
    FilterTitle
  },
  data() {
    return {
      hovered: false
    }
  },
  computed: {
    ...mapGetters({}),
    orden: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {}
}
</script>
