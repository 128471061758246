<template>
  <div>
    <v-row align="center">
      <v-col class="text-left py-0" cols="8">
        <v-row>
          <v-icon :color="!hovered ? color : 'primary'">
            {{ icon }}
          </v-icon>
          <h4 class="m-4 ml-2" :style="'color:' + color">
            {{ this.$t(titulo) }}
          </h4>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['titulo', 'color', 'hovered', 'icon'],
  name: 'FilterTitle',
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>
