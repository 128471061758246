import Vue from 'vue'

export class CustomError extends Error {
  constructor(message = 'Error', code = 0) {
    super(message)
    this.code = code
  }
}

Vue.config.errorHandler = (error, vm, info) => {
  console.info(info)
  if (!error.code || error.code !== 1) {
    console.error(error)
    // vm.addAlert({ texto: error.message, color: 'error' })
  }
}

Vue.config.warnHandler = (msg, vm, trace) => {
  if (msg !== 'The .native modifier for v-on is only valid on components but it was used on <div>.') {
    console.error(`Warn: ${msg}\nTrace: ${trace}`)
  }
}
