<template>
  <div>
    <router-link style="text-decoration: none" :to="'/properties/' + item.id + '/' + importeTotal" :params="item.id">
      <v-hover v-slot:default="{ hover }">
        <v-card class="mb-5" :elevation="hover ? 5 : 2">
          <v-row>
            <v-col cols="12" md="4" lg="4" xl="4" class="pt-0 mt-0 mb-0 pb-0">
              <v-img
                :src="
                  item.imagenPrincipal
                    ? $apiDocument(item.imagenPrincipal)
                    : require('@/assets/fake_properties/11/building_placeholder_500x332.jpg')
                "
                :lazy-src="require('@/assets/fake_properties/11/building_placeholder_500x332.jpg')"
                class="white--text align-end mt-0 pt-0 pb-0 mb-0"
                :height="height"
                ><template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </v-col>
            <v-col cols="12" md="8" lg="8" xl="8" align="start">
              <v-card-title class="mt-0 pt-0" style="font-size: 18px; word-break: normal"
                >{{ item.descripcionLarga }}
                {{ item.promocion.provincia ? '(' + item.promocion.provincia + ')' : '' }}</v-card-title
              >
              <v-card-title
                class="mt-0 pt-0"
                style="font-size: 34px; font-weight:bold"
                v-if="item.tarifaAlquiler.importeAlquiler == null"
                >{{ $t('properties.noImporte') }}</v-card-title
              >
              <v-card-title v-else class="mt-0 pt-0" style="font-size: 34px; font-weight:bold">{{
                importeTotal | currency
              }}</v-card-title>
              <v-card-subtitle class="mt-1">{{ traducirTipo(item.descripcionTipo) }}</v-card-subtitle>
              <v-card-text class="pb-0"
                ><span v-if="item.descripcionTipo === 'Vivienda'"
                  >{{ item.numDormitorios }}
                  {{
                    item.numDormitorios == 1 ? $t('properties.cardProperty.room') : $t('properties.cardProperty.rooms')
                  }}</span
                >
                <span style="margin-left:10px;margin-right:10px" v-if="item.descripcionTipo === 'Vivienda'">-</span>
                <span>{{ item.m2Construidos | noDecimals }} {{ $t('m2') }}</span>
              </v-card-text>
              <!-- <v-row justify="end" style="bottom: 0px;"> -->

              <!-- <v-btn text class="mt-4 mr-8">
                <v-icon x-large>mdi-arrow-right-circle-outline</v-icon>
              </v-btn> -->

              <!-- </v-row> -->
            </v-col></v-row
          >
        </v-card></v-hover
      ></router-link
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['item', 'height'],
  name: 'CardProperty',
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters({}),
    importeTotal() {
      let suma = Number(this.item.tarifaAlquiler.importeAlquiler)
      if (this.item.inmueblesHijos && this.item.inmueblesHijos.length > 0) {
        for (let i = 0; i < this.item.inmueblesHijos.length; i++) {
          suma += this.item.inmueblesHijos[i].tarifaAlquiler
            ? Number(this.item.inmueblesHijos[i].tarifaAlquiler.importeAlquiler)
            : 0
        }
      }
      return suma
    }
  },
  methods: {
    ...mapActions({}),
    traducirTipo(tipo) {
      if (this.$root.$i18n.locale == 'es') {
        return tipo
      } else {
        switch (tipo) {
          case 'Vivienda':
            return 'Dwelling'
          case 'Garaje':
            return 'Parking'
          case 'Local':
            return 'Premises'
          case 'Trastero':
            return 'Storage'
        }
      }
    }
  }
}
// width="500px" height="400px"
</script>
