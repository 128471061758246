<template>
  <pages-layout>
    <template v-slot:content>
      <Header :title="'titulosSecciones.faqsLargo'" :image="require('@/assets/faqs/faqs_header.jpg')" />
      <v-container class="pb-16 mb-md-0">
        <v-row justify="center" class="">
          <v-col cols="10" justify="center">
            <p
              data-aos="fade-up"
              data-aos-duration="800"
              class="mr-8 ml-8 mt-10 pb-4"
              style="font-size:20px; font-weight-light; color:black; text-align: center;"
            >
              {{ $t('faqsHeader') }}
              <router-link to="contact" style="color:black"> {{ $t('howtorent.find.text2') }}</router-link>
              .
            </p>
          </v-col>
        </v-row>

        <v-row justify="center" class="mb-6">
          <v-expansion-panels style="z-index: 1" popout multiple>
            <!-- <v-expansion-panel
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-anchor-placement="top-bottom"
              :data-aos-delay="i * 300"
              dense
              v-for="(question, i) in $t('faqs')"
              :key="i"
              style="padding:10px; background-color:#E4EAE7;"
              class="mt-6"
            > -->
            <v-expansion-panel
              dense
              v-for="(question, i) in $t('faqs')"
              :key="i"
              style="padding:10px; background-color:#E4EAE7;"
              class="mt-6"
            >
              <v-expansion-panel-header class="primary--text" style="font-size: 20px; font-weight: 600; ">{{
                question.question
              }}</v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4 secondary--text" style="font-weight: bold">
                <p>{{ question.response1 }}</p>
                <p>
                  {{ question.response2 }} <a :href="fullURL">{{ question.link }}</a>
                  {{ question.subResponse2 }}
                </p>
                <p>{{ question.response3 }}</p>
                <p>{{ question.response4 }}</p>
                <p class="ml-8">{{ question.tab1 }}</p>
                <p class="ml-8">{{ question.tab2 }}</p>
                <p class="ml-8">{{ question.tab3 }}</p>
                <p class="ml-8">{{ question.tab4 }}</p>
                <p class="ml-14">{{ question.subtab1 }}</p>
                <p class="ml-14">{{ question.subtab2 }}</p>
                <p class="ml-14">{{ question.subtab3 }}</p>

                <p class="ml-8">{{ question.tab5 }}</p>
                <p class="ml-8">{{ question.tab6 }}</p>
                <p class="ml-8">{{ question.tab7 }}</p>
                <p class="ml-8">{{ question.tab8 }}</p>
                <p class="ml-8">{{ question.tab9 }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row></v-container
      ></template
    >
  </pages-layout>
</template>
<script>
import Header from '@/components/Header'
export default {
  name: 'faqs',
  components: {
    Header
  },
  data() {
    return {}
  },
  created() {
    window.scrollTo(0, 0)
  },
  computed: {
    fullURL() {
      return window.baseURL + '/contact'
    }
  }
}
</script>
