<template>
  <div>
    <v-col cols="12" class="py-0 px-0 mx-0 my-0">
      <v-img height="1000" width="100%" :src="image" style=" position:absolute; top: 0px; right:0px; "> </v-img>
      <div
        style="height:1000px; background: linear-gradient(0deg, white,transparent); position:relative; top: 0px"
      ></div>
    </v-col>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['title', 'image'],
  name: 'App',
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>
