export default {
  namespaced: true,
  state: {
    noticias: [
      {
        id: 1,
        enlace: 'mgvm-aspira-lider-gestion-residencial',
        fecha: '12/14/2020',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia1.jpg'),
        categorias: ['proptech', 'mvgm']
      },
      {
        id: 2,
        enlace: 'mvgm-da-gas-residencial',
        fecha: '06/08/2021',
        subtitulo: true,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia2.jpg'),
        categorias: ['alquiler', 'mvgm']
      },
      {
        id: 3,
        enlace: 'mvgm-compra-gestion-jll',
        fecha: '12/03/2019',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia3.jpeg'),
        categorias: ['mvgm']
      },
      {
        id: 4,
        enlace: 'mgvm-lider-gestion-residencial',
        fecha: '04/04/2021',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia4.jpeg'),
        categorias: ['mvgm', 'proptech']
      },
      {
        id: 5,
        enlace: 'mgvm-informe-tercer-trimestre',
        fecha: '11/24/2021',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia5.jpeg'),
        categorias: ['mvgm', 'proptech']
      },
      {
        id: 6,
        enlace: 'mgvm-informe-mercado-Q1-2022',
        fecha: '06/24/2022',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia6.jpeg'),
        categorias: ['mvgm', 'proptech' /* , 'new' */]
      },
      {
        id: 7,
        enlace: 'mgvm-certificacion-AIS',
        fecha: '06/27/2022',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia7.jpg'),
        categorias: ['mvgm', 'proptech' /* , 'new' */]
      },
      {
        id: 8,
        enlace: 'aedas-built-to-rent-sevilla',
        fecha: '01/26/2023',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia8.jpg'),
        categorias: ['mvgm', 'proptech' /* , 'new' */]
      },
      {
        id: 9,
        enlace: 'informe-de-mercado-mvgm-q4-2023',
        fecha: '02/12/2024',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia9.jpg'),
        categorias: ['mvgm', 'proptech', 'new']
      },
      {
        id: 10,
        enlace: 'mvgm-unifica-sus-oficinas-de-espana-y-portugal',
        fecha: '11/14/2023',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia10.jpg'),
        categorias: ['mvgm', 'proptech', 'new']
      },
      {
        id: 11,
        enlace: 'mvgm-obtiene-las-certificaciones-iso-9001-e-iso-14001',
        fecha: '11/14/2023',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia11.jpg'),
        categorias: ['mvgm', 'proptech', 'new']
      },
      {
        id: 12,
        enlace: 'memoria-anual-mvgm-2023',
        fecha: '09/18/2023',
        subtitulo: false,
        estado: 'publicada',
        foto: require('@/assets/news/post/noticia12.jpg'),
        categorias: ['mvgm', 'proptech' /* , 'new' */]
      }
    ],
    categorias: [
      {
        id: 1,
        code: 'proptech',
        texto: 'news.proptech'
      },
      { id: 2, code: 'alquiler', texto: 'news.alquiler' },
      { id: 3, code: 'mvgm', texto: 'news.mvgm' }
    ]
  },
  getters: {
    getCategorias(state) {
      return state.categorias
    },
    getNoticias(state) {
      return state.noticias
    },
    getArticulo(state) {
      return enlace => {
        return state.noticias.find(x => x.enlace === enlace)
      }
    }
  }
}
