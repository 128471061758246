<template>
  <div>
    <v-col cols="12" justify="center" class="pb-2">
      <v-row>
        <span class="text-left text-md-left text-lg-left text-xl-left">
          <v-icon color="white" class="mb-xl-2">mdi-arrow-right-drop-circle</v-icon>
          <span
            class="text-left text-md-left text-lg-left text-xl-left"
            :style="size ? 'font-size:' + size : 'font-size:20px'"
            style="font-weight-light; color:white;"
          >
            {{ texto }} <router-link v-if="link" to="contact"> {{ $t('howtorent.find.text2') }}</router-link></span
          >
        </span>
      </v-row></v-col
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['texto', 'size', 'link'],
  name: 'App',
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>
