export default {
  // called by Vue.use(FirstPlugin)
  install(Vue) {
    function gtag() {
      const dataLayer = (window.dataLayer = window.dataLayer || [])

      dataLayer.push(arguments)

      console.log('Current dataLayer', window.dataLayer)
    }
    function loadScript(id) {
      const script = document.createElement('script')

      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`

      gtag('js', new Date())
      gtag('config', id)

      document.body.appendChild(script)
    }
    function loadScriptAnalytics() {
      const script = document.createElement('script')

      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-B978L9MZT7`

      gtag('js', new Date())

      gtag('config', 'G-B978L9MZT7')

      document.body.appendChild(script)
    }
    function gtag_report_conversion(url, key) {
      var callback = function() {
        if (typeof url != 'undefined') {
          window.location = url
        }
      }
      gtag('event', 'conversion', { send_to: key, event_callback: callback })
      return false
    }

    loadScriptAnalytics()
    loadScript('AW-11199284535')

    Vue.prototype.$gtag = gtag
    Vue.prototype.$gtagReportConversion = gtag_report_conversion
  }
}
