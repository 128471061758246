<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-card
        class="mb-5 tarjeta"
        style="cursor:pointer;"
        :height="$vuetify.breakpoint.name === 'xl' ? '620px' : $vuetify.breakpoint.name === 'lg' ? '600px' : ''"
        :elevation="hover ? 5 : 2"
      >
        <v-col cols="12" class="">
          <!--  <v-img
            :src="image"
            content
            class="white--text align-end imgHoverZoom"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            :height="height"
          ></v-img> -->
          <div class="parent" :style="'height:' + height + '; width:100%'">
            <div
              class="child"
              :style="
                'background-image:url(' +
                  image +
                  '); width: 100%; height:100%; background-position: center; background-size: cover;'
              "
            ></div>
          </div>
        </v-col>
        <v-col cols="12" align="start">
          <v-card-title style="word-break: normal; font-size: 18px ">{{ $t(title) }}</v-card-title>
          <v-card-subtitle class="pb-0">{{ date }}</v-card-subtitle>
          <v-card-text
            v-if="
              $vuetify.breakpoint.name === 'md' ||
                $vuetify.breakpoint.name === 'sm' ||
                $vuetify.breakpoint.name === 'xl'
            "
            class="pb-0"
            >{{ $t(text) }}</v-card-text
          >
        </v-col>
      </v-card></v-hover
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['title', 'image', 'date', 'text', 'height'],
  name: 'CardNewsHome',
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
// width="500px" height="400px"
</script>
<style scoped>
.parent {
  overflow: hidden;
  position: relative;
  display: inline-block;
}
.child {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.tarjeta:hover .child,
.tarjeta:focus .child {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.tarjeta:hover .child:before,
.tarjeta:focus .child:before {
  display: block;
}

.child:before {
  content: '';
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
