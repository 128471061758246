<template>
  <v-dialog
    v-model="privacyDialog"
    :width="
      $vuetify.breakpoint.name === 'md' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl'
        ? '60%'
        : '90%'
    "
    ><template v-slot:default="dialog">
      <v-card>
        <v-toolbar
          color="primary"
          class="privacyTitle text-h6 text-sm-h5 pl-12"
          style="font-size:25px;font-weight: bold;height:100px;padding:20px 0px"
          dark
          >{{ $t('formulario.avisoLegal') }}</v-toolbar
        >
        <v-card-text>
          <p class="mt-12 px-12" style="text-align:justify;">
            <TextoFormateado class="" :texto="$t('formulario.avisoLegalText')" />
          </p>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">{{ $t('formulario.closeDialog') }}</v-btn>
        </v-card-actions>
      </v-card>
    </template></v-dialog
  >
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import TextoFormateado from '@/components/Textos/TextoFormateado.vue'
export default {
  props: ['value'],
  name: 'PopUpAvisoLegal',
  components: {
    TextoFormateado
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({}),
    privacyDialog: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    ...mapActions({})
  }
}
</script>
