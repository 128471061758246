<template>
  <div class="locale-changer" style="height:100%;max-width:50px">
    <!-- <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select> -->

    <v-select
      class="custom"
      style="height:100%;margin-top:12px"
      v-model="languageSelected"
      :items="langs"
      item-value="lang"
    >
      <template v-slot:selection="{ item }">
        <v-row style="height:100%;" justify="center" align="center"><country-flag :country="item.icon" /> </v-row>
      </template>
      <template v-slot:item="{ item }">
        <v-row style="height:100%;padding-left:10px;padding-right:10px" justify="start" align="center"
          ><country-flag :country="item.icon" />
          <span style="margin-left:10px;margin-top:10px">{{ $t(item.texto) }}</span></v-row
        >
      </template>
    </v-select>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'locale-changer',
  components: {
    CountryFlag
  },
  created() {
    console.log('me creo de nuevo')
    this.languageSelected = localStorage.language != null ? localStorage.language : 'es'
  },
  data() {
    return {
      langse: ['es', 'en'],
      langs: [
        {
          lang: 'es',
          texto: 'espanol',
          icon: 'es'
        },
        {
          lang: 'en',
          texto: 'ingles',
          icon: 'gb'
        }
      ],
      languageSelected: 'es'
    }
  },
  computed: {
    ...mapGetters({
      getLang: 'properties/getLang'
    })
  },
  methods: {
    ...mapActions({
      updateLanguage: 'properties/updateLanguage'
    })
  },
  watch: {
    languageSelected(newVal) {
      this.$root.$i18n.locale = newVal
      this.updateLanguage(newVal)
    }
  }
}
</script>
<style>
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
</style>
