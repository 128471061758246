import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/lang/en.json'
import es from '@/lang/es.json'

Vue.use(VueI18n)

export const languages = ['en', 'es']
export const defaultLanguage = languages[1]

export default new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages: {
    en: en,
    es: es
  }
})
