import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '@/pages/home.vue'
import properties from '@/pages/properties/properties.vue'
import property from '@/pages/properties/property.vue'
import about from '@/pages/about/about.vue'
import howtorent from '@/pages/howtorent.vue'
import faqs from '@/pages/faqs.vue'
import news from '@/pages/news/news.vue'
import articulo from '@/pages/news/articulos/articulo.vue'
import contact from '@/pages/contact.vue'
import comercializaciondigital from '@/pages/landings/comercializaciondigital.vue'
import nombrepromocion from '@/pages/landings/nombrepromocion.vue'
/* import sevillaLasDelicias from '@/pages/landings/sevillaLasDelicias.vue' */

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/properties',
    name: 'properties',
    component: properties,
    props: true
  },
  {
    path: '/properties/:id/:importeTotal',
    name: 'property',
    component: property,
    props: true
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/howtorent',
    name: 'howtorent',
    component: howtorent
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: faqs
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/news/:ruta',
    name: 'articulo',
    component: articulo,
    props: true
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/comercializacion-digital',
    name: 'comercializacion-digital',
    component: comercializaciondigital
  },
  {
    path: '/developments/sevillalasdelicias',
    name: 'sevillalasdelicias',
    component: nombrepromocion
  }
  /* {
    path: '/developments/sevillalasdeliciaswip',
    name: 'sevillalasdelicias2',
    component: sevillaLasDelicias
  } */
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
